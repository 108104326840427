import {
    DASHBOARD_GET_ALL_MESSAGES,
    DASHBOARD_PROFILE_VERIFICATION
} from "../types/dashboardTypes";


export const dashboardMessageReducer = (state = {
    messageList: {
        pending: true,
        failed: false,
        message: "",
        payload: {
            data: null,
            error: null,
        }
    }
}, action ) => {
	if ([DASHBOARD_GET_ALL_MESSAGES].includes(action.type)) {
		return {
			...state,
			messageList: action.data
		};
	}
	return state;
}

