import ActivityInvariantException from "./ActivityInvariantException";

export default class InvalidSearchCriteriaException extends ActivityInvariantException {
    name = 'InvalidSearchCriteriaException';
    context = {};

    constructor(message, context = {}) {
        super(message);
        this.context = context;
    }
}
