import { memo } from "react";

const MyBookingsProgressive = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M10.0013 6H14.5794C14.6927 6 14.8013 6.04499 14.8814 6.12509C14.9615 6.20518 15.0065 6.3138 15.0065 6.42707V7.85774H9.57422V6.42707C9.57422 6.3138 9.61921 6.20518 9.6993 6.12509C9.7794 6.04499 9.88802 6 10.0013 6Z" stroke="#64748B" strokeWidth="1.20433" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.42707 7.85742H19.153C19.3889 7.85742 19.5801 8.04863 19.5801 8.28449V18.1754C19.5801 18.4112 19.3889 18.6024 19.153 18.6024H5.42707C5.1912 18.6024 5 18.4112 5 18.1754V8.28449C5 8.04863 5.1912 7.85742 5.42707 7.85742ZM7.2124 15.9857C6.87984 15.9857 6.61024 16.2553 6.61024 16.5879C6.61024 16.9205 6.87984 17.1901 7.2124 17.1901H17.3681C17.7006 17.1901 17.9702 16.9205 17.9702 16.5879C17.9702 16.2553 17.7006 15.9857 17.3681 15.9857H7.2124Z" fill="#64748B" />
    </svg>
);

export default memo(MyBookingsProgressive);
