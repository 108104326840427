import { memo } from "react";

const PrivacyPolicyProgressive = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#privacy_policy_progressive)">
            <path d="M13.3125 5H6.60417C5.7175 5 5 5.7175 5 6.60417V15.6458C5 16.5325 5.7175 17.25 6.60417 17.25H11.3583C10.8683 16.4742 10.5417 15.5583 10.5417 14.5083V13.085C10.4542 13.1375 10.355 13.1667 10.25 13.1667H7.33333C7.0125 13.1667 6.75 12.9042 6.75 12.5833C6.75 12.2625 7.0125 12 7.33333 12H10.25C10.3608 12 10.46 12.0292 10.5475 12.0817C10.5708 11.5858 10.8042 11.1308 11.1717 10.8333H7.33333C7.0125 10.8333 6.75 10.5708 6.75 10.25C6.75 9.92917 7.0125 9.66667 7.33333 9.66667H12.5833C12.8283 9.66667 13.0383 9.81833 13.1258 10.0342L14.7883 9.46833C14.8292 9.45083 14.87 9.43917 14.9167 9.43333V6.60417C14.9167 5.7175 14.1992 5 13.3125 5V5ZM9.66667 8.5H7.33333C7.0125 8.5 6.75 8.2375 6.75 7.91667C6.75 7.59583 7.0125 7.33333 7.33333 7.33333H9.66667C9.9875 7.33333 10.25 7.59583 10.25 7.91667C10.25 8.2375 9.9875 8.5 9.66667 8.5Z" fill="#64748B" />
            <path d="M18.6045 11.6139L15.542 10.5721C15.4201 10.5307 15.2877 10.5307 15.1658 10.5721L12.1033 11.6139C11.867 11.6944 11.7078 11.9167 11.7078 12.1663V14.5102C11.7078 17.4659 14.9855 18.8939 15.1249 18.9534C15.1984 18.9843 15.2766 18.9995 15.3542 18.9995C15.4318 18.9995 15.5099 18.9843 15.5829 18.9528C15.7223 18.8933 19 17.4659 19 14.5102V12.1663C19 11.9161 18.8414 11.6944 18.6045 11.6139ZM17.425 13.8078L15.675 16.1412C15.5735 16.2765 15.4189 16.3611 15.2498 16.3727C15.2358 16.3739 15.2218 16.3745 15.2084 16.3745C15.0538 16.3745 14.9056 16.3138 14.7959 16.2036L13.6293 15.0369C13.4012 14.8088 13.4012 14.4402 13.6293 14.2121C13.8574 13.984 14.226 13.984 14.4541 14.2121L15.1454 14.9033L16.4917 13.1078C16.6848 12.8506 17.0505 12.7975 17.3078 12.9912C17.5662 13.1842 17.6187 13.55 17.425 13.8078Z" fill="#64748B" />
        </g>
        <defs>
            <clipPath id="privacy_policy_progressive">
                <rect width="14" height="14" fill="white" transform="translate(5 5)" />
            </clipPath>
        </defs>
    </svg>
);

export default memo(PrivacyPolicyProgressive);
