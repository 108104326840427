import { memo } from "react";

const CuddlyNestIcon = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M14.408 11.1399C14.408 11.1399 15.2384 11.9995 16.7371 12.3424C17.6446 10.9225 19.5 10.13 21.4903 9.83969C19.2862 9.69111 18.4124 9.98205 17.955 10.0312C17.4343 10.0872 15.9626 8.94871 14.408 11.1399Z" fill="#64748B"/>
<path d="M9.38754 13C9.38754 13 8.55064 13.9603 8 15C8.38506 14.4635 8.85601 13.9647 10 13.4207L9.38754 13Z" fill="#94A3B8"/>
<path d="M7.17303 9.00552C7.53863 9.36169 7.77739 9.66964 8 10C6.96275 9.47048 5.15294 9.49671 4 9.65538C4.64222 9.35104 6.11011 8.94806 7.17303 9.00552Z" fill="#94A3B8"/>
<path d="M12.5704 8.85452C12.5704 8.85452 13.1021 9.19917 13.3418 9.87123C13.8016 8.95887 15.5516 8.20535 16.7344 7.9077C15.3956 7.95366 14.8864 8.18296 14.6135 8.24081C14.3029 8.30663 13.0647 7.86824 12.5704 8.85452Z" fill="#94A3B8"/>
<path d="M6.57084 18C6.57084 18 9.21355 15.5943 12.0886 15.5943C15.3219 15.5943 16.256 14.035 17 12.5131C17 12.5131 14.5072 12.1793 13.3765 9.54193C13.3765 9.54193 10.6804 5.77503 3.3955 6.01061C3.3955 6.01061 7.52914 8.44123 8.53575 10.076C9.56753 11.7515 12.2359 13.5731 12.123 13.5173C9.78628 12.3646 8.19695 10.1744 8.14313 10.0337C8.08938 9.89296 5.87327 9.24453 2 10.5506C2 10.5506 6.16182 9.7433 10.9076 13.7243C10.9076 13.7243 10.4278 13.9403 10.0838 14.1785C7.95642 15.6515 6.56547 18.0013 6.57084 18Z" fill="#64748B"/>

    </svg>
);

export default memo(CuddlyNestIcon);
