import { convert_obj_to_query_string } from "@/src/utils/globalFunc";
import { getSideWidgetData } from "../services/checkoutPage";
import { voucherCoderRemove, voucherScreenLoading } from "./voucherCodeAction";

export const ROOM_DETAIL_UPDATE = 'ROOM_DETAIL_UPDATE';

export const roomDetialUpdate = (data) => {
    return { type: ROOM_DETAIL_UPDATE, data: data };
}

export const roomDetailUpdateAction = (bookingCode, new_pi) => {
    return async dispatch => {
          try {
            const roomDetails = await getSideWidgetData(
                convert_obj_to_query_string({ booking_id: bookingCode, new_pi : new_pi })
              );

            if (roomDetails.data) {
                dispatch(roomDetialUpdate(roomDetails.data));
                dispatch(voucherScreenLoading())
            }
          } catch(err) {
            dispatch(voucherScreenLoading())
            dispatch(voucherCoderRemove(bookingCode))
            alert(err.message)
          }
    }
}
