import { AuthenticationPromptType } from "@/utils/customHooks/useAuth";
import { useState } from "react";

export default function useAuthentication() {
    const [authState, setAuthState] = useState({
        isAuthPromptOpen: false,
        authPromptType: AuthenticationPromptType.Login
    });

    const openAuthPrompt = authPromptType =>
        setAuthState({
            isAuthPromptOpen: true,
            authPromptType
        });

    const closeAuthPrompt = () =>
        setAuthState(state => ({
            ...state,
            isAuthPromptOpen: false
        }));

    const changeAuthPromptType = authPromptType =>
        setAuthState(state => ({
            ...state,
            authPromptType
        }));

    const changeAuthPromptOpenState = isAuthPromptOpen =>
        setAuthState(state => ({
            ...state,
            isAuthPromptOpen: typeof isAuthPromptOpen === 'function'
                ? isAuthPromptOpen(state)
                : isAuthPromptOpen
        }));

    const toggleAuthPrompt = authPromptType =>
        setAuthState(state => ({
            ...state,
            authPromptType: authPromptType || state.authPromptType,
            isAuthPromptOpen: !state.isAuthPromptOpen
        }));

    return {
        ...authState,
        setAuthState,
        openAuthPrompt,
        closeAuthPrompt,
        changeAuthPromptType,
        changeAuthPromptOpenState,
        toggleAuthPrompt
    };
};
