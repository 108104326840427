const tSocketServerDomain = process.env.NEXT_PUBLIC_T_SOCKET_SERVER || 'https://tsocket.cuddlynest.com';
const tSocketApiEndpoint = `${tSocketServerDomain}/api`;

export const config = {
    cnEnvironment: process.env.CN_ENVIRONMENT,
    api_url: process.env.NEXT_PUBLIC_PHP_SERVER_URL + '/api',
    current_url: process.env.NEXT_PUBLIC_CURRENT_URL,
    api_url_geo: process.env.NEXT_PUBLIC_GEO_SERVER_URL,
    api_url_geo_neighbourhood: process.env.NEXT_PUBLIC_GEO_SERVER_NEIGHBOURHOOD_URL,
    // TODO: FE-865 revert
    api_web_services_endpoint_url: process.env.NEXT_PUBLIC_WEB_SERVICES_ENDPOINT_URL || 'https://gomnidevapi.cuddlynest.com',
    api_ver: 'v1',
    google_api_key: process.env.NEXT_PUBLIC_GOOGLE_MAP_API,
    google_map_id: process.env.NEXT_PUBLIC_GOOGLE_MAP_ID || 'f0342b2ff55a8ec4',
    fb_app_id: process.env.NEXT_PUBLIC_FB_APP_ID,
    google_client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    stripe_client_key: process.env.NEXT_PUBLIC_STRIPE_CLIENT_KEY,
    srp_ws_dummy: process.env.NEXT_PUBLIC_WS_SRP_DUMMY,
    srp_ws_real: process.env.NEXT_PUBLIC_WS_SRP_REAL,
    srp_ws_smw: process.env.NEXT_PUBLIC_WS_SRP_SMW,
    srp_api_smw: process.env.NEXT_PUBLIC_API_SRP_SMW || `${tSocketApiEndpoint}/availability`,
    ldp_ws_service: process.env.NEXT_PUBLIC_LDP_WS_SERVICE || 'wss://ldpstag.cuddlynest.com',
    geo_pages_api_base_url: process.env.NEXT_PUBLIC_GEO_PAGES_API_BASE_URL || 'https://geopages.cuddlynest.com', //'http://172.16.1.156',
    tSocketServerDomain,
    tSocketApiEndpoint,
    imagesUrl: process.env.NEXT_PUBLIC_IMAGES_URL || 'https://img.cuddlynest.com',
    resizedImagesUrl: process.env.NEXT_PUBLIC_RESIZED_IMAGES_URL || 'https://img.cuddlynest.com',
    watchlistApiBaseUrl: process.env.NEXT_PUBLIC_WATCHLIST_API || 'https://watchapi.cuddlynest.com',
    isCryptoEnabled: process.env.NEXT_PUBLIC_IS_CRYPTO_ENABLED
}