import {
    ONBOARD_INIT,
    ONBOARD_SET_VISIBILITY,
    ONBOARD_REMOVE_MSG
} from "../types";


export const initOnboard = (data) => {
    return (dispatch) => {
        dispatch({ type: ONBOARD_INIT, data });
    };
};

export const setElemVisibilityOnboard = (data) => {
    return (dispatch) => {
        dispatch({ type: ONBOARD_SET_VISIBILITY, data });
    };
};

export const removeMsgOnboard = (data) => {
    return (dispatch) => {
        dispatch({ type: ONBOARD_REMOVE_MSG, data });
    };
};
