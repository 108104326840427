import { memo } from "react";

const CloseSVG = () => (
    <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.78027 1.48523L5.29499 4.99995" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.29492 1.48523L1.7802 4.99995" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default memo(CloseSVG);
