import { useCallback } from "react";
import useValidators from '../hooks/useValidators';
import { getSearchQueryParams } from "../helpers";
import { SEARCH_RESULTS_FILTERS_BUDGET } from "@/redux/types";

export default function useBudget(asHook = true) {
    const applyValidators = useValidators(asHook);
    const fn = (priceRange = []) => {
        const queryParams = getSearchQueryParams({
            path: window.location.search,
            params: [
                { key: 'pricemin' },
                { key: 'pricemax' }
            ]
        });
        const budgetFromQueryParams = [queryParams.pricemin, queryParams.pricemax].map(Math.round);

        const isPriceRangeValid = Array.isArray(priceRange) && (priceRange.length === 2) && priceRange.every(Number.isSafeInteger);
        const isBudgetQueryParamsRangeValid = budgetFromQueryParams.every(Number.isSafeInteger);

        if (isPriceRangeValid && isBudgetQueryParamsRangeValid) {
            return applyValidators({ type: SEARCH_RESULTS_FILTERS_BUDGET, value: budgetFromQueryParams, initialValue: priceRange });
        }

        return isBudgetQueryParamsRangeValid ? budgetFromQueryParams : priceRange;
    };

    return asHook ? useCallback(fn, [applyValidators]) : fn;
}
