import InvalidGuestSelectionCriteriaException from "../exceptions/InvalidGuestSelectionCriteriaException";
import SearchCriteriaEntity from "../SearchCriteriaEntity";

export default class GuestModel extends SearchCriteriaEntity {
    adultCount;
    childCount;
    childAges;
    infantCount;

    roomCount;

    isVisited;

    constructor({
        adultCount = 2,
        childCount = 0,
        infantCount = 0,
        roomCount = 1,
        isVisited = false,
        childAges = []
    } = {}) {
        super();

        this.adultCount = adultCount;
        this.childCount = childCount;
        this.infantCount = infantCount;
        this.isVisited = isVisited;
        this.roomCount = roomCount;

        childAges.length = childCount;
        this.childAges = childAges;
    }

    get totalCount() {
        return this.adultCount + this.childCount;
        // return this.adultCount + this.childCount + this.infantCount;
    }

    get children() {
        return this.childAges.filter(Boolean);
    }

    setChildAge(childOrder = 0, age = 2) {
        this.childAges[childOrder] = age;
    }

    getChildAge(childOrder = 0) {
        return this.childAges[childOrder];
    }

    validate() {
        return this.isChildAgesValid();
    }

    isChildAgesValid() {
        let iteratedCount = 0;

        const isValid = this.childAges.every(age => {
            iteratedCount += 1;
            return Boolean(age);
        });

        return (this.childAges.length > 0)
            ? ((this.childAges.length === iteratedCount) && isValid)
            : true;
    }

    getValidationError() {
        if (!this.validate()) {
            return new InvalidGuestSelectionCriteriaException;
        }

        return null;
    }
}
