export default {
    ATTRACTION: 'ATTRACTION',
    AIRPORT: 'AIRPORT',
    HOSPITAL: 'HOSPITAL',
    PARK: 'PARK',
    TRANSIT: 'TRANSIT',
    SHOPPING: 'SHOPPING',
    FOOD_AND_DRINK: 'FOOD_AND_DRINK'
};

export const StylingStrategy = {
    SINGULAR: 'SINGULAR'
};
