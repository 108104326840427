import { CancelToken } from 'axios';

const CANCELLATION_NOTICE = '[CANCELLED BY USER]';

const AsyncActionStates = {
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
};

export const EntityRequestProcessingStatus = {
    UNINITIALIZED: 'UNINITIALIZED',
    IN_PROGRESS: 'IN_PROGRESS',
    RESOLVED_SUCCESS: 'RESOLVED_SUCCESS',
    RESOLVED_ERROR: 'RESOLVED_ERROR',
    CANCELLED: 'CANCELLED'
};

export const getEntityInitialState = (entity = {}) => ({
    entityPayload: {
        ...entity
    },
    entityRequestProcessingStatus: EntityRequestProcessingStatus.UNINITIALIZED,
    entityRequestProcessingError: null,
    entityRequestCancellationSource: null
});

export const entityStoreReducersMixin = ({
    actionType,
    entityStorePath,
    onLoading = defaultReducersHandler,
    onSuccess = defaultReducersHandler,
    onError = defaultReducersHandler
}) => ({
    [getActionTypeOfState({
        actionType,
        acyncActionState: AsyncActionStates.LOADING
    })]: reducerActionCreator({ entityStorePath, actionCallback: onLoading }),
    [getActionTypeOfState({
        actionType,
        acyncActionState: AsyncActionStates.SUCCESS
    })]: reducerActionCreator({ entityStorePath, actionCallback: onSuccess }),
    [getActionTypeOfState({
        actionType,
        acyncActionState: AsyncActionStates.ERROR
    })]: reducerActionCreator({ entityStorePath, actionCallback: onError })
});

export const processEntityRequest = ({
    // Request
    request,
    requestParams,

    // Store
    getState = globalState => globalState,
    actionType,
    dataTransformer = ({ data }) => data,

    // Callbacks
    onRequestInit,
    onRequestSuccess,
    onRequestError,
    onRequestCancel,

    // Cancellation
    getRequestCancellationNotice = () => CANCELLATION_NOTICE,
    getCancellationSource = () => CancelToken.source()
}) => async (dispatch, globalStateGetter) => {
    const { entityRequestProcessingStatus, entityRequestCancellationSource } = getState(globalStateGetter());
    if ([EntityRequestProcessingStatus.IN_PROGRESS].includes(entityRequestProcessingStatus)) {
        entityRequestCancellationSource.cancel(getRequestCancellationNotice());
    }

    const cancellationSource = getCancellationSource();

    dispatch({
        type: getActionTypeOfState({
            actionType,
            acyncActionState: AsyncActionStates.LOADING
        }),
        payload: {
            entityRequestProcessingStatus: EntityRequestProcessingStatus.IN_PROGRESS,
            entityRequestCancellationSource: cancellationSource
        }
    });
    onRequestInit?.({ actionType, cancellationSource, dispatch });

    let onActionCallback = null;
    let type = '';
    let payload = {
        entityRequestCancellationSource: null,
        entityRequestProcessingError: null
    };

    const acyncActionPromise = request(requestParams, cancellationSource.token)
        .then(response => {
            const entityPayload = dataTransformer(response);

            onActionCallback = onRequestSuccess;

            type = getActionTypeOfState({
                actionType,
                acyncActionState: AsyncActionStates.SUCCESS
            });

            payload = {
                ...payload,
                entityPayload,
                entityRequestProcessingStatus: EntityRequestProcessingStatus.RESOLVED_SUCCESS
            };

            return entityPayload;
        })
        .catch(entityRequestProcessingError => {
            const isRequestCancelled = (entityRequestProcessingError === getRequestCancellationNotice());

            onActionCallback = isRequestCancelled ? onRequestCancel : onRequestError;

            let entityRequestProcessingStatus = EntityRequestProcessingStatus.RESOLVED_ERROR;

            if (isRequestCancelled) {
                entityRequestProcessingStatus = EntityRequestProcessingStatus.CANCELLED;
            }

            type = getActionTypeOfState({
                actionType,
                acyncActionState: AsyncActionStates.ERROR
            });

            payload = {
                ...payload,
                entityRequestProcessingStatus,
                entityRequestProcessingError,
            };
        })
        .finally(() => {
            dispatch({
                type,
                payload
            });

            onActionCallback?.(
                payload.entityRequestProcessingError ??
                payload.entityPayload
            );
        });

    return [acyncActionPromise, cancellationSource];
};

function defaultReducersHandler(state, action) {
    return {};
}

function getActionTypeOfState({ actionType, acyncActionState }) {
    return `${actionType}_${acyncActionState}`;
}

function reducerActionCreator({ entityStorePath, actionCallback }) {
    return (state, action) => ({
        ...state,
        [entityStorePath]: {
            ...state[entityStorePath],
            ...action.payload,
            entityPayload: {
                ...state[entityStorePath].entityPayload,
                ...action.payload.entityPayload
            },
            ...actionCallback(state, action)
        }
    })
}
