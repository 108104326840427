// import moment from 'moment'
// import { refreshToken } from '@/redux/services/authApi';
import JwtDecode from "jwt-decode";

// TODO: consider to delete this file

/* export const setupLogin = data => {
  if(!!data) {
    localStorage.setItem('token', data.token);
    localStorage.setItem('refreshToken', data.refresh_token);
    localStorage.setItem('expiryTime', data.exp);
    localStorage.setItem('userData', JSON.stringify(data));
    setupRefreshTimer();
  }
} */

export const isAuth = () => {
  const token = localStorage.getItem("token");
  return token || null;
};

export const getUser = () => {
  const token = isAuth();
  if (token) {
    const data = JwtDecode(token);
    return data;
  }
  return null;
};

/* //Refrsh Token
let refrestTimer;
export const setupLogout = (search = null, to = null) => {
  if(!!refrestTimer){
    clearInterval(refrestTimer)
    refrestTimer = null;
  }
  localStorage.clear();
  if(!!search){
    if(!!to){
      // window.location.href(`/${to}${search}`)
    }else{
      // router.push(`/`)
    }
  }else{
    if(!!to){
      // router.push(`/${to}`)
    }else{
      // router.push('/')
    }
  }
}

export const setupRefreshTimer = () => {
  if(!!isAuth()){
    const expiryToken = localStorage.getItem('expiryTime')
    const duration = moment(new Date(expiryToken * 1000)).diff(new Date(), 'seconds')
    refrestTimer = setInterval(async() => {
      callRefresh()
    }, (duration - 100) * 1000)
  }else{
    logout()
  }
}

export const callRefresh = async _ => {
  const refreshData = await refreshToken();
    if(refreshData.statusCode === 200 || refreshData.statusCode === 201){
      setupLogin(refreshData.data)
    }else{
      logout()
    }
} */
