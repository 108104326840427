/* eslint-disable no-nested-ternary */
import axios from "axios";
import { config } from "../../utils/config";
/**
 * Request Wrapper with default success/error actions
 */
const requestToken = (options) => {
  /**
   * Create an Axios Client with defaults
   */
  const client = axios.create({
    baseURL: options.geo
      ? config.api_url_geo
      : options.dev3
      ? `${config.current_url}/api`
      : config.api_url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-api-key": process.env.NEXT_PUBLIC_API_KEY,
      "X-Authorization": `Bearer ${localStorage.getItem(
        options.tokenType ?? "token"
      )}`,
    },
  });

  const onSuccess = (response) => response.data;

  const onError = async (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        // If refresh token expires
        // if(options.url.includes('refresh')){
        //     logout()
        // }else{
        //     //If other api's fails
        //     await callRefresh()
        //     setupRefreshTimer();
        //     request(options);
        // }
      }
      if (error.response.status === 404) {
        // history.push('/404')
      }
      return Promise.reject(
        error.response.data
          ? error.response.data
          : error.response || error.message
      );
    }
    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      // history.push('/app/error/500')

      return Promise.reject(error.request);
    }
    // Something else happened while setting up the request
    // triggered the error
    // history.push('/app/error/500')
    return Promise.reject(error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default requestToken;

export const requestQuery = ({ url, searchParams, strategy, ...rest }) =>
  requestToken({
    ...rest,
    url: makeUrl({
      url,
      searchParams,
      strategy,
    })
  });

function makeUrl({ url, searchParams = {}, strategy = 'append' }) {
  return [
    url,
    queryParams({ strategy, data: searchParams })
  ]
    .filter(Boolean)
    .map(instance => instance.toString())
    .join('?');
}

function queryParams({
  strategy = 'append',
  data = {},
  searchParams = new URLSearchParams()
}) {
  return Object.entries(data)
    .reduce((searchParams, [key, value]) => {
      searchParams[strategy](key, value);

      return searchParams;
    }, searchParams);
}
