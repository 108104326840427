export default class Lists {
    constructor(
      data,
      listName,
      selected,
      status,
      id
    ) {
      this.data = data;
      this.listName = listName;
      this.selected = selected;
      this.status = status;
      this.id = id;
    }
}