export const MIN_ALLOWED_CHILD_AGE = 2;
export const MAX_ALLOWED_CHILD_AGE = 17;

export const guestAgeTypes = {
    INFANT: 'INFANT',
    CHILD: 'CHILD',
    ADULT: 'ADULT'
};

export const getGuestUnit = (guestAgeType, age = '') => ({ guestAgeType, age });

export const filterByGuestTypeArray = value =>
    guestAgeType => () => value.filter(guest => guest.guestAgeType === guestAgeType);

export const getGuestUnitsByNumber = (count, guestAgeType) => new Array(count).fill(getGuestUnit(guestAgeType));

export const childAgeValidator = inputValue => {
    let value = Number.parseInt(inputValue);
    let hasError = false;

    if (Number.isNaN(value) || value < MIN_ALLOWED_CHILD_AGE) {
        value = MIN_ALLOWED_CHILD_AGE;
        hasError = true;
    } else if (value > MAX_ALLOWED_CHILD_AGE) {
        value = MAX_ALLOWED_CHILD_AGE;
        hasError = true;
    }

    return {
        isValid: !hasError,
        value,
        payload: hasError ? `Child age should be between ${MIN_ALLOWED_CHILD_AGE} and ${MAX_ALLOWED_CHILD_AGE}` : ''
    };
};

export const filterInvalidKids = array => array.filter(({ age }) => childAgeValidator(age).isValid);

export const getCountByGuestType = (guests, guestType = guestAgeTypes.ADULT) => guests.reduce((sum, { guestAgeType }) => guestAgeType === guestType ? sum + 1 : sum, 0);
