import { revokeVoucher, validatVoucher } from "../services/checkoutPage";
import { roomDetailUpdateAction } from "./roomDetailAction";

export const VOUCHER_CODE_ADD = "VOUCHER_CODE_ADD"
export const VOUCHER_CODE_REMOVE = "VOUCHER_CODE_REMOVE"
export const VOUCHER_CODE_ADD_ERROR = "VOUCHER_CODE_ADD_ERROR"
export const VOUCHER_CODE_REMOVE_ERROR = "VOUCHER_CODE_REMOVE_ERROR"
export const VOUCHER_CODE_LOADING = "VOUCHER_CODE_LOADING"
export const VOUCHER_SCREEN_LOADING = "VOUCHER_SCREEN_LOADING"
export const RESET_STATE = 'RESET_STATE';

export const resetState = () => ({
  type: RESET_STATE,
});

export const voucherCodediscount = (data) => {
    return { type: VOUCHER_CODE_ADD, data: data };
}

export const voucherCodeDiscountRemove = (data) => {
    return { type: VOUCHER_CODE_REMOVE, data: data };
}

export const voucherCodeDiscountError = (data) => {
    return { type: VOUCHER_CODE_ADD_ERROR, data: data };
}

export const voucherCodeDiscountRemoveError = (data) => {
    return { type: VOUCHER_CODE_REMOVE_ERROR, data: data };
}
export const voucherCodeLoading = () => {
    return { type: VOUCHER_CODE_LOADING };
}
export const voucherScreenLoading = () => {
    return { type: VOUCHER_SCREEN_LOADING };
}

export const voucherCoderAdd = (voucherCode, bookingCode) => {
    return async dispatch => {
        dispatch(voucherCodeLoading());
        const token = localStorage?.getItem('token');
        try {
            const discountResponse = await validatVoucher(voucherCode, bookingCode,token)
            dispatch(voucherCodediscount(discountResponse));
            dispatch(roomDetailUpdateAction(bookingCode))
        } catch (error) {
            dispatch(voucherCodeDiscountError(error));
        }
    }
};

export const voucherCoderRemove = (bookingCode) => {
    return async dispatch => {
        dispatch(voucherCodeLoading());
        try {
            const removedResponse = await revokeVoucher(bookingCode)
            dispatch(voucherCodeDiscountRemove(removedResponse));
            dispatch(roomDetailUpdateAction(bookingCode))
        } catch (error) {
            dispatch(voucherCodeDiscountRemoveError(error));
        }
    }
};