import { ROOM_DETAIL_UPDATE } from '../actions/roomDetailAction';

const initialState = {
    data: null
};

const roomDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROOM_DETAIL_UPDATE:
            return action.data
        default:
            return state;
    }
};

export default roomDetailReducer;