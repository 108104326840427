import { memo } from "react";

const HostBookingList = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        
        <path d="M7.28846 17.885H9.24819C8.33993 16.787 7.84398 15.4061 7.84615 13.9811C7.84615 11.9065 8.87565 10.0717 10.4528 8.96191H4.5V15.0965C4.5 15.8361 4.79378 16.5453 5.31672 17.0683C5.83966 17.5912 6.54892 17.885 7.28846 17.885Z" fill="#64748B"/>
<path d="M17.8846 7.84615H4.5V7.28846C4.5 6.54892 4.79378 5.83966 5.31672 5.31672C5.83966 4.79378 6.54892 4.5 7.28846 4.5H15.0962C15.8357 4.5 16.545 4.79378 17.0679 5.31672C17.5908 5.83966 17.8846 6.54892 17.8846 7.28846V7.84615Z" fill="#64748B"/>
<circle cx="14" cy="14" r="5" fill="#64748B"/>
<path d="M14.04 11.352C14.568 11.352 14.9947 11.5013 15.32 11.8C15.6453 12.0987 15.808 12.4987 15.808 13C15.808 13.56 15.6347 13.976 15.288 14.248C14.9413 14.5147 14.472 14.648 13.88 14.648L13.856 15.408H13.224L13.192 14.144H13.424C13.952 14.144 14.3653 14.064 14.664 13.904C14.9627 13.744 15.112 13.4427 15.112 13C15.112 12.68 15.016 12.4267 14.824 12.24C14.632 12.0533 14.3733 11.96 14.048 11.96C13.7173 11.96 13.456 12.0507 13.264 12.232C13.0773 12.408 12.984 12.6507 12.984 12.96H12.296C12.296 12.64 12.368 12.36 12.512 12.12C12.656 11.8747 12.8587 11.6853 13.12 11.552C13.3867 11.4187 13.6933 11.352 14.04 11.352ZM13.536 17.048C13.3973 17.048 13.28 17 13.184 16.904C13.088 16.808 13.04 16.6907 13.04 16.552C13.04 16.4133 13.088 16.296 13.184 16.2C13.28 16.104 13.3973 16.056 13.536 16.056C13.6693 16.056 13.7813 16.104 13.872 16.2C13.968 16.296 14.016 16.4133 14.016 16.552C14.016 16.6907 13.968 16.808 13.872 16.904C13.7813 17 13.6693 17.048 13.536 17.048Z" fill="white"/>
    </svg>
);

export default memo(HostBookingList);
