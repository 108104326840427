
export const messages = [
    {
        name: 'srpCardHeart',
        content: "Tap to save to your lists or to track price changes.",
        placement: 'left',
    },
    {
        name: 'srpCardPoi',
        content: "Hover over each icon to view various points of interest near this property.",
        placement: 'top',
    },
    {
        name: 'srpMapLandmark',
        content: "Drop a pin to find stays near your preferred interests or activities.",
        placement: 'right',
    },
    {
        name: 'srpMapRecenter',
        content: "Re-Center",
        placement: 'left',
    },
    {
        name: 'srpMapExpand',
        content: "Expand the map and browse by neighborhood!",
        placement: 'top',
    },
    {
        name: 'srpFiltersAttractions',
        content: "Click here to filter your results based on nearby attractions.",
        placement: 'bottom',
    },
    {
        name: 'ldpNearby',
        content: "Explore landmarks, airports, parks, shopping areas, and public transport near this property.",
        placement: 'right',
    }
];
