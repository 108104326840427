import ReactGA from 'react-ga';
import { ECType } from "@/static/contants"
import { constants } from "./constants"

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    if (process.env.NODE_ENV === 'development') {
        return;
    }

    ReactGA.initialize(constants.gaId);
    ReactGA.pageview(url);

    window.gtag('config', constants.gaTrackingId, {
        page_path: url,
    });
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value, nonInteraction }) => {
    if (process.env.NODE_ENV === 'development') {
        return;
    }

    ReactGA.initialize(constants.gaId);

    const eventCategory = category ?? getCategoryName();

    ReactGA.event({
        category: eventCategory,
        action,
        label,
        value,
        nonInteraction
    });

    window.gtag('event', action, {
        event_category: eventCategory,
        event_label: label,
        value,
        non_interaction: nonInteraction
    });
}

export function getCategoryName(url) {
    const locationPathname = url ?? (typeof location !== 'undefined' ? location.pathname : null);

    if (!locationPathname) {
        return;
    }

    if (ECType[locationPathname]) {
        return ECType[locationPathname];
    }

    for (const [locationKey, locationName] of Object.entries(ECType)) {
        if (locationKey === '/') {
            continue;
        }

        if (locationPathname.startsWith(locationKey)) {
            return locationName;
        }
    }

    return locationPathname;
}
