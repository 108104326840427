import { createContext, memo } from "react";
import useCurrency from "./useCurrency";

export const CurrencyContext = createContext(null);

const CurrencyProvider = ({ children }) => (
    <CurrencyContext.Provider
        value={useCurrency()}
    >
        {children}
    </CurrencyContext.Provider>
);

export default memo(CurrencyProvider);
