
import { config } from "@/src/utils/config";
import requestToken from "./requestToken";
import api from "services/API";

export const getListsFromAPI = () =>
    {
        const user_id = api.getUserId();

        return requestToken({
            url: `/${config.api_ver}/wishlists?user_id=${user_id}&listings=1`,
            method: 'GET',
            dev3: true
        });
    }

export const postListToAPI = (data) =>
    requestToken({
        url: `/${config.api_ver}/wishlist`,
        method: 'POST',
        dev3: true,
        data
    });

export const updateListToAPI = (data) =>
    requestToken({
        url: `/${config.api_ver}/wishlists`,
        method: 'POST',
        dev3: true,
        data
    });
export const updateListNameToAPI = (data,id) =>
    requestToken({
        url: `/${config.api_ver}/wishlist/${id}`,
        method: 'POST',
        dev3: true,
        data
    });

export const deleteListFromAPI = (data) =>
    requestToken({
        url: `/${config.api_ver}/wishlists`,
        method: 'POST',
        dev3: true,
        data
    });