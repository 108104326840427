import { memo } from "react";

const LogoutProgressive = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M15 13V11H17L20 11.5V12.5L17 13H15Z" fill="#64748B" />
        <path d="M21.4737 12.5023C21.7664 12.1949 21.7605 11.71 21.4603 11.4097L17.7574 7.70688C17.3669 7.31636 16.7337 7.31636 16.3432 7.70688C15.9527 8.09741 15.9527 8.73057 16.3432 9.1211L18.0563 10.8342C18.0927 10.8706 18.1319 10.9041 18.1734 10.9345L20.4457 12.5945C20.7629 12.8263 21.2027 12.7868 21.4737 12.5023Z" fill="#64748B" />
        <path d="M21.4485 11.4973C21.7413 11.8048 21.7354 12.2897 21.4351 12.5899L17.7323 16.2928C17.3417 16.6833 16.7086 16.6833 16.3181 16.2928C15.9275 15.9022 15.9275 15.2691 16.3181 14.8785L18.0311 13.1655C18.0675 13.1291 18.1067 13.0955 18.1483 13.0651L20.4205 11.4051C20.7377 11.1733 21.1776 11.2128 21.4485 11.4973Z" fill="#64748B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H13C14.1046 20 15 19.1046 15 18V13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H15V6C15 4.89543 14.1046 4 13 4Z" fill="#64748B" />
    </svg>
);

export default memo(LogoutProgressive);
