import { constants } from "@/utils/constants";

let start = null;
let end = null;
let distance = null;
const mob_start = null;
const mob_end = null;
const mob_distance = null;

export const _setScrollDeskTab = (path) => {
  if (window.innerWidth > 600) {
    if (!start) {
      start = window.pageYOffset;
    }

    end = window.pageYOffset;
    distance = end - start;

    const elem = document.getElementById("mainHeader");

    if (window.location.pathname === "/") {
      if (elem) {
        const logoWord = document.getElementsByClassName("cnword")[0];
        const logoColorPurple = [
          ...document.getElementsByClassName("logo-purple"),
        ];
        const logoColorPink = [...document.getElementsByClassName("logo-pink")];

        const setPlus = window.innerWidth > 600 ? 50 : 74;
        const windowHeight = window.innerHeight + setPlus;
        const elementHeight = elem.offsetHeight;
        let whereToFix;
        if (window.innerHeight < 450) {
          const bgHeight =
            document.getElementsByClassName("videoBgWrapper")[0]?.offsetHeight;
          whereToFix = bgHeight;
        } else {
          whereToFix = windowHeight - elementHeight;
        }
        function fillElementsColors(el, color) {
          el.map((each) => (each.style.fill = color));
        }
        if (end >= whereToFix) {
          elem.style.cssText = `opacity: 1; visibility: visible; transition: opacity 0.3s ease, visibility 0.3s ease;`;
          elem.classList.add("sticky");
          elem.style.top = "0px";
          if (logoWord) {
            logoWord.classList.add("logo-word-remove");
            fillElementsColors(logoColorPurple, "#4C35BC");
            fillElementsColors(logoColorPink, "#FA368B");
            /* fillElementsColors(logoColorPurple,'#242526');
                        fillElementsColors(logoColorPink,'#242526'); */
          }
        } else if (end < whereToFix && end > elementHeight + 50) {
          elem.style.cssText = `opacity: 0; visibility: hidden;`;
          elem.classList.remove("sticky");
          if (logoWord) {
            logoWord.classList.remove("logo-word-remove");
            fillElementsColors(logoColorPurple, "#4C35BC");
            fillElementsColors(logoColorPink, "#FA368B");
            // fillElementsColors(logoColorPurple,'#242526');
            // fillElementsColors(logoColorPink,'#242526');
          }
        } else {
          elem.style.cssText = `opacity: 1; visibility: visible; transition: none`;
        }
      }
    }
  }
};

let lastScrollTop = 0;
const inputPositionFromTop = [];

export const _setScrollMobile = (e) => {
  if (window.innerWidth < 600 && window.innerHeight > 450) {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    const firstSectionHeight = window.innerHeight - 74;
    if (window.location.pathname === "/") {
      function offset(el) {
        const rect = el.getBoundingClientRect();
        const scrollLeft =
          window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
      }
      const div = document.querySelector("#stickySearch");
      if (div) {
        const divOffset = offset(div);
        inputPositionFromTop.push(divOffset.top + 52);
      }

      if (st > inputPositionFromTop[0]) {
        const elem = document.getElementById("stickySearch");
        const elem2 = document.getElementById("banner_search");
        // elem?.classList.add('stickysearch-box')
      } else {
        const elem = document.getElementById("stickySearch");
        // elem?.classList.remove('stickysearch-box')
      }

      const elem = document.getElementById("mob-header");
      const logoWord = document.getElementsByClassName("cnword")[0];
      const logoColorPurple = [
        ...document.getElementsByClassName("logo-purple"),
      ];
      const logoColorPink = [...document.getElementsByClassName("logo-pink")];

      const windowHeight = window.innerHeight + 74;
      const elementHeight = elem.offsetHeight;
      const whereToFix = windowHeight - elementHeight;

      function fillElementsColors(el, color) {
        el.map((each) => (each.style.fill = color));
      }

      if (st > firstSectionHeight) {
        if (st > lastScrollTop) {
          const elem = document.getElementById("mob-header");
          if (elem) {
            elem.classList.remove("sticky");
            logoWord.classList.add("logo-word-remove");
            fillElementsColors(logoColorPurple, "#4C35BC");
            fillElementsColors(logoColorPink, "#FA368B");
          }
        } else {
          const elem = document.getElementById("mob-header");
          if (elem) {
            elem.classList.add("sticky", "top-header");
          }
        }
      }
      if (st < whereToFix) {
        const elem = document.getElementById("mob-header");
        elem?.classList.remove("sticky", "top-header");
        logoWord.classList.remove("logo-word-remove");
        fillElementsColors(logoColorPurple, "#4C35BC");
        fillElementsColors(logoColorPink, "#FA368B");
        /* fillElementsColors(logoColorPurple,'#242526');
                fillElementsColors(logoColorPink,'#242526'); */
      }
      lastScrollTop = st <= 0 ? 0 : st;
    } else {
      const logoWord = document.getElementsByClassName("cnword")[0];

      if (st > 0) {
        logoWord?.classList.add("logo-word-remove");
        if (st > lastScrollTop) {
          const elem = document.getElementById("mob-header");
          if (elem) {
            elem.classList.remove("sticky");
          }
        } else {
          const elem = document.getElementById("mob-header");
          if (elem) {
            elem.classList.add("sticky", "top-header");
          }
        }
      } else {
        logoWord ? logoWord.classList.remove("logo-word-remove") : null;
        const elem = document.getElementById("mob-header");
        elem?.classList.remove("top-header");
      }
      lastScrollTop = st <= 0 ? 0 : st;
    }
  }
  if (window.innerWidth > 500 && window.innerHeight < 450) {
    if (!start) {
      start = window.pageYOffset;
    }

    end = window.pageYOffset;
    distance = end - start;

    const elem = document.getElementById("mob-header");

    if (window.location.pathname === "/") {
      if (elem) {
        const logoWord = document.getElementsByClassName("cnword")[0];
        const logoColorPurple = [
          ...document.getElementsByClassName("logo-purple"),
        ];
        const logoColorPink = [...document.getElementsByClassName("logo-pink")];

        const setPlus = window.innerWidth > 600 ? 50 : 74;
        const windowHeight = window.innerHeight + setPlus;
        const elementHeight = elem.offsetHeight;
        const whereToFix = windowHeight + 25;

        function fillElementsColors(el, color) {
          el.map((each) => (each.style.fill = color));
        }
        if (end >= whereToFix) {
          elem.style.cssText = `opacity: 1; visibility: visible; transition: opacity 0.3s ease, visibility 0.3s ease;`;
          elem.classList.add("sticky");
          elem.style.top = "0px";
          if (logoWord) {
            logoWord.classList.add("logo-word-remove");
            fillElementsColors(logoColorPurple, "#4C35BC");
            fillElementsColors(logoColorPink, "#FA368B");
          }
        } else if (end < whereToFix && end > elementHeight + 50) {
          elem.style.cssText = `opacity: 0; visibility: hidden;`;
          elem.classList.remove("sticky");
          if (logoWord) {
            logoWord.classList.remove("logo-word-remove");
            fillElementsColors(logoColorPurple, "#4C35BC");
            fillElementsColors(logoColorPink, "#FA368B");
            /* fillElementsColors(logoColorPurple,'#242526');
                        fillElementsColors(logoColorPink,'#242526'); */
          }
        } else {
          elem.style.cssText = `opacity: 1; visibility: visible; transition: none`;
        }
      }
    }
  }
};

export const getScreenWidth = () =>
  (document.body || document.documentElement).clientWidth;

export const isMobile = () => {
  if (!document) return false;
  if (getScreenWidth() <= constants.mobile_max_width) return true;
  return false;
};
export const isTablet = () => {
  if (!document) return false;
  if (
    getScreenWidth() > constants.mobile_max_width &&
    getScreenWidth() <= constants.tablet_max_width
  )
    return true;
  return false;
};
export const isDesktop = () => {
  if (!document) return false;
  if (getScreenWidth() > constants.tablet_max_width) return true;
  return false;
};

export const isAboutUs = (router) => {
  if (router.asPath.startsWith(constants.about_us_ruote)) return true;
  return false;
};

export const isContactUs = (router) => {
  if (router.asPath.startsWith(constants.contact_us_route)) return true;
  return false;
};

export const isVrGeoPage = (router) => {
  if (router.asPath.startsWith(`/${constants.vacation_rentals_route}`))
    return true;
  return false;
};

export const isCheckoutPage = (router) => {
  if (router.asPath.startsWith(`${constants.checkout_page_route}`)) return true;
  return false;
};
export const isCheckoutConfirmationPage = (router) => {
  if (router.asPath.startsWith(`${constants.checkoutconfirmation_page_route}`))
    return true;
  return false;
};
export const isHotelGeoPage = (router) => {
  if (router.asPath.startsWith(`/${constants.hotels_route}`)) return true;
  return false;
};

export const isHomePage = (router) => {
  if (router.asPath === "/") return true;
  return false;
};

export const isMobileCal = (router) => {
  if (router.asPath === "/" || router.asPath.startsWith(`/deals`)) return true;
  return false;
};

export const sanitizeGuestFormFieldName = (name) =>
  name.replace("[", "__").replace("]", "__");

export const unsanitizeGuestFormFieldName = (name) =>
  name.replace("__", "[").replace("__", "]");
export const displayList = (arr) => {
    let result = '';
    arr.forEach((item, i) => {
      if (i === 0) {
        result += item;
      } else if (arr[i + 1]) {
        result += `, ${item}`;
      } else {
        result += ` & ${item}`;
      }
    });
    return result;
  };