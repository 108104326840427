import { DestinationTypeEnum } from "@/static/contants";
import { normalizeLocation, normalizeLocationString } from "@/utils/locationNormalizers";
import InvalidSearchCriteriaException from "../exceptions/InvalidSearchCriteriaException";
import SearchCriteriaEntity from "../SearchCriteriaEntity";

export default class DestinationModel extends SearchCriteriaEntity {
    id;
    name;
    address;
    type;
    slug;

    inputValue;
    isLoading;

    constructor({
        id,
        name,
        address,
        type,
        slug,
        viewportBounds,
        zoomLevel,
        mobileZoomLevel,
        location,
        propertyCount,
        inputValue = '',
        isLoading = true
    } = {}) {
        super();

        this.id = id;
        this.name = name;
        this.address = address;
        this.type = type;
        this.slug = slug;
        this.viewportBounds = viewportBounds;
        this.zoomLevel = zoomLevel;
        this.mobileZoomLevel = mobileZoomLevel;
        this.location = location;
        this.propertyCount = propertyCount;
        this.inputValue = inputValue;
        this.isLoading = isLoading;
    }

    get searchTerm() {
        if (this.name || this.address) {
            return normalizeLocation(this.rawSearchTerm);
        }
        return this.inputValue;
    }

    get rawSearchTerm() {
        return normalizeLocationString(`${this.name}, ${this.address}`);
    }

    /**
     * Motherfucking business requirements lead us to the creepy code.
     * Fuck you bitches.
     */
    get typeQueryParam() {
        if (!this.id) {
            return DestinationTypeEnum.AROUND;
        }

        return this.type;
    }

    get locationQueryParam() {
        const locationType = this.typeQueryParam;

        switch (locationType) {
            case DestinationTypeEnum.AROUND: {
                return this.address;
            }

            default: {
                return this.rawSearchTerm;
            }
        }
    }

    toQueryParam() {
        return {
            ...this,
            type: this.typeQueryParam,
            rawSearchTerm: this.locationQueryParam
        };
    }
    /**
     * End of business shit
     */

    validate() {
        return Boolean(this.inputValue); // Boolean(this.name || this.address);
    }

    getValidationError() {
        if (!this.validate()) {
            return new InvalidSearchCriteriaException('Please select or enter a location', this);
        }

        return null;
    }
}
