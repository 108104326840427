import { getUser } from "@/src/utils/authFunctions";
import { getListsFromAPI, postListToAPI, updateListToAPI, updateListNameToAPI } from '@/redux/services/wishList';

import Lists from "@/components/common/SaveWishList/models/lists";
import WishList from "@/components/common/SaveWishList/data/WishLists";
import { PRODUCT_UPDATE } from "../types";

export const INIT_LIST = "INIT_LIST";
export const RESET_LIST = "RESET_LIST";
export const NOTLOGGED_LIST = "NOTLOGGED_LIST";
export const ADD_TO_LIST = "ADD_TO_LIST";
export const SAVE_TO_LIST = "SAVE_TO_LIST";
export const EDIT_LIST = "EDIT_LIST";
export const TOGGLE_LIST = 'TOGGLE_LIST';
export const HOVER_CARD = 'HOVER_CARD';
export const RESIZE_WINDOW = 'RESIZE_WINDOW';
export const SAVE_TO_WISHLIST_PROPERTY = 'SAVE_TO_WISHLIST_PROPERTY';
export const TOGGLE_SAVE_TO_WISHLIST_MODAL = 'TOGGLE_SAVE_TO_WISHLIST_MODAL';
export const DELETE = 'DELETE';

const retrieve = async (data,action,dispatch) => {
    const listsAPI = await getListsFromAPI();
    const normalizedList = listsAPI.data.map(({ listings, name, id }) => new Lists(listings.filter(l => l.product_id != null).map(listing => new WishList(listing)), name, false, false, id));
    let check = false;
    let newArr = [];
    for(let x in data){
        check = false;
        for(let y of normalizedList){
            if(data[x].listName === y.listName || data[x].id === y.id && !check){
                const listID = data[x];
                if(action == 'S'){
                    if(y.data.length > 0){
                        data[x].data.map(el1 => {
                            y.data.map(el2 => {
                                if(el1.id !== el2.id){
                                    let data = {
                                        wishlist: [
                                            {
                                                wishlists_ids: [
                                                    listID.id
                                                ],
                                                listings_ids: [
                                                    el1.id
                                                ]
                                            }
                                        ]
                                        };
                                    updateListToAPI(data);
                                    y.data.push(el1);
                                }
                            });
                        });
                    }else{
                            data[x].data.map(el1 => {
                                let data = {
                                    wishlist: [
                                        {
                                            wishlists_ids: [
                                                listID.id
                                            ],
                                            listings_ids: [
                                                el1.id
                                            ]
                                        }
                                    ]
                                };
                            updateListToAPI(data);
                        })
                    }
                }else{
                    let results = data[x].data.filter(({ id: id1 }) => !y.data.some(({ id: id2 }) => id2 === id1));
                    results.unshift(y.id);
                    let dataToSend = {
                        wishlist: [
                            {
                                wishlists_ids: [],
                                listings_ids: []
                            }
                        ]
                    }
                    if(results.length > 1){
                        results.map((el,i) => {
                            if(i == 0){
                                dataToSend.wishlist[0].wishlists_ids.push(results[0]);
                            }else{
                                dataToSend.wishlist[0].listings_ids.push(Number(el.id));
                            }
                        });
                        updateListToAPI(dataToSend);
                        if(y.id === results[0]){
                            results.map((el,i) => {
                                if(i > 0){
                                    y.data.push(el);
                                }
                            })
                        }
                    }

                    if(data[x].id === y.id && data[x].listName !== y.listName){
                        let name = data[x].listName;
                        let id = data[x].id;
                        let dataToSend = {
                            name
                        }
                        updateListNameToAPI(dataToSend,id);
                    }
                }
                check = true;
            }
        }
        !check && newArr.push(data[x]);
    }
    if(action == 'I'){
        if(newArr.length > 0){
            newArr.map(el => {
                let listings = [];
                el.data.map(el => listings.push(Number(el.id)));
                let newlistPost = {
                    name: el.listName,
                    listings_ids: listings
                };
                postListToAPI(newlistPost).then( () => {
                    localStorage.setItem("wishlist", JSON.stringify([]));
                    dispatch(initWishList());
                });
            });
        }
        const newList = [...normalizedList, ...newArr];
        return newList;
    }else{
        return newArr;
    }
}

export const addToList = (listName) => {
    return { type: ADD_TO_LIST, listName: listName };
};

export const saveItem = (data) => {
    return { type: SAVE_TO_LIST, list: data };
}

export const saveToList = (newlist, listedItem) => {
    const isLogged = getUser();
    return async dispatch => {
        if(!isLogged){
            dispatch(saveItem(newlist));
        } else {
            const wishlists_ids = [];
            const new_wishlists_names = [];
            newlist.map(el => {
                if(typeof el === 'string') {
                    new_wishlists_names.push(el)
                } else {
                    wishlists_ids.push(el)
                }
            })
            const newWishlist = {
                wishlist: [{
                        wishlists_ids: wishlists_ids,
                        listings_ids: [listedItem],
                        new_wishlists_names: new_wishlists_names
                    }]
                }
                return updateListToAPI(newWishlist).then(() => {
                    dispatch(initWishList());
                    dispatch({
                        type: PRODUCT_UPDATE,
                        payload: {
                            is_wished:true,
                        }})
                });
        }
    }
}

export const editList = (newlist) => {
    return { type: EDIT_LIST, list: newlist };
};

export const getLists = (data) => {
    return { type: INIT_LIST, data: data };
}

export const initWishList = () => {
    return async dispatch => {
        const isLogged = getUser();
        if(isLogged){
            const listsAPI = await getListsFromAPI();
            const normalizedList = listsAPI.data.map(({ listings, name, id }) => new Lists(listings.filter(l => l.product_id != null).map(listing => new WishList(listing)), name, false, false, id));
            dispatch(getLists(normalizedList));
        }
        return;
        /* const isLogged = getUser();
        const localStorageData = localStorage.getItem("wishlist");
        if(!isLogged){
            if(localStorageData){
                let dataParsed = JSON.parse(localStorageData);
                dispatch(getLists(dataParsed));
            }else{
                dispatch(getLists([]));
            }
        }else{
            if(localStorageData){
                let dataParsed = JSON.parse(localStorageData);
                let newList = await retrieve(dataParsed,'I',dispatch);
                localStorage.setItem("wishlist", JSON.stringify(newList));
                dispatch(getLists(newList));
            }else{
                const listsAPI = await getListsFromAPI();
                const normalizedList = listsAPI.data.map(({ listings, name, id }) => new Lists(listings.filter(l => l.product_id != null).map(listing => new WishList(listing)), name, false, false, id));
                localStorage.setItem("wishlist", JSON.stringify(normalizedList));
                dispatch(getLists(normalizedList));
            }
        } */
    }
};

export const resetList = (data) => {
    return { type: RESET_LIST, list: data };
};

export const notLoggedListSet = () => {
    return { type: NOTLOGGED_LIST};
}

export const deleteList = (data,lists,action) => {
    if(action === 'item'){
        lists.map(el => {
            if(el.listName === data.listName){
                el.data.map(el2 => {
                    if(el2.id === data.delete){
                        let index = el.data.indexOf(el2);
                        el.data.splice(index, 1);
                    }
                });
            }
        });
    }else{
        lists.map(el => {
            if(el.listName === data){
                let index = lists.indexOf(el);
                lists.splice(index, 1);
            }
        });
    }
    localStorage.setItem("wishlist", JSON.stringify(lists));
    return { type: DELETE, list: lists };
};

export const saveToWishlistProperty = saveToWishlistProperty => ({
    type: SAVE_TO_WISHLIST_PROPERTY,
    payload: { saveToWishlistProperty }
});

export const toggleSaveToWishlistModal = isSaveToWishlistModalOpen => ({
    type: TOGGLE_SAVE_TO_WISHLIST_MODAL,
    payload: { isSaveToWishlistModalOpen }
});

export const toggleList = activeListName => ({
    type: TOGGLE_LIST,
    payload: { activeListName }
});

export const hoverCard = hoveredProperty => ({
    type: HOVER_CARD,
    payload: { hoveredProperty }
});

export const resizeWindow = resObject => ({
    type: RESIZE_WINDOW,
    payload: { resObject }
});
