import { constants } from '@/utils/constants';
import moment from 'moment';
import SearchCriteriaEntity from '../SearchCriteriaEntity';
import InvalidDateRangeSelectionCriteriaException from '../exceptions/InvalidDateRangeSelectionCriteriaException';
import { formatDate } from '@/utils/globalFunc';

export default class DateRangeModel extends SearchCriteriaEntity {
    startDate;
    endDate;

    constructor({ startDate = null, endDate = null } = {}) {
        super();

        this.startDate = startDate;
        this.endDate = endDate;
    }

    getValidDates(pattern) {
        const startDate = moment(this.startDate).isValid() ? this.startDate : moment();
        const endDate = moment(this.endDate).isValid() ? this.endDate : moment(startDate).startOf('d').add(1, 'd');

        return {
            startDate: formatDate(startDate, pattern),
            endDate: formatDate(endDate, pattern)
        };
    }

    validate() {
        return this.isLenghtOfStayValid();
    }

    isLenghtOfStayValid() {
        return ((this.endDate - this.startDate) <= constants.lengthOfStay);
    }

    getValidationError() {
        if (!this.validate()) {
            return new InvalidDateRangeSelectionCriteriaException;
        }

        return null;
    }
}
