import request from "@/redux/services/request";

import { config } from '@/utils/config'


export const getListings = endpointUrl => {
    return request({
        url: `/${config.api_ver}${endpointUrl}`,
        method: 'GET'
    })
}