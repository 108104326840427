import { useState } from 'react';
import { OmnisearchStateFactory } from './OmnisearchStateFactory';

export default function useOmnisearch() {
    const step = useState(/*StepEnum.INITIAL*/);

    const destination = useState(OmnisearchStateFactory.Destination);
    const dates = useState(OmnisearchStateFactory.DateRange);
    const guests = useState(OmnisearchStateFactory.Guest);
    const interests = useState(OmnisearchStateFactory.Interests);

    const reset = () => {
        for (const [[, setState], factory] of [
            [destination, OmnisearchStateFactory.Destination],
            [dates, OmnisearchStateFactory.DateRange],
            [guests, OmnisearchStateFactory.Guest],
            [interests, OmnisearchStateFactory.Interests]
        ]) {
            setState(factory);
        }
    };

    return {
        step,
        destination,
        dates,
        guests,
        interests,
        reset
    };
};
