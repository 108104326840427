import { getWeekend } from '@/utils/globalFunc';
import moment from 'moment';
import { useCallback } from "react";

export default function useDates(asHook = true) {
    const fn = ({ checkin, checkout, isVR }) => {
        const defaultDaterange = getWeekend(1);

        let startDate = moment(checkin).startOf('day');
        let endDate = moment(checkout).startOf('day');
        const isDatesValid = (startDate.isValid() && endDate.isValid());

        // if (isDatesValid && !startDate.isBefore(endDate)) {
        //     [startDate, endDate] = [endDate, startDate];
        // }
        if (isDatesValid) {
            if (!startDate.isBefore(endDate)) {
                [startDate, endDate] = [endDate, startDate];
            }

            if (startDate.isBefore(moment().startOf('day'))) {
                startDate = defaultDaterange[0];
            }

            if (endDate.isBefore(startDate)) {
                endDate = moment(startDate).add(1, 'day');
            }

            if (startDate.isSame(endDate, 'day')) {
                endDate = moment(endDate).add(1, 'day');
            }
        }

        if (!isVR) {
            // Hotels dates validation
            if (!startDate.isValid()) {
                startDate = defaultDaterange[0];
            }

            if (!endDate.isValid()) {
                endDate = moment(startDate).add(1, 'day');
            }

            if (endDate.isBefore(startDate)) {
                startDate = defaultDaterange[0];
                endDate = moment(startDate).add(1, 'day');
            }
        } else if (!isDatesValid) {
            // Homes dates validation
            startDate = moment(null);
            endDate = moment(null);
        }
        return {
            startDate: startDate.isValid() ? startDate : null,
            endDate: endDate.isValid() ? endDate : null
        };
    };
    return asHook ? useCallback(fn, []) : fn;
};
