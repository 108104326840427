import { getCurrentLoc } from "@/redux/services/global";
import { isServer } from "@/providers/Environment";

// 8====D Indian fuckers ╭ᑎ╮ 🖕 fuck you bitches, motherfucking cunts

export const getCurrentPosition = async () => {
    let coords = null;

    try {
        if (checkInvariant()) {
            throw new Error('Looks like you are not allowed to use geolocation API. No worries just fuck it!');
        }

        ({ coords } = await new Promise((resolve, reject) =>
            window.navigator
                .geolocation
                .getCurrentPosition(
                    resolve,
                    reject
                )));
    } catch {
    } finally {
        let queryParams = '';

        if (coords) {
            const { latitude, longitude } = coords;

            const searchParams = new URLSearchParams();
            searchParams.set('lat', latitude);
            searchParams.set('lon', longitude);

            queryParams = `?${searchParams.toString()}`;
        }

        return getCurrentLoc(queryParams);
    }
};

export const currentPositionPromise = getCurrentPosition();

function checkInvariant() {
    return isServer() || !window.navigator.geolocation;
}