import CurrencyProgressive from "@/components/common/icons/CurrencyProgressive";
import InboxProgressive from "@/components/common/icons/InboxProgressive";

import FilledAccount from "@/components/common/icons/FilledAccount";
import ListYourPropertyProgressive from "@/components/common/icons/ListYourPropertyProgressive";
import NotificationProgressive from "@/components/common/icons/NotificationProgressive";
import FaqsAndHelpProgressive from "@/components/common/icons/FaqsAndHelpProgressive";
import TermsAndConditionsProgressive from "@/components/common/icons/TermsAndConditionsProgressive";
import ContactUsProgressive from "@/components/common/icons/ContactUsProgressive";
import PrivacyPolicyProgressive from "@/components/common/icons/PrivacyPolicyProgressive";
import LogoutProgressive from "@/components/common/icons/LogoutProgressive";
import { logoutAction } from "@/redux/actions/authActions";
import ManageProperties from "@/components/common/icons/ManageProperties";
import MyBookingsProgressive from "@/components/common/icons/MyBookingsProgressive";
import WishlistProgressive from "@/components/common/icons/WishlistProgressive";

import PaymentMethodsProgressive from "@/components/common/icons/PaymentMethodsProgressive";
import PrivacyAndSecurityProgressive from "@/components/common/icons/PrivacyAndSecurityProgressive";
import ReviewsDashboardIcon from "@/components/common/icons/ReviewsDashboardIcon";
import HouseFilled from "@/components/common/icons/HouseFilled";
import CuddlyNestIcon from "@/components/common/icons/CuddlyNestIcon";
import HostMyListing from "@/components/common/icons/HostMyListing";
import HostBookingList from "@/components/common/icons/HostBookingList";


export const DEFAULT_ROUTE = "guest"

export const DASHBOARD_ROUTES = {
    guest: [
        {
            id: 'account',
            icon: <FilledAccount />,
            children: 'My account',
            href: '/d/guest/account',
            active: true,
            extra: {}
        },
        {
            id: 'messages',
            icon: <InboxProgressive />,
            children: 'Messages',
            href: '/d/guest/messages',
            active: false,
            extra: {}
        },
        {
            id: 'notifications',
            icon: <NotificationProgressive />,
            children: "Notifications",
            active: false,
            href: "/d/guest/notifications",
            extra: {}
        },
        {
            id: 'currency',
            icon: <CurrencyProgressive />,
            children: "Currency",
            href: "/d/guest/currency",
            extra: {},
            active: false
        },
        {
            id: 'homepage',
            icon: <CuddlyNestIcon />,
            children: 'Return to homepage',
            href: '/',
            active: false,
            extra: {}
        },
    ],


    host: [
        {
            id: 'account',
            icon: <FilledAccount />,
            children: 'My account',
            href: '/d/host/account',
            active: true,
            extra: {}
        },
        {
            id: 'inbox',
            icon: <InboxProgressive />,
            children: 'Messages',
            href: '/d/host/messages',
            active: false,
            extra: {}
        },
        {
            id: 'hostNotifications',
            icon: <NotificationProgressive />,
            children: 'Notifications',
            href: '/d/host/notifications',
            active: false,
            extra: {}
        },
        {
            id: 'currency',
            icon: <CurrencyProgressive />,
            children: 'Currency',
            href: '/d/host/currency',
            active: false,
            extra: {}
        },
        {
            id: 'homepage',
            icon: <CuddlyNestIcon />,
            children: 'Return to homepage',
            href: '/',
            active: false,
            extra: {}
        },
    ],

    footerRoutes: [
        {
            id: 'faqsAndHelp',
            icon: <FaqsAndHelpProgressive />,
            children: 'FAQ & help center',
            href: '/help',
            active: false,
            extra: {}
        },
        {
            id: 'contactUs',
            icon: <ContactUsProgressive />,
            children: 'Contact us',
            href: '/contact-us',
            active: false,
            extra: {}
        },
        {
            id: 'privacyPolicy',
            icon: <PrivacyPolicyProgressive />,
            children: "Privacy policy",
            href: "/privacy-policy",
            active: false,
            extra: {}
        },
        {
            id: 'termsAndCondition',
            icon: <TermsAndConditionsProgressive />,
            children: "Terms and conditions",
            active: false,
            href: "/terms-and-conditions",
            extra: {}
        },
        {
            id: 'logout',
            icon: <LogoutProgressive />,
            children: "Log out",
            extra: {
                onLogout: (dispatch) => {
                    dispatch && dispatch(logoutAction());
                }
            },
            active: false
        }
    ]
};


const NAV_TABS = [
    {
        type:'guest',
        active: true,
        routes: [
            {
                id:"myBooking",
                icon: <MyBookingsProgressive />,
                children: 'My bookings',
                href: '/d/guest/my-bookings',
                extra: {},
                active: false
            },
            {
                id:"wishlist",
                icon: <WishlistProgressive /> ,
                children: 'List',
                href: '/d/guest/lists',
                extra: {} ,
                active: false
            },
            {
                id:"paymentMethod",
                icon: <PaymentMethodsProgressive />,
                children: 'Payment methods',
                href: '/d/guest/payment',
                extra: {},
                active: false
            },
            {
                id:"privacySecurity",
                icon: <PrivacyAndSecurityProgressive />,
                children: 'Privacy & security',
                href: '/d/guest/privacy-security',
                extra: {},
                active: false
            },
            {
                id:"reviews",
                icon: <ReviewsDashboardIcon />,
                children: 'My reviews',
                href: '/d/guest/reviews',
                extra: {},
                active: false
            }
        ]
    },

    {
        type:'host',
        active: true,
        routes: [
            {
                id:"listing",
                icon: <HostMyListing />,
                children: 'My listings',
                href: '/d/host/listing',
                extra: {},
                active: false
            },
            {
                id:"bookingRequest",
                icon: <HostBookingList /> ,
                children: 'Booking Requests',
                href: '/d/host/booking-request',
                extra: {} ,
                active: false
            },
            // Suck my dick Naeem, you cheated us told it is imlemented, but it is not!!!!
            // {
            //     id:"earning",
            //     icon: <CurrencyProgressive /> ,
            //     children: 'Earnings',
            //     href: '/d/host/earnings',
            //     extra: {} ,
            //     active: false
            // },
            {
                id:"reviews",
                icon: <ReviewsDashboardIcon /> ,
                children: 'Reviews',
                href: '/d/host/reviews',
                extra: {} ,
                active: false
            }
        ]
    },
]


export const setSwitcherActions = (value=DEFAULT_ROUTE) => {
    return dispatch => {
        return dispatch({ type: 'DASHBOARD_SWITCHER', data: value });
    }
}



export const allDashboardRoutes = () => {
    return async (dispatch, getState) => {
        const {dashboardSwitcher} = getState().dashboardRoutesReducer;
        const {pathname} = window.location;

        let selectedRoute = {}
        let getRoutes = {};
        getRoutes = resetDashboardRoutes(dashboardSwitcher)
        Object.keys(getRoutes).map(key=>{
            const getObject = getRoutes[key];
            getObject.map(route=>{
                route.active = route.id !== "homepage" && (route.href === `/d/${dashboardSwitcher}` ? route.href === pathname : pathname.includes(route.href));
                if(route.active) {
                    selectedRoute = {
                        route,
                        goBack: !(route.id === 'account') // not main page
                    }
                }

                return route;
            })
            getRoutes[key] = getRoutes[key] || {};
            getRoutes[key] = getObject;
        });

        let navRoutes = resetNavTabs(dashboardSwitcher);

        navRoutes.map(route=>{
            route.active = pathname.includes(route.href);
            if(route.active) {
                selectedRoute = {
                    route,
                    goBack: true
                }
            }
            return route;
        });

        dispatch({ type: 'DASHBOARD_ROUTES', data: {sideNav: getRoutes, navTabs: {...NAV_TABS.find(nav=>nav.active&&nav.type===dashboardSwitcher), routes: navRoutes}, selectedRoute} });
    }
}

const resetNavTabs = (dashboardSwitcher) => {
    const navRoutes = NAV_TABS.find(nav=>nav.active&&nav.type===dashboardSwitcher)?.routes || [];

    navRoutes.map(v=>{
        v.active = false;
        return v;
    })
    return navRoutes;
}

const resetDashboardRoutes = (mainKey) =>{
    const getRoutes = {}
    Object.keys(DASHBOARD_ROUTES).map(key=>{
        if([mainKey, 'footerRoutes'].indexOf(key)>=0) {
            const getObject = DASHBOARD_ROUTES[key];
            getObject.map(route => {
                route.active = false;
                return route;
            })
            getRoutes[key] = getRoutes[key] || {};
            getRoutes[key] = getObject;
        }
    });

    const temp = {generalRoutes: getRoutes[mainKey], footerRoutes: getRoutes.footerRoutes};
    delete temp[mainKey];

    return temp
}

export const onClickTabNavRoute = (id) => {
    return async (dispatch, getState) => {}
}

export const onClickRoute = (id) => {
    return async dispatch => {}
}