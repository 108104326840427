import { initOnboard } from "@/redux/actions/onboardingActions";
import { createContext, useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { messages } from "./OnboardingConfig";
import OnboardingTooltipMui from "./OnboardingTooltipMui";


export const OnboardingContext = createContext({});

export const OnboardingInitializer = ({ children }) => {
    const dispatch = useDispatch();
    const [popper, setPopper] = useState(null);
    const popperRef = useCallback(node => setPopper(node), []);

    useEffect(() => {
        let shownMsgNames = localStorage.getItem('onboarding');
        let messagesToShow;

        if (shownMsgNames) {
            shownMsgNames = JSON.parse(shownMsgNames);
        }

        // uncomment for development
        // if (true) {
        if (!shownMsgNames || !Array.isArray(shownMsgNames) || !shownMsgNames.length) {
            localStorage.removeItem('onboarding');

            messagesToShow = messages.map(item => ({
                ...item,
                visible: false,
                container: null,
            }));
        } else {
            const allMsgNames = messages.map(item => item.name);
            shownMsgNames = shownMsgNames.filter(item => allMsgNames.includes(item));
            const msgNamesToShow = allMsgNames.filter(item => !shownMsgNames.includes(item));

            localStorage.setItem('onboarding', JSON.stringify(shownMsgNames));

            messagesToShow = messages
                .filter(item => msgNamesToShow.includes(item.name))
                .map(item => ({ ...item, visible: false, container: null }));
        }

        dispatch(initOnboard(messagesToShow));
    }, []);

    return (
        <>
            <OnboardingContext.Provider
                value={{
                    popperInstance: popper,
                }}
            >
                {children}
            </OnboardingContext.Provider>
            <OnboardingTooltipMui ref={popperRef} />
        </>
    );
};
