import { DestinationTypeEnum } from "@/static/contants";
import { useState } from "react";
import { ActivityErrorTypes } from "../../enum";
import ActivityInvariantException from "../../exceptions/ActivityInvariantException";
import { useOmnisearchState } from "../OmnisearchState";

export default function useValidationInvariants() {
    const { interests } = useOmnisearchState();

    const [activityValidationError, setActivityValidationError] = useState(null);

    const [selectedInterests] = interests;

    // Selectors
    const selectIntersected = (data = []) => {
        const intersected = [];

        for (const interestModel of data) {
            for (const selectedInterest of selectedInterests) {
                if (Object.is(
                    interestModel.display_name,
                    selectedInterest.displayName
                )) {
                    intersected.push(selectedInterest);
                }
            }
        }

        return intersected;
    };

    // Predicates
    const isResponseEmpty = (data = []) => data.length <= 0;
    const getSelectedCount = () => selectedInterests.length;
    const isSelectionAvailable = () => getSelectedCount() > 0;
    const isResponseDifferFromSelection = (data = []) =>
        selectIntersected(data).length !== getSelectedCount();

    const isLocationInvalid = ([city, state, country]) => ![city, state, country].some(Boolean);
    const isLocationTypeValid = type => [
        DestinationTypeEnum.CITY,
        DestinationTypeEnum.STATE,
        DestinationTypeEnum.COUNTRY
    ].includes(type);

    const invariants = new Map()
        // If the new city entered does not have activities we will
        // show a pop up letting the user know that the activities
        // selected are not available in the city.
        .set(ActivityErrorTypes.ActivitesNotAvailable, (activityResponseModel = [], destinationModel) => {
            if (isResponseEmpty(activityResponseModel) &&
                isLocationTypeValid(destinationModel.type) &&
                isSelectionAvailable()) {
                throw new ActivityInvariantException(
                    'Activities is not available',
                    {
                        errorType: ActivityErrorTypes.ActivitesNotAvailable,
                        selectedCount: getSelectedCount()
                    }
                );
            }
        })
        //  If the new city entered does not have the activities the
        // user has selected we will show a pop up asking to review the
        // activities for the new location entered.
        .set(ActivityErrorTypes.ActivitiesOutsideSelectedRange, (activityResponseModel = []) => {
            if (!isResponseEmpty(activityResponseModel) &&
                isResponseDifferFromSelection(activityResponseModel)) {
                throw new ActivityInvariantException(
                    'Activities does not match selected',
                    {
                        errorType: ActivityErrorTypes.ActivitiesOutsideSelectedRange,
                        selectedCount: getSelectedCount()
                    }
                );
            }
        });

    const validateInvariants = (activityResponseModel, destinationModel) => {
        if (!isSelectionAvailable()) {
            return true;
        }

        invariants.forEach(invariantFn => invariantFn(activityResponseModel, destinationModel));

        return true;
    };

    return {
        validateInvariants,
        isResponseEmpty,
        isLocationInvalid,
        selectIntersected,
        activityValidationError,
        setActivityValidationError
    };
};
