import { createContext, memo } from "react";
import useAttractionsNearby from "./useAttractionsNearby";

export const AttractionsNearbyContext = createContext({
    attractionsState: [],
    fetchAtractionNearby: async () => {}
});

const AttractionsNearby = ({ children }) => {
    const { attractionsState, fetchAtractionNearby } = useAttractionsNearby();

    return (
        <AttractionsNearbyContext.Provider
            value={{
                attractionsState,
                fetchAtractionNearby
            }}
        >
            {children}
        </AttractionsNearbyContext.Provider>
    );
};

export default memo(AttractionsNearby);
