import { constants } from '@/src/utils/constants'
import { DestinationTypeEnum, defaultSourceImageProps } from '@/static/contants';
import { AmenityIconSets } from '@/static/contants';
import {error_body} from "@/utils/dynamicClasses"
import moment from 'moment';

import snakeCase from 'lodash.snakecase';
import mapKeys from 'lodash.mapkeys';
import map from 'lodash.mapkeys';
import camelCase from 'lodash.camelcase';

export const convertJsonObjectToFormData = (obj)=>{
      const formData = new FormData();
      for ( let key in obj ) {
          formData.append(key, obj[key]);
      }
      return formData;
  }

export const toSnakeCase = (obj) => {
return mapKeys(obj, (_value, key) => {
  return snakeCase(key);
});
}

export const toCamelCase = (obj) => {
return mapKeys(obj, (_value, key) => {
  return camelCase(key);
})
}

export const serialize = (obj) =>  {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}


export const arrayObjectsToCamelCase = (arr) => {
return map(arr, (obj) => {
  return mapKeys(obj, (_value, key) => {
    return camelCase(key);
  });
});
}
export const arrayObjectsToSnakeCase = (arr) => {
return map(arr, (obj) => {
  return mapKeys(obj, (_value, key) => {
    return snakeCase(key);
  });
});
}

export const entityToChar = str => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
};

// Truncate a string at a given length in UTF-8 encoded bytes.
// https://gist.github.com/panzi/f338e83e128736b5aaac2b5a0816f530
export const truncateUtf8Bytes = (string, byteLength) => {
  const charLength = string.length;
  let curByteLength = 0;

  for (let i = 0; i < charLength; ++ i) {
      const start = i;
      const w1 = string.charCodeAt(i);
      let cp = w1;

      if ((w1 & 0xfc00) === 0xd800) {
          const w2 = string.charCodeAt(i + 1);
          if ((w2 & 0xfc00) === 0xdc00) {
              const hi = w1 & 0x3ff;
              const lo = w2 & 0x3ff;
              cp = (hi << 10) | lo | 0x10000;
              ++ i;
          }
      }

      curByteLength += (
          cp >= 0x10000 ? 4 :
          cp >= 0x800   ? 3 :
          cp >= 0x80    ? 2 :
                          1);

      if (curByteLength === byteLength) {
          return string.slice(0, i + 1);
      }
      else if (curByteLength > byteLength) {
          return string.slice(0, start + 1);
      }
  }

  return string;
};

// https://stackoverflow.com/questions/25994001/how-to-calculate-byte-length-containing-utf8-characters-using-javascript
export const countUtf8Bytes = s => {
  let b = 0, i = 0, c;
  for(;c=s.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
  return b;
};

export const logger = {
    error: (message, value) => console.error(`${message} ==> `, value),
    log: (message, value) => console.log(`${message} ==> `, value),
    warn: (message, value) => console.warn(`${message} ==> `, value)
}

export const _debounce = (a, b) => {
    var d
    const func = () => {
      var f = this,
        g = arguments
      clearTimeout(d)
      // d=null
      d = setTimeout(a.apply(f, g), b)
    }
    return func()
}
// CONVERTS Lahore--Punjab--Pakistan to Lahore, Punjab, Pakistan
export const format_sr_location_reverse = (location) => {
  if(!location) return location;
	location = decodeURI(location);

	location = replaceAll(location, "--",", ")
	location = replaceAll(location, "-", " ")
	location = replaceAll(location, "~", "-")

	return location;
}

export const escapeRegExp = (str) => {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

export const replaceAll = (str, find, replace) => {

  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export const add_remove_class_to_body = (className="",shouldRemove=false, selector=false) => {

  if(!className) return;


  if(selector && shouldRemove)
  {
    // in this case we add the passed class to the passed selector
    document.querySelector(selector)?.classList.remove(className)
    return;
  }
  else if(selector && !shouldRemove)
  {
    // in this case we remove the passed class from the passed selector
    document.querySelector(selector)?.classList.add(className)
    return;
  }

  if(shouldRemove)
  {
    // it means we should try to remove the class name passed in className variable

    document.body.classList.remove(className);

  }
  else
  {
    // here we add the class to body
    document.body.classList.add(className);
  }

}

export const convert_obj_to_query_string = (obj) => {
  if(!isObject(obj)) return "";

  let query_string = []
  if(obj && Object.keys(obj).length>0 )
  {
    Object.entries(obj).forEach(([key, val])=>{
      if(val)
      query_string.push(`${key}=${val}`)
    })
  }

  return query_string.length>0 && `?${query_string.join('&')}`

}

export const isObject = (obj) => {

  if(typeof obj==='object' && typeof obj !=='function' && obj !=null) return true;
  return false;
}

export const isValidJson = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

 export const  add_remove_error_class_to_error_pages_body = (router) => {

  if(router.asPath.startsWith(constants.error_pages_common_start_path))
  {
    add_remove_class_to_body(error_body)
  }
  else
  {
    add_remove_class_to_body(error_body,true)
  }

 }

 // made to avoid showing undefined or show another value passed in second parameter
export const _u = (val, showThisIfNotTrue="") => {
  if(!!val)
  return val;
  return showThisIfNotTrue;
}

export const ObjectShallowEqualityCheck = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

/**
 * You could use this method to remove line breaks from any string, like of kind \r\n or \n or \r
 * @param {string} string
 */
export const removeAllLineBreaks = (string) => {
  return string.replace(/(\r\n|\n|\r)/gm, "");
}

export const convertToNum = (value, defaultValue) => {
  const num = Number.parseInt(value);
  return Number.isNaN(num) ? defaultValue : num;
};

export const normalizeGeoCode = data => {
  const arr = data.map(item => {
    item = {
      id: item._id.replace(/\D/g,''),
      name: item._source.name,
      address: item._source.type !== 'Country' ? `${item._source.city ? `${item._source.city}, ` : ''}${item._source.state ? `${item._source.state}, ` : ''}${item._source.country ? item._source.country : ''}` : '',
      type: item._source.type,
      // slug: item._source['seo-slug'] ? item._source['seo-slug'].replace(" ", '-') : ''
      slug: item._source.slug || '',
      imageUrl: item._source.image_url,
      distance: item._source.distance,
      lat: item._source.lat,
      long: item._source.long,
      state: item._source.state,
      geoSeoTag: item._source.geo_seo_tag,
      totalInventory: item._source.total_inventory,
      location: item._source.location,
      viewportBounds: item._source.viewport_bounds,
      grs: item._source?.grs_new,
      starRating: item._source?.star_rating,
      grs_adjective: item._source?.grs_adjective_new,
      zoomLevel: item._source.zoom_in,
      mobileZoomLevel: item._source.mob_zoom_in,
      propertyCount: item._source?.property_count || 0,
      property_count: item._source?.property_count && formatNumber(item._source?.property_count)
    }
    return item;
  })
  return arr;
}

export const transformCountryNearbyResponse = (nearbyData = []) =>
  nearbyData.map(item => ({
    id: item.name,
    name: item.name,
    address: [item.state, item.country]
      .filter(Boolean)
      .join(', '),
    slug: '',
    imageUrl: item.image_url,
    lat: item.lat,
    long: item.long,
    state: item.state,
    geoSeoTag: item.geo_seo_tag,
    totalInventory: item.total_inventory,
    zoomLevel: item.zoom_in,
    mobileZoomLevel: item.mob_zoom_in,
    viewportBounds: item?.viewport_bounds
  }));

export const normalizeUrl = (url, stripDomain = false) => {
  if (!url || (!stripDomain && url.startsWith('http'))) {
    return url;
  }

  if (url.startsWith('http')) {
    return url.replace(/^.*\/\/[^\/]+/, '');
  }

  if (url.startsWith('/')) {
    return url;
  }

  return `/${url}`;
};

export const getWeekend = (
  numberOfWeekends = 0,
  date = moment()
) => {
  const currentWeekDay = date.isoWeekday();
  const daysMap = new Map([
    [1, 5],
    [2, 4],
    [3, 3],
    [4, 2],
    [5, 1],
    [6, 0],
    [7, 0]
  ]);

  let firstWeekendDate = date.add(daysMap.get(currentWeekDay), 'd');
  if ((numberOfWeekends > 0) && (currentWeekDay === 7)) {
    firstWeekendDate = date.subtract(1, 'd');
  }

  const firstWeekendDay = firstWeekendDate.add(numberOfWeekends, 'w');

  return [
    moment(firstWeekendDay),
    moment(firstWeekendDay).add(1, 'd')
  ].map(m => m.startOf('d'));
};

//https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export const shuffleArray = array => {
  let currentIndex = array.length, randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const randomFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const keyID = (tokens = [], separator = '_') => tokens.join(separator);

export const getEntity = entity => entity.name ?? entity;

export const isIncludes = (value, data = []) =>
    data.findIndex(entity => getEntity(entity) === value) !== -1;

export const concatUnique = (array1 = [], array2 = [], ...args) => {
  const collectionRaw = array1.reduce((acc, entity) => [...acc, getEntity(entity)], []);

  return [
    ...new Set([
      ...collectionRaw,
      ...array2,
      ...args.flat()
    ])
  ].map(name => {
    const entity = array1.find(entity => name === getEntity(entity)) || {};

    return {
      ...entity,
      name: entity.name || name,
    };
  });
};

/**
* @tayyab
*  method to get resized image url with size
* temporary if condition to check if image url already contains img.cuddlynest then replace it with tresize.cuddlynest
* returns a string
*/
export const getResizedImgUrl = (img, size) => {
  let imgUrl = img;

  // TODO: temporary fix until BE will move all images into same domain
  // Authored by Tayaab
  if (imgUrl.lastIndexOf('img.cuddlynest') > 0) {
    if (size) {
      let indexOfLastSlash = imgUrl.lastIndexOf('/');
      imgUrl = `${imgUrl.slice(0, indexOfLastSlash)}/t/webp/${imgUrl.slice(indexOfLastSlash+1)}`;

      if (imgUrl.lastIndexOf('.jpg') > 0) {
        imgUrl = imgUrl.replace('.jpg', `_${size}.jpg`);
      }

      if (imgUrl.lastIndexOf('.jpeg') > 0) {
        imgUrl = imgUrl.replace('.jpeg', `_${size}.jpeg`);
      }

      if(imgUrl.lastIndexOf('.png') > 0){
        imgUrl = imgUrl.replace('.png', `_${size}.png`);
      }
    }
  }

  return imgUrl;
};

export const updateImgUrl = (url, size) => {
  const path = url.split('/');
  const img = path.pop().replace('.', `_${size}.`);
  path.push(img);
  return path.join('/');
};

export const enhanceImageSource = (arrayOfSources, defaultImageProps = defaultSourceImageProps) =>
  arrayOfSources.map(source => ({ ...defaultImageProps, ...source }));

export const getAmenityIconUrl = (
  amenityIconName = '',
  amenityiconSet = AmenityIconSets.BLACK
) => `${constants.amenityIconBasePath}${amenityiconSet}/${amenityIconName}`;

export const formatDigit = (num, { locales = 'en-US', options = { maximumFractionDigits: 2 } } = {}) =>
  num.toLocaleString(locales, options);

export const roundToFixed = ({ value, precision, isConvertToNum = false }) => {
  const roundedValue = Number.parseFloat(value).toFixed(precision);
  if (isConvertToNum) {
    return +roundedValue;
  }
  if (precision === 1) {
    return roundedValue.replace(/\.0$/, '');
  }
  return roundedValue;
};

export const cloneObject = objectSupposedToBeCloned => JSON.parse(JSON.stringify(objectSupposedToBeCloned));

export const extractDigitFromString = str => str.match(/\d+/g);

export const getImageInfo = (imgurl,sizes) => {
  let obj = {
      src: getResizedImgUrl(imgurl, false),
      sources: [
          {
              media: '(max-width: 600px)',
              srcset: `${getResizedImgUrl(imgurl, sizes.mobile[0])} 1x, ${getResizedImgUrl(imgurl, sizes.mobile[1])} 2x`,
              type: 'image/webp'
          },
          {
              media: '(min-width: 601px) and (max-width:1135px)',
              srcset: `${getResizedImgUrl(imgurl, sizes.tablet[0])} 1x, ${getResizedImgUrl(imgurl, sizes.tablet[1])} 2x`,
              type: 'image/webp'
          },
          {
              media: '(min-width: 1136px)',
              srcset: `${getResizedImgUrl(imgurl, sizes.desktop[0])} 1x, ${getResizedImgUrl(imgurl, sizes.desktop[1])} 2x`,
              type: 'image/webp'
          }
      ]
  }
  return obj;
};

export const concatFilter = (subject = '', splitToken = ',', joinToken = ', ') => {
  const formattedSubject = Array.isArray(subject) ? subject : subject.split(splitToken);

  return formattedSubject
    .filter(t => Boolean(t?.trim()))
    .join(joinToken);
};

export const range = (min, max) =>
  Array.from({ length: max - min + 1 }, (_, i) => min + i);

export const isEnvList = environmentList =>
  environmentList.some(env => (typeof location !== 'undefined') && location.host.startsWith(env));

export const getValidated = ({
  inputValue = {},
  isInvalid = value => Number.isNaN(Number.parseFloat(value))
}) => Object.entries(inputValue)
    .reduce((acc, [key, value]) =>
      isInvalid(value)
        ? acc
        : { ...acc, [key]: value }, {});


export const formatNumber = (number, options = ['en-US', {}]) =>
  (new Intl.NumberFormat(...options)).format(number);

export const formatDate = (date, format = 'YYYY-MM-DD') => date?.format(format);

export const fuckOSValidator = () => {
  const result = {
    isFuckOS: false,
    isIos: false,
    isSafari: false
  };

  if (typeof window === 'undefined') {
    return result;
  }

  const { userAgent } = window.navigator;

  const isIos = /iPad|iPhone|iPod/.test(userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  return {
    ...result,
    isFuckOS: isIos || isSafari,
    isIos,
    isSafari
  };
};

export const compareIds = (id1, id2) => (
  String(id1).toLocaleLowerCase() === String(id2).toLocaleLowerCase()
);

export const compareStrings = (string1, string2, {
  withTrim = true,
  comparatorStrategy
} = {}) => {
  const [stringA, stringB] = [
    string1,
    string2
  ]
  .map(value => {
    const stringValue = String(value || '');

    if (withTrim) {
      return stringValue.trim();
    }

    return stringValue;
  });

  switch (true) {
    case (typeof comparatorStrategy === 'function'): {
      return comparatorStrategy(stringA, stringB);
    }

    case (typeof stringA[comparatorStrategy] === 'function'): {
      return stringA[comparatorStrategy](stringB);
    }

    default: {
      return compareIds(stringA, stringB);
    }
  }
};

export const shouldFuckUsmanAssThroughSearchApi = data =>
  [
    DestinationTypeEnum.COUNTRY,
    // DestinationTypeEnum.STATE
  ].includes(data.location_type)

export const isElemInViewport = (elem,
  { top = 0, left = 0, bottom = 0, right = 0} = {}) => {
  if (!(elem instanceof HTMLElement)) {
    return false;
  }

  const rect = elem.getBoundingClientRect();
  return (
      rect.top >= (0 + top) &&
      rect.left >= (0 + left) &&
      rect.bottom <= ((window.innerHeight || document.documentElement.clientHeight) + bottom) &&
      rect.right <= ((window.innerWidth || document.documentElement.clientWidth) + right)
  );
};
