// Profile

export const DASHBOARD_USER_PROFILE = 'DASHBOARD_USER_PROFILE';
export const DASHBOARD_USER_UNREADCOUNTS = 'DASHBOARD_USER_UNREADCOUNTS';
export const DASHBOARD_USER_UNREADCOUNTS_TOZERO = 'DASHBOARD_USER_UNREADCOUNTS_TOZERO';

export const DASHBOARD_UPDATE_PROFILE_PICTURE = 'DASHBOARD_UPDATE_PROFILE_PICTURE';
export const DASHBOARD_UPDATE_PROFILE_PHONE = 'DASHBOARD_UPDATE_PROFILE_PHONE';
export const DASHBOARD_UPDATE_PROFILE = 'DASHBOARD_UPDATE_PROFILE';
export const DASHBOARD_ADD_PROFILE_PHONE = 'DASHBOARD_ADD_PROFILE_PHONE';

export const DASHBOARD_GET_USER_UPCOMING_BOOKINGS = 'DASHBOARD_GET_USER_UPCOMING_BOOKINGS';
export const DASHBOARD_GET_USER_PAST_BOOKINGS = 'DASHBOARD_GET_USER_PAST_BOOKINGS';

export const  DASHBOARD_RESET = 'DASHBOARD_RESET';
export const DASHBOARD_GET_USER_WISHLISTS = 'DASHBOARD_GET_USER_WISHLISTS';
export const DASHBOARD_UPDATE_USER_WISHLISTS = 'DASHBOARD_UPDATE_USER_WISHLISTS';
export const DASHBOARD_DELETE_USER_WISHLISTS = 'DASHBOARD_DELETE_USER_WISHLISTS';
export const DASHBOARD_DELETE_USER_WISHLIST_LISTING = 'DASHBOARD_DELETE_USER_WISHLIST_LISTING';
export const DASHBOARD_UNDO_DELETE_USER_WISHLISTS = 'DASHBOARD_UNDO_DELETE_USER_WISHLISTS';
export const DASHBOARD_PROFILE_VERIFICATION = 'DASHBOARD_PROFILE_VERIFICATION';
export const DASHBOARD_PROFILE_VERIFICATION_SUBMIT = 'DASHBOARD_PROFILE_VERIFICATION_SUBMIT'

export const DASHBOARD_GET_ALL_MESSAGES = "DASHBOARD_GET_ALL_MESSAGES";
export const DASHBOARD_GET_CHAT_THREADS = "DASHBOARD_GET_CHAT_THREADS"; 


export const DASHBOARD_GET_ALL_REVIEWS = "DASHBOARD_GET_ALL_REVIEWS"; 
export const DASHBOARD_SUBMIT_REVIEWS = "DASHBOARD_SUBMIT_REVIEWS";

export const DASHBOARD_GET_REVIEWS = "DASHBOARD_GET_REVIEWS"; 