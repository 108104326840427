import { dashboardRoutesReducer } from './dashboardRoutesReducer';
import { dashboardProfileReducer } from './dashboardProfileReducer';
import {dashboardMessageReducer} from './dashboardMessageReducer'

const dashboardReducers = {
    dashboardRoutesReducer,
    dashboardProfileReducer,
    dashboardMessageReducer
};

export default dashboardReducers;