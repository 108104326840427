import { createContext } from "react";
import useAuthentication from "./useAuthentication";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { config } from '@/utils/config';

export const AuthenticationContext = createContext();

const AuthenticationProvider = ({ children }) => (
    <AuthenticationContext.Provider
        value={useAuthentication()}
    >
        <GoogleOAuthProvider
            clientId={config.google_client_id}
        >
            {children}
        </GoogleOAuthProvider>
    </AuthenticationContext.Provider>
);

export default AuthenticationProvider;
