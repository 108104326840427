import { getEntityInitialState, entityStoreReducersMixin } from "@/utils/entityRequestAdapter";
import createReducer from "@/utils/createReducer";
import {
    DASHBOARD_ADD_PROFILE_PHONE,
    DASHBOARD_RESET,
    DASHBOARD_UPDATE_PROFILE,
    DASHBOARD_UPDATE_PROFILE_PHONE,
    DASHBOARD_UPDATE_PROFILE_PICTURE,
    DASHBOARD_USER_PROFILE,
    DASHBOARD_GET_USER_UPCOMING_BOOKINGS,
    DASHBOARD_GET_USER_PAST_BOOKINGS,
    DASHBOARD_DELETE_USER_WISHLISTS,
    DASHBOARD_DELETE_USER_WISHLIST_LISTING,
    DASHBOARD_GET_USER_WISHLISTS,
    DASHBOARD_UNDO_DELETE_USER_WISHLISTS,
    DASHBOARD_UPDATE_USER_WISHLISTS,
    DASHBOARD_GET_NOTIFICATION_BY_OBJECT,
    DASHBOARD_GET_NOTIFICATION_LISTING,
    DASHBOARD_GET_USER_WATCHLIST,
    DASHBOARD_UPDATE_WATCHLIST_PROPERTIES,
    DASHBOARD_REMOVE_PROPERTY_FROM_WATCHLIST,
    DASHBOARD_MARK_NOTIFICATION_AS_READ,
    DASHBOARD_MARK_ALL_NOTIFICATIONS_AS_READ,
    DASHBOARD_GET_NOTIFICATION_SETTINGS,
    DASHBOARD_UPDATE_NOTIFICATION_SETTINGS,
    DASHBOARD_GET_USER_CURRENT_BOOKINGS,
    DASHBOARD_GET_USER_CANCELLED_BOOKINGS,
    DASHBOARD_GET_BOOKING_DETAILS,
    DASHBOARD_CANCEL_BOOKING,
    DASHBOARD_GET_REFUND_INFO,
    DASHBOARD_PAYMENT_DELETE_CARD,
    DASHBOARD_PAYMENT_SET_DEFAULT_CARD,
    DASHBOARD_UPDATE_PASSWORD
} from "../types/dashboard";
import { useSelector } from "react-redux";

export const STORE_TREE_PATH = 'dashboard';
export const useDashboardSelector = (key, storeTree = STORE_TREE_PATH) =>
    useSelector(state => state[storeTree][key].entityPayload);

export const useDashboardEntityRequestProcessingStatusSelector = (key, storeTree = STORE_TREE_PATH) =>
    useSelector(state => state[storeTree][key].entityRequestProcessingStatus);

// Entities
export const PROFILE = 'profile';
export const CURRENT_BOOKINGS = 'current_bookings';
export const UPCOMING_BOOKINGS = 'upcoming_bookings';
export const PAST_BOOKINGS = 'past_bookings';
export const CANCELLED_BOOKINGS = 'cancelled_bookings';
export const BOOKING_DETAILS = 'booking_details';
export const PAYMENT = 'payment';
export const SECURITY = 'security';
export const REFUND_INFO = 'refund_info';
export const USER_WISHLISTS = 'user_wishlists';
export const USER_WATCHLIST = 'user_watchlist';
export const USER_NOTIFICATIONS = 'user_notifications';
export const USER_NOTIFICATIONS_SETTINGS = 'user_notification_settings';
export const USER_NOTIFICATIONS_BY_PROPERTY = 'user_notifications_by_property';

const getInitialState = () => ({
    [PROFILE]: {
        ...getEntityInitialState()
    },
    [SECURITY]: {
        ...getEntityInitialState()
    },
    [CURRENT_BOOKINGS]: {
        ...getEntityInitialState()
    },
    [UPCOMING_BOOKINGS]: {
        ...getEntityInitialState()
    },
    [PAST_BOOKINGS]: {
        ...getEntityInitialState()
    },
    [CANCELLED_BOOKINGS]: {
        ...getEntityInitialState()
    },
    [BOOKING_DETAILS]: {
        ...getEntityInitialState()
    },
    [REFUND_INFO]: {
        ...getEntityInitialState()
    },
    [USER_WISHLISTS]: {
        ...getEntityInitialState()
    },
    [USER_WATCHLIST]: {
        ...getEntityInitialState()
    },
    [USER_NOTIFICATIONS]: {
        ...getEntityInitialState()
    },
    [USER_NOTIFICATIONS_BY_PROPERTY]: {
        ...getEntityInitialState()
    },
    [USER_NOTIFICATIONS_SETTINGS]: {
        ...getEntityInitialState()
    },
    [USER_WATCHLIST]: {
        ...getEntityInitialState()
    },
    [PAYMENT]: {
        ...getEntityInitialState()
    },
});

export default createReducer(
    getInitialState(),
    {
        ...entityStoreReducersMixin({ actionType: DASHBOARD_USER_PROFILE, entityStorePath: PROFILE }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_PAYMENT_DELETE_CARD, entityStorePath: PAYMENT }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_PAYMENT_SET_DEFAULT_CARD, entityStorePath: PAYMENT }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_UPDATE_PROFILE_PICTURE, entityStorePath: PROFILE }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_UPDATE_PROFILE, entityStorePath: PROFILE }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_UPDATE_PASSWORD, entityStorePath: PROFILE }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_UPDATE_PROFILE_PHONE, entityStorePath: PROFILE }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_ADD_PROFILE_PHONE, entityStorePath: PROFILE }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_USER_UPCOMING_BOOKINGS, entityStorePath: UPCOMING_BOOKINGS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_USER_PAST_BOOKINGS, entityStorePath: PAST_BOOKINGS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_USER_CURRENT_BOOKINGS, entityStorePath: CURRENT_BOOKINGS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_USER_CANCELLED_BOOKINGS, entityStorePath: CANCELLED_BOOKINGS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_BOOKING_DETAILS, entityStorePath: BOOKING_DETAILS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_CANCEL_BOOKING, entityStorePath: BOOKING_DETAILS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_REFUND_INFO, entityStorePath: REFUND_INFO }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_USER_WISHLISTS, entityStorePath: USER_WISHLISTS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_UPDATE_USER_WISHLISTS, entityStorePath: USER_WISHLISTS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_DELETE_USER_WISHLISTS, entityStorePath: USER_WISHLISTS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_UNDO_DELETE_USER_WISHLISTS, entityStorePath: USER_WISHLISTS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_DELETE_USER_WISHLIST_LISTING, entityStorePath: USER_WISHLISTS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_NOTIFICATION_LISTING, entityStorePath: USER_NOTIFICATIONS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_MARK_NOTIFICATION_AS_READ, entityStorePath: USER_NOTIFICATIONS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_MARK_ALL_NOTIFICATIONS_AS_READ, entityStorePath: USER_NOTIFICATIONS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_NOTIFICATION_BY_OBJECT, entityStorePath: USER_NOTIFICATIONS_BY_PROPERTY }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_NOTIFICATION_SETTINGS, entityStorePath: USER_NOTIFICATIONS_SETTINGS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_UPDATE_NOTIFICATION_SETTINGS, entityStorePath: USER_NOTIFICATIONS_SETTINGS }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_GET_USER_WATCHLIST, entityStorePath: USER_WATCHLIST }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_UPDATE_WATCHLIST_PROPERTIES, entityStorePath: USER_WATCHLIST }),
        ...entityStoreReducersMixin({ actionType: DASHBOARD_REMOVE_PROPERTY_FROM_WATCHLIST, entityStorePath: USER_WATCHLIST }),
        [DASHBOARD_RESET]: getInitialState
    }
);
