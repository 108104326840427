import { VOUCHER_CODE_ADD, VOUCHER_CODE_REMOVE, VOUCHER_CODE_REMOVE_ERROR, VOUCHER_CODE_ADD_ERROR, VOUCHER_CODE_LOADING, VOUCHER_SCREEN_LOADING, RESET_STATE } from '../actions/voucherCodeAction';

const initialState = {
    success: null,
    remove: null,
    addError: null,
    removeError: null,
    loading: false,
    updatingSideWidget: false
};

const VoucherStore = (state = initialState, action) => {
    switch (action.type) {
        case RESET_STATE:
            return initialState;
        case VOUCHER_CODE_ADD:
            return {
                success: action.data,
                remove: null,
                addError: null,
                removeError: null,
                loading: false,
                updatingSideWidget: true
            }
        case VOUCHER_CODE_REMOVE:
            return {
                remove: action.data,
                removeError: null,
                addError: null,
                success: null,
                loading: false,
                updatingSideWidget: true
            }
        case VOUCHER_CODE_ADD_ERROR:
            return {
                addError: action.data,
                success: null,
                remove: null,
                removeError: null,
                loading: false
            }
        case VOUCHER_CODE_REMOVE_ERROR:
            return {
                removeError: action.data,
                addError: null,
                success: null,
                remove: null,
                loading: false
            }
        case VOUCHER_CODE_LOADING:
            return {
                ...state,
                loading: true
            }
        case VOUCHER_SCREEN_LOADING:
            return {
                ...state,
                updatingSideWidget: false
            }
        default:
            return state;
    }
};

export default VoucherStore;