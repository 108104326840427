// Profile

export const DASHBOARD_USER_PROFILE = 'DASHBOARD_USER_PROFILE';
export const DASHBOARD_UPDATE_PROFILE_PICTURE = 'DASHBOARD_UPDATE_PROFILE_PICTURE';
export const DASHBOARD_UPDATE_PROFILE_PHONE = 'DASHBOARD_UPDATE_PROFILE_PHONE';
export const DASHBOARD_UPDATE_PROFILE = 'DASHBOARD_UPDATE_PROFILE';
export const DASHBOARD_UPDATE_PASSWORD = 'DASHBOARD_UPDATE_PASSWORD';
export const DASHBOARD_ADD_PROFILE_PHONE = 'DASHBOARD_ADD_PROFILE_PHONE';

export const DASHBOARD_GET_USER_UPCOMING_BOOKINGS = 'DASHBOARD_GET_USER_UPCOMING_BOOKINGS';
export const DASHBOARD_GET_USER_PAST_BOOKINGS = 'DASHBOARD_GET_USER_PAST_BOOKINGS';
export const DASHBOARD_GET_USER_CURRENT_BOOKINGS = 'DASHBOARD_GET_USER_CURRENT_BOOKINGS';
export const DASHBOARD_GET_USER_CANCELLED_BOOKINGS = 'DASHBOARD_GET_USER_CANCELLED_BOOKINGS';
export const DASHBOARD_GET_BOOKING_DETAILS = 'DASHBOARD_GET_BOOKING_DETAILS';
export const DASHBOARD_GET_REFUND_INFO = 'DASHBOARD_GET_REFUND_INFO';
export const DASHBOARD_CANCEL_BOOKING = 'DASHBOARD_CANCEL_BOOKING';

export const DASHBOARD_PAYMENT_DELETE_CARD = 'DASHBOARD_PAYMENT_DELETE_CARD';
export const DASHBOARD_PAYMENT_SET_DEFAULT_CARD = 'DASHBOARD_PAYMENT_SET_DEFAULT_CARD';

export const  DASHBOARD_RESET = 'DASHBOARD_RESET';
export const DASHBOARD_GET_USER_WISHLISTS = 'DASHBOARD_GET_USER_WISHLISTS';
export const DASHBOARD_UPDATE_USER_WISHLISTS = 'DASHBOARD_UPDATE_USER_WISHLISTS';
export const DASHBOARD_DELETE_USER_WISHLISTS = 'DASHBOARD_DELETE_USER_WISHLISTS';
export const DASHBOARD_DELETE_USER_WISHLIST_LISTING = 'DASHBOARD_DELETE_USER_WISHLIST_LISTING';
export const DASHBOARD_UNDO_DELETE_USER_WISHLISTS = 'DASHBOARD_UNDO_DELETE_USER_WISHLISTS';

export const DASHBOARD_GET_USER_WATCHLIST = 'DASHBOARD_GET_USER_WATCHLIST';
export const DASHBOARD_ADD_PROPERTY_TO_WATCHLIST = 'DASHBOARD_ADD_PROPERTY_TO_WATCHLIST';
export const DASHBOARD_REMOVE_PROPERTY_FROM_WATCHLIST = 'DASHBOARD_REMOVE_PROPERTY_FROM_WATCHLIST';
export const DASHBOARD_UPDATE_WATCHLIST_PROPERTIES = 'DASHBOARD_UPDATE_WATCHLIST_PROPERTIES';

export const DASHBOARD_GET_NOTIFICATION_LISTING = 'DASHBOARD_GET_NOTIFICATION_LISTING';
export const DASHBOARD_GET_NOTIFICATION_BY_OBJECT = 'DASHBOARD_GET_NOTIFICATION_BY_OBJECT';
export const DASHBOARD_MARK_NOTIFICATION_AS_READ = 'DASHBOARD_MARK_NOTIFICATION_AS_READ';
export const DASHBOARD_MARK_ALL_NOTIFICATIONS_AS_READ = 'DASHBOARD_MARK_ALL_NOTIFICATIONS_AS_READ';
export const DASHBOARD_GET_NOTIFICATION_SETTINGS = 'DASHBOARD_GET_NOTIFICATION_SETTINGS';
export const DASHBOARD_UPDATE_NOTIFICATION_SETTINGS = 'DASHBOARD_UPDATE_NOTIFICATION_SETTINGS';
