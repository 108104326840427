import { LOGOUT_SUCCESS } from "@/redux/types";
import {
    DASHBOARD_USER_PROFILE,
    DASHBOARD_USER_UNREADCOUNTS,
    DASHBOARD_PROFILE_VERIFICATION,
    DASHBOARD_USER_UNREADCOUNTS_TOZERO
} from "../types/dashboardTypes";


export const dashboardProfileReducer = (state = {
    profile: {
        pending: true,
        failed: false,
        message: "",
        payload: {
            data: null,
            error: null,
        }
    },
    verification: {
        pending: true,
        failed: false,
        message: "",
        payload: {
            data: null,
            error: null,
        }
    },
}, action ) => {

    if([LOGOUT_SUCCESS].includes(action.type)) {
        return {
            ...state,
            profile: {
                pending: true,
                failed: false,
                message: "",
                payload: {
                    data: null,
                    error: null,
                }
            }
        }
    }

    // LOGOUT_SUCCESS
	if ([DASHBOARD_USER_PROFILE].includes(action.type)) {
		return {
			...state,
			profile: action.data
		};
	}
    if ([DASHBOARD_PROFILE_VERIFICATION].includes(action.type)) {
		return {
			...state,
			verification: action.data
		};
	}
    if ([DASHBOARD_USER_UNREADCOUNTS].includes(action.type)) {
		return {
			...state,
			unreadCounts: action.data
		};
	}
    if ([DASHBOARD_USER_UNREADCOUNTS_TOZERO].includes(action.type)) {
		return {
			...state,
			unreadCounts: {
                ...state.unreadCounts,
                payload: {
                  ...state.unreadCounts.payload,
                  data: {
                    ...state.unreadCounts.payload.data,
                    unread_notifications: 0,
                  },
                },
              },
		};
	}
	return state;
}

