import { useEffect } from 'react';
import * as gtag from '../gtag';
import useRouteEventSubscriptions from './useRouteEventSubscriptions';
import { EAType } from '@/static/contants';

export default function usePageAnalytics() {
    /**
     * Specification
     * namespace cn-ga-*
     * actions:
     *  observe (viewport)
     *  click
     * parameters:
     *  cn-ga-[actionName]={...params}
     * example:
     * data-cn-ga-observe / data-cn-ga-viewport, data-cn-ga-click
     *
     * meta:
     * data-cn-ga-observe-active
     * data-cn-ga-click-active
     *
     * { ec (EventCategory), ea (EventAction), el (EventLabel), pa (ProductAction) }
     */

    useEffect(() => {
        let observersPool = [];
        const intervalId = setInterval(() => {
            // viewport events
            for (const el of Array.from(document.querySelectorAll('[data-cn-ga-observe]:not([data-cn-ga-observe-active]), [data-cn-ga-viewport]:not([data-cn-ga-viewport-active])'))) {
                if (el.dataset.cnGaObserveActive) {
                    continue;
                }

                const intersectionObserverInstance = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            const { cnGaObserve, cnGaViewport } = el.dataset;
                            let actionPayload = {};
                            try {
                                actionPayload = JSON.parse(cnGaObserve ?? cnGaViewport);
                            } catch { }

                            if (Object.entries(actionPayload).length > 0) {
                                const { ec = gtag.getCategoryName(), ea = EAType.SCROLL, el } = actionPayload;
                                if (ec && el) {
                                    gtag.event({ action: ea, category: ec, label: el, nonInteraction: true });
                                }
                            }
                            observer.unobserve(el);
                        }
                    });
                });

                observersPool.push(
                    intersectionObserverInstance
                );

                intersectionObserverInstance.observe(el);

                el.dataset.cnGaObserveActive = true;
            }

            // click events
            for (const el of Array.from(document.querySelectorAll('[data-cn-ga-click]:not([data-cn-ga-click-active])'))) {
                if (el.dataset.cnGaClickActive) {
                    continue;
                }

                el.dataset.cnGaClickActive = true;
            }
        }, 1000);

        const eventClickHandler = ({ target }) => {
            const currentTarget = target.closest('[data-cn-ga-click]');
            if (currentTarget) {
                let actionPayload = {};
                try {
                    actionPayload = JSON.parse(currentTarget.dataset.cnGaClick);
                } catch { }

                if (Object.entries(actionPayload).length > 0) {
                    const { ec = gtag.getCategoryName(), ea = EAType.CLICK, el } = actionPayload;
                    if (ec && el) {
                        gtag.event({ action: ea, category: ec, label: el });
                    }
                }
            }
        };
        window.addEventListener('click', eventClickHandler);

        return () => {
            clearInterval(intervalId);

            observersPool.forEach(observer => {
                observer.disconnect();
            });

            window.removeEventListener('click', eventClickHandler);
        };
    }, []);

    /** Bounce rate debug */
    // useEffect(() => {
    //     gtag.event({ action: 'PageView', category: gtag.getCategoryName(), label: `${location.pathname}${location.search}` });
    // }, []);

    useRouteEventSubscriptions({
        eventName: 'routeChangeComplete',
        eventHandler: url => {
            gtag.pageview(url);
            /** Bounce rate debug */
            // gtag.event({ action: 'PageView', category: gtag.getCategoryName(url), label: url });
        }
    });
}