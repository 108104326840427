import { ADD_TO_LIST, SAVE_TO_WISHLIST_PROPERTY, EDIT_LIST, HOVER_CARD, INIT_LIST, RESIZE_WINDOW, SAVE_TO_LIST, TOGGLE_LIST, TOGGLE_SAVE_TO_WISHLIST_MODAL, RESET_LIST, DELETE, NOTLOGGED_LIST } from "@/redux/actions/wishListActions";
import Lists from "@/components/common/SaveWishList/models/lists";
import { ResolutionTypesEnum } from "@/containers/WishList/enum";

const initialState = {
  swlists:[],
  lists:[],
  checked: false,
  activeListName: '',
  message: '',
  loading: true,
  hoveredProperty: {},
  resObject: ResolutionTypesEnum.MOBILE,
  saveToWishlistProperty: null,
  isSaveToWishlistModalOpen: false
};

const wishlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_LIST:
            return {
                ...state,
                loading: false,
                swlists: [...action.data],
                lists: [...action.data],
            }
        case RESET_LIST:
            return {
                ...state,
                swlists: [...action.list],
                lists: [...action.list],
                checked: false,
            }
        case NOTLOGGED_LIST:
            return {
                ...state,
                swlists: [],
                lists: [],
                checked:false,
                loading:false
            }
        case ADD_TO_LIST:
            const newList = new Lists(
                [],
                action.listName,
                true,
                false
            );
            if(state.swlists.length > 0){
                let swCopyState = [...state.swlists];
                for(let x in swCopyState){
                    if(newList.listName === swCopyState[x].listName){
                        swCopyState[x].selected = true;
                        return{
                            ...state,
                            swlists: [...swCopyState]
                        }
                    }else{
                        swCopyState[x].selected = false;
                    }
                }
                return {
                    ...state,
                    swlists: [newList, ...swCopyState]
                };
            }else{
                return{
                    ...state,
                    swlists: [newList]
                }
            }
        case SAVE_TO_LIST:
            return {
                ...state,
                swlists:[...action.list],
                lists: [...action.list],
            }
        case EDIT_LIST:
            return {
                ...state,
                swlists: [...action.list],
                checked: true,
            }
        case DELETE:
            return {
                ...state,
                swlists:[...action.list],
                lists: [...action.list],
            }
        case TOGGLE_LIST: {
            return {
                ...state,
                activeListName: action.payload.activeListName
            };
        }
        case HOVER_CARD: {
            return {
                ...state,
                hoveredProperty: action.payload.hoveredProperty
            };
        }
        case RESIZE_WINDOW: {
            return {
                ...state,
                resObject: action.payload.resObject
            };
        }
        case SAVE_TO_WISHLIST_PROPERTY: {
            return {
                ...state,
                saveToWishlistProperty: action.payload.saveToWishlistProperty
            };
        }
        case TOGGLE_SAVE_TO_WISHLIST_MODAL: {
            return {
                ...state,
                isSaveToWishlistModalOpen: action.payload.isSaveToWishlistModalOpen
            };
        }
        default:
            return state;
    }
};

export default wishlistReducer;
