import { useState } from "react";

export default function useCurrency() {
    const [isOpen, setOpen] = useState(false);

    const open = () => setOpen(true);

    const close = () => setOpen(false);

    return {
        isOpen,
        setOpen,
        open,
        close
    };
};
