import Popper from '@mui/material/Popper';
import { styled } from '@material-ui/core/styles';


const StyledPopper = styled(Popper, {
    shouldForwardProp: (prop) => !['arrow', 'color'].includes(prop),
})(({ arrow, color }) => ({
    '& > div': {
        position: 'relative',
    },
    '&[data-popper-placement*="bottom"]': {
        '& > div': {
            marginTop: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`,
            },
        },
    },
    '&[data-popper-placement*="top"]': {
        '& > div': {
            marginBottom: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: 'black',
                borderColor: `${color} transparent transparent transparent`,
            },
        },
    },
    '&[data-popper-placement*="right"]': {
        '& > div': {
            marginLeft: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`,
            },
        },
    },
    '&[data-popper-placement*="left"]': {
        '& > div': {
            marginRight: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`,
            },
        },
    },
}));

const StyledPopperArrow = styled('div')({
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
    },
});

export { StyledPopper, StyledPopperArrow };
