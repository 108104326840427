import {
    getGuestUnitsByNumber,
    guestAgeTypes,
    getGuestUnit,
    childAgeValidator
} from '@/components/formComponents/GuestSelector/guestSelectorHelpers';
import { convertToNum } from '@/utils/globalFunc';

export default function useGetGuests() {
    return ({ adults, children, infants }, isVR) =>
        isVR ? [
            ...getGuestUnitsByNumber(convertToNum(adults, 1), guestAgeTypes.ADULT),
            ...getGuestUnitsByNumber(convertToNum(children, 0), guestAgeTypes.CHILD),
            ...getGuestUnitsByNumber(convertToNum(infants, 0), guestAgeTypes.INFANT)
        ] : [
            ...getGuestUnitsByNumber(convertToNum(adults, 1), guestAgeTypes.ADULT),
            ...getGuestUnitsByNumber(convertToNum(infants, 0), guestAgeTypes.INFANT),
            ...(children
                ? String(children).split(',')
                    .filter(age => Boolean(age))
                    .map(ageToParse => getGuestUnit(guestAgeTypes.CHILD, childAgeValidator(ageToParse).value))
                : [])
        ];
};
