import { INIT_PROMO_DEALS_LIST } from '@/redux/actions/promoDealsActions';

const initialState = {
  data:[],
};

const promoDealsReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_PROMO_DEALS_LIST:
            return {
                ...state,
                data: action.data.data
            }
        default:
            return state;
    }
};

export default promoDealsReducer;
