import { constants } from '@/src/utils/constants';
import {
    ONBOARD_INIT,
    ONBOARD_SET_VISIBILITY,
    ONBOARD_REMOVE_MSG
} from '../types';


const initialState = {
    data: null,
    message: null,
}

const onboardingReducer = (state = initialState, { type, data }) => {
    switch (type) {
        case ONBOARD_INIT:
            return {
                data: data,
                message: null,
                showing: null,
            };
        case ONBOARD_SET_VISIBILITY:
            return {
                ...state,
                data: state.data.map(item => (item.name === data.name
                    ? ({ ...item, ...data })
                    : item))
            };
        case ONBOARD_REMOVE_MSG:
            if (!data) {
                return state;
            }
            let shownMsgNames = localStorage.getItem('onboarding');
            shownMsgNames = shownMsgNames ? JSON.parse(shownMsgNames) : [];

            if (Array.isArray(shownMsgNames)) {
                shownMsgNames = [...shownMsgNames, data];
            } else {
                shownMsgNames = [data]
            }
            
            localStorage.setItem('onboarding', JSON.stringify(shownMsgNames));
            return {
                ...state,
                data: state.data.filter(item => (item.name !== data))
            };

        default:
            return state;
    }
};

export default onboardingReducer;
