import { DestinationTypeEnum } from '@/static/contants';

export const resolutionType = {
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
    DESKTOP: 'DESKTOP'
};

export const viewState = {
    MOBILE: {
        DEFAULT: 'MOBILE_DEFAULT',
        FILTERS: 'MOBILE_FILTERS',
        MAP: 'MOBILE_MAP'
    },
    TABLET: {
        DEFAULT: 'TABLET_DEFAULT',
        MAP: 'TABLET_MAP'
    },
    DESKTOP: {
        DEFAULT: 'DESKTOP_DEFAULT',
        MAP_HIDDEN: 'DESKTOP_MAP_HIDDEN',
        MAP_EXPANDED: 'DESKTOP_MAP_EXPANDED',
        MAP: 'DESKTOP_MAP_EXPANDED'
    }
};

export const resolutionSize = {
    MOBILE: 0,
    TABLET: 601,// 580, //450,
    DESKTOP: 1100// 800, 930
};

export const propertyVariant = {
    HOTEL: 'hotel',
    VR: 'vr'
};

export const sortByTypes = {
    DISCOUNTED_STAYS_FIRST: 'discounted_stays_first',
    RECOMMENDED: 'recommended',
    LOWEST_PRICE: 'price_asc',
    HIGHEST_PRICE: 'price_desc',
    HIGHEST_RATING: 'rating',
    CLOSEST_TO_CENTER: 'center',
    TOP_RATED_LOWEST_PRICE: 'top rated + lowest price',
    MOST_REVIEWED_FIRST: 'most reviewed',
    APARTMENT_AND_HOMES: 'apartment homes'
};

const seoProps = {
    title: 'Search Accommodations',
    description: 'The best properties from around the world at the ✓ cheapest price possible. Browse ✓ thousands of rooms ✓ Hotels ✓ Vacation Homes ✓ Apartments ✓ Condos ✓ Villas ✓ Beach Houses ✓ and get your reservation with CuddlyNest.',
    robots: 'noindex, nofollow'
};

export const metaInfo = {
    general: seoProps,
    [propertyVariant.HOTEL]: {
        ...seoProps,
        title: 'Search Hotels',
        description: 'The best hotels from around the world at the ✓ cheapest price possible. Browse ✓ thousands of rooms and get your Hotel reservation with CuddlyNest.',
    },
    [propertyVariant.VR]: {
        ...seoProps,
        title: 'Search Vacation Rentals',
        description: 'Book the best vacation rentals worldwide with great deals on ✓Vacation Homes ✓ Apartments ✓ Condos ✓ Villas ✓ Beach Houses ✓ Home Rental and more!',
    }
};

export const searchFiltersState = {
    UNINITIALIZED: 'UNINITIALIZED',
    READY: 'READY',
    PROCESSED: 'PROCESSED'
};

// Unused just for the reference
export const querySearchParamsRegistry = {
    host_id: 'host_id', //-> only for API
    pageNo: 'page',
    page: 'page',

    hotels: 'type',
    type: 'type',

    nwLat: 'nwLat',
    nwLng: 'nwLng',
    seLat: 'seLat',
    seLng: 'seLng',
    lat: 'lat',
    lon: 'lon',

    location_type: 'location_type',

    // limit: 'limit',
    sort: 'sort',

    city: 'location',
    location: 'location',

    checkin: 'checkin',
    checkout: 'checkout',
    dates: 'dates', // -> abstract

    adults: 'adults',
    guests: 'adults',
    // children: {
    //     [propertyVariant.HOTEL]: 'children[]',
    //     [propertyVariant.VR]: 'children'
    // },
    'children[]': 'children[]',
    children: 'children',
    childrenAges: 'childrenAges',

    infants: 'infants',

    rooms: 'rooms',

    pricemin: 'pricemin',
    pricemax: 'pricemax',

    currency: 'currency',

    trip_type: 'trip_type',
    'star_rating[]': 'star_rating[]',
    'listvalue[]': 'listvalue[]', // services & amenities
    'property_type[]': 'property_type[]',
    'room_type[]': 'room_type[]',
    'neighbourhood[]': 'neighbourhood[]',
    'attraction[]': 'attraction[]',
    activitiesLdp: 'activities',
    price: 'price',
    sessionId: 'sessionid'
};

export const filterTypes = {
    // Primary filters
    searchTerm: 'searchTerm',
    dates: 'dates',
    guests: 'guests',
    childrenAges: 'childrenAges',
    rooms: 'rooms',

    // Secondary filters
    sortBy: 'sortBy',
    neighbourhoods: 'neighbourhoods',
    landmarks: 'landmarks',
    budget: 'budget',
    priceSlots: 'price_slots',
    starRatings: 'starRatings',
    propertyTypes: 'propertyTypes',
    amenities: 'amenities',
    bedrooms: 'bedrooms',
    beds: 'beds',
    noofbathrooms: 'noofbathrooms',
    hotelChains: 'hotelChains',
    breakfastIncluded: 'breakfastIncluded',
    recommended: 'recommended',
    themes: 'themes',
    rules: 'rules',
    cancellationPolicies: 'cancellationPolicies',
    views: 'views',
    activities: 'activities',
    interests: 'interests',
    things_to_do: 'things_to_do',
    review_score: 'review_score',
    viewportMapBounds: 'viewportMapBounds',
    attractions: 'attractions',

    // partners staging only
    partners: 'partners'
};

export const viewMode = {
    HOST_VIEW: 'HOST_VIEW',
    DEFAULT: 'DEFAULT'
};

export const statusCodesLevels = {
    OK: 200,
    CLIENT_ERROR: 400,
    SERVER_ERROR: 500
};

export class RequestType {
    static WEB_SOCKET = 'WEB_SOCKET';
    static REST_API = 'REST_API';
}

export const ShowMoreVariant = {
    SEARCHABLE: 'SEARCHABLE',
    DEFAULT: 'DEFAULT',
    EXPANDABLE: 'EXPANDABLE'
};

export const LocationTypes = {
    ...DestinationTypeEnum,
    VIEWPORT: 'Viewport'
};

export const WTFUsmanType = {
    Viewport: 'viewport',
    Host: 'host_properties',
    Landmark: 'landmark',
    Poi: 'POI',
};

export const DefaultPriceRanges = {
    MIN: 1,
    MAX: 1000
};

export const sessionCookieName = 'sessionid';
