import { DestinationTypeEnum } from "@/static/contants";
import { concatFilter } from "./globalFunc";

export const normalizeLocationString = (...args) =>
    [...args]
        .flat()
        .filter(Boolean)
        .join(', ')
        .split(',')
        .map(s => String(s).trim())
        .filter(Boolean)
        .join(', ');

export const splitByToken = (location = '', splitAs = ',') =>
    location.split(splitAs).reduce((acc, str) => {
        const trimmedStr = str.trim();
        return trimmedStr ? [...acc, trimmedStr] : acc;
    }, []);

export const normalizeLocation = (location = '', splitAs = ',', joinAs = ', ') => {
    const locationArray = splitByToken(location, splitAs);

    for (let i = 0; i < locationArray.length; i++) {
        const currentItem = locationArray[i];
        const nextIndex = i + 1;
        if ((nextIndex < locationArray.length) && (currentItem === locationArray[nextIndex])) {
            locationArray.splice(i--, 1);
        }
    }

    return concatFilter(locationArray);
}

export const deNormalizeLocation = (location = '', splitAs = ',', joinAs = ', ') => {
    if (typeof location === 'string') {
        const locationArray = splitByToken(location, splitAs);

        const strategies = new Map()
            .set(1, locationArray => {
                return Array(3).fill(locationArray[0]);
            })
            .set(2, locationArray => {
                return [
                    ...Array(2).fill(locationArray[0]),
                    locationArray.slice(-1)[0]
                ];
            });

        const strategy = strategies.get(locationArray.length);
        if (typeof strategy === 'function') {
            return strategy(locationArray).join(joinAs);
        }
    }
    return location;
}

/**
 * @param { action: Function, type: string | Array<string> locationType: DestinationTypeEnum | Array<DestinationTypeEnum> }>
 * @returns Function
 */
export const aggregateLocationByType = ({ action, type, locationType = DestinationTypeEnum.CITY }) => {
    const aggregatedArrayOfLocationTypes = [type].flat();

    if (
        [locationType]
            .flat()
            .some(destinationType =>
                aggregatedArrayOfLocationTypes.includes(destinationType)
            )
        ) {
        return action;
    }

    return location => location;
};
