import { memo } from "react";

const CurrencyProgressive = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#currency_progressive)">
            <path d="M6.95217 15.2803L5.70221 14.6554C5.54846 14.5798 5.36036 14.641 5.28284 14.7954L4.65788 16.0453C4.581 16.1997 4.64349 16.3872 4.79788 16.4647C4.84349 16.4872 4.89101 16.4978 4.93788 16.4978C5.05225 16.4978 5.16287 16.4347 5.21725 16.3253L5.70224 15.3547L6.67283 15.8397C6.82784 15.916 7.01533 15.8547 7.0922 15.6997C7.16904 15.5453 7.10652 15.3578 6.95217 15.2803Z" fill="#64748B" />
            <path d="M8.68955 17.7487C7.08462 17.7437 6.05842 16.3094 5.87342 14.8957C5.8503 14.7238 5.68279 14.6013 5.52281 14.6263C5.35093 14.6488 5.23093 14.8057 5.25282 14.9769C5.4728 16.66 6.72337 18.3674 8.68642 18.3736C8.68703 18.3736 8.68703 18.3736 8.68768 18.3736C8.86017 18.3736 9.00017 18.2336 9.00079 18.0618C9.00141 17.8899 8.8614 17.7493 8.68955 17.7487Z" fill="#64748B" />
            <path d="M18.2015 6.53352C18.0471 6.45852 17.8596 6.51913 17.7821 6.67352L17.2971 7.64412L16.3265 7.15913C16.1722 7.08352 15.9847 7.14475 15.9071 7.29913C15.8303 7.45349 15.8928 7.64099 16.0471 7.7185L17.2971 8.34347C17.3427 8.36597 17.3902 8.3766 17.4371 8.3766C17.5515 8.3766 17.6621 8.31347 17.7165 8.20285L18.3415 6.95289C18.4184 6.7985 18.3558 6.61101 18.2015 6.53352Z" fill="#64748B" />
            <path d="M17.7471 8.01833C17.5271 6.33526 16.2766 4.62785 14.3135 4.62158C14.3129 4.62158 14.3129 4.62158 14.3123 4.62158C14.1404 4.62158 14.0004 4.76159 13.9998 4.93343C13.9991 5.10531 14.1391 5.24593 14.311 5.24655C15.9159 5.25156 16.9421 6.68587 17.1271 8.09956C17.1477 8.25706 17.2821 8.37143 17.4365 8.37143C17.4509 8.37143 17.464 8.37081 17.4771 8.36894C17.649 8.34644 17.769 8.18956 17.7471 8.01833Z" fill="#64748B" />
            <path d="M12.125 12.436C12.125 12.8804 12.6719 13.2641 13.375 13.3535V11.5186C12.6719 11.608 12.125 11.9917 12.125 12.436Z" fill="#64748B" />
            <path d="M14 14.02V15.855C14.7037 15.7656 15.25 15.3812 15.25 14.9375C15.25 14.4931 14.7031 14.1094 14 14.02Z" fill="#64748B" />
            <path d="M13.6875 8.37695C10.7583 8.37695 8.37524 10.76 8.37524 13.6892C8.37524 16.6185 10.7583 19.0015 13.6875 19.0015C16.6168 19.0015 18.9998 16.6185 18.9998 13.6892C18.9998 10.76 16.6168 8.37695 13.6875 8.37695ZM15.8749 14.9392C15.8749 15.7385 15.0719 16.3735 14 16.4798V16.8141C14 16.9866 13.86 17.1266 13.6875 17.1266C13.515 17.1266 13.375 16.9866 13.375 16.8141V16.4798C12.3032 16.3735 11.5001 15.7385 11.5001 14.9392C11.5001 14.7667 11.6401 14.6267 11.8126 14.6267C11.9851 14.6267 12.1251 14.7667 12.1251 14.9392C12.1251 15.3835 12.672 15.7673 13.375 15.8566V13.9798C12.3032 13.8736 11.5001 13.2386 11.5001 12.4393C11.5001 11.6399 12.3032 11.005 13.375 10.8987V10.5643C13.375 10.3919 13.5151 10.2518 13.6875 10.2518C13.86 10.2518 14 10.3919 14 10.5643V10.8987C15.0719 11.005 15.875 11.6399 15.875 12.4393C15.875 12.6118 15.735 12.7518 15.5625 12.7518C15.39 12.7518 15.25 12.6118 15.25 12.4393C15.25 11.9949 14.7031 11.6112 14 11.5218V13.3986C15.0725 13.5049 15.8749 14.1398 15.8749 14.9392Z" fill="#64748B" />
            <path d="M13.2836 7.77047C12.8561 5.62368 10.9587 4 8.68754 4C6.10265 4 4.00024 6.1024 4.00024 8.68731C4.00024 10.9585 5.62331 12.8559 7.77071 13.2834C7.81884 12.5778 7.99321 11.9072 8.2663 11.2903C8.24193 11.3472 8.21193 11.4003 8.18882 11.4572C7.21137 11.2997 6.50013 10.6903 6.50013 9.93724V9.62477H6.18763C6.01514 9.62477 5.87514 9.48477 5.87514 9.31227C5.87514 9.13978 6.01514 8.99978 6.18763 8.99978H6.50013V8.37481H6.18763C6.01514 8.37481 5.87514 8.23481 5.87514 8.06231C5.87514 7.88982 6.01514 7.74985 6.18763 7.74985H6.50013V7.43735C6.50013 6.56115 7.4607 5.87492 8.68754 5.87492C9.91439 5.87492 10.875 6.56115 10.875 7.43735C10.875 7.60984 10.735 7.74985 10.5625 7.74985C10.39 7.74985 10.25 7.60984 10.25 7.43735C10.25 6.92925 9.53436 6.49989 8.68754 6.49989C7.84073 6.49989 7.12512 6.92925 7.12512 7.43735V7.74985H9.31254C9.48503 7.74985 9.62503 7.88985 9.62503 8.06234C9.62503 8.23484 9.48503 8.37484 9.31254 8.37484H7.12512V8.9998H9.31254C9.48503 8.9998 9.62503 9.13981 9.62503 9.3123C9.62503 9.34043 9.61628 9.3648 9.60942 9.39105C10.5818 8.46794 11.8624 7.86671 13.2836 7.77047Z" fill="#64748B" />
            <path d="M9.39141 9.60742C9.36578 9.61366 9.34079 9.62304 9.31266 9.62304H7.12524V9.93553C7.12524 10.3986 7.72085 10.7918 8.46769 10.8593C8.42457 10.938 8.39208 11.023 8.35332 11.1043C8.62143 10.5518 8.9733 10.048 9.39141 9.60742Z" fill="#64748B" />
        </g>
        <defs>
            <clipPath id="currency_progressive">
                <rect width="15" height="15" fill="white" transform="translate(4 4)" />
            </clipPath>
        </defs>
    </svg>
);

export default memo(CurrencyProgressive);
