import { memo } from "react";

const FaqsAndHelpProgressive = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M19 14.4746C19 12.7373 18.0033 11.1868 16.5106 10.4326C16.4643 13.7636 13.7636 16.4643 10.4326 16.5106C11.1868 18.0033 12.7373 19 14.4746 19C15.2891 19 16.0812 18.783 16.7772 18.3708L18.9802 18.9802L18.3708 16.7772C18.783 16.0812 19 15.2891 19 14.4746Z" fill="#64748B" />
        <path d="M15.6914 10.3457C15.6914 7.39803 13.2934 5 10.3457 5C7.39803 5 5 7.39803 5 10.3457C5 11.3064 5.25571 12.2417 5.74127 13.0629L5.01965 15.6716L7.62852 14.9501C8.44969 15.4357 9.38504 15.6914 10.3457 15.6914C13.2934 15.6914 15.6914 13.2934 15.6914 10.3457ZM9.52539 9.10156H8.70508C8.70508 8.19687 9.44101 7.46094 10.3457 7.46094C11.2504 7.46094 11.9863 8.19687 11.9863 9.10156C11.9863 9.56075 11.7919 10.0021 11.4528 10.3123L10.7559 10.9501V11.5898H9.93555V10.5889L10.899 9.70708C11.0712 9.54953 11.166 9.33452 11.166 9.10156C11.166 8.64922 10.798 8.28125 10.3457 8.28125C9.89336 8.28125 9.52539 8.64922 9.52539 9.10156ZM9.93555 12.4102H10.7559V13.2305H9.93555V12.4102Z" fill="#64748B" />
    </svg>
);

export default memo(FaqsAndHelpProgressive);
