import React, { memo } from 'react';
import Head from 'next/head';
import { constants } from '@/utils/constants';

const MapBase = props => (
    (typeof google === 'undefined') ||
    (typeof google.maps === 'undefined')
) && (
        <Head>
            <script
                {...props}
                key={props.id}
            />
        </Head>
    );

MapBase.defaultProps = {
    src: constants.googleMapsSrc,
    id: 'google-maps-api'
};

export default memo(MapBase);
