export const ResolutionTypesEnum = {
    DESKTOP: {
        threshold: 930,
        type: 'DESKTOP'
    },
    TABLET: {
        threshold: 601,
        type: 'TABLET'
    },
    MOBILE: {
        threshold: 0,
        type: 'MOBILE'
    },
};
