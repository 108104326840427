import { memo } from "react";

const ContactUsProgressive = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#contact_us_progressive)">
            <path d="M13.75 16.0833C13.428 16.0833 13.1667 16.3447 13.1667 16.6667V17.25C13.1667 17.572 12.9053 17.8333 12.5833 17.8333H6.75C6.428 17.8333 6.16667 17.572 6.16667 17.25V6.75C6.16667 6.428 6.428 6.16667 6.75 6.16667H7.04167C7.366 6.16667 7.62908 6.4315 7.625 6.757C7.62092 7.07667 7.88458 7.33333 8.20425 7.33333H10.8392C11.4482 7.33333 11.7118 7.07667 11.7083 6.757C11.7043 6.4315 11.9673 6.16667 12.2917 6.16667H12.5833C12.9053 6.16667 13.1667 6.428 13.1667 6.75V7.33333C13.1667 7.65533 13.428 7.91667 13.75 7.91667C14.072 7.91667 14.3333 7.65533 14.3333 7.33333V6.75C14.3333 5.78342 13.5499 5 12.5833 5H6.75C5.78342 5 5 5.78342 5 6.75V17.25C5 18.2166 5.78342 19 6.75 19H12.5833C13.5499 19 14.3333 18.2166 14.3333 17.25V16.6667C14.3333 16.3447 14.072 16.0833 13.75 16.0833Z" fill="#64748B" />
            <path d="M18.9999 10.2497C18.9999 9.60626 18.4767 9.08301 17.8333 9.08301H11.9999C11.3565 9.08301 10.8333 9.60626 10.8333 10.2497V13.7497C10.8333 14.3931 11.3565 14.9163 11.9999 14.9163H17.8333C18.4767 14.9163 18.9999 14.3931 18.9999 13.7497V10.2497ZM17.8333 11.4163L15.1033 12.688C15.0449 12.7172 14.9808 12.7288 14.9166 12.7288C14.8524 12.7288 14.7883 12.7172 14.7299 12.688L11.9999 11.4163V10.448L14.9166 11.8072L17.8333 10.448V11.4163Z" fill="#64748B" />
        </g>
        <defs>
            <clipPath id="contact_us_progressive">
                <rect width="14" height="14" fill="white" transform="translate(5 5)" />
            </clipPath>
        </defs>
    </svg>
);

export default memo(ContactUsProgressive);
