import { GET_CURRENCIES_LOAD, GET_CURRENCIES_SUCCESS, GET_CURRENCIES_ERR, URL_PATH, SET_CURRENCY, GEO_CODE_LOAD, GEO_CODE_ERROR, GEO_CODE_SUCCESS, GET_CURRENT_LOAD, GET_CURRENT_ERR, GET_CURRENT_SUCCESS, GEO_NEAR_LOAD, GEO_NEAR_SUCCESS, GEO_NEAR_ERROR, GEO_CODE_RESET } from "../types"
import { SUCCESS_NOTIFICATION, ERROR_NOTIFICATION, INFO_NOTIFICATION } from "../types"
import { getCurrencies, getGeoLocation, getCurrentLoc, getCountryNearbyUserCoordinates } from "../services/global"
import { _checkValidStatus } from "@/src/utils/globalValidations"
import { normalizeGeoCode, transformCountryNearbyResponse } from "@/src/utils/globalFunc"
import axios from "axios"
import { constants } from "@/src/utils/constants"

export const getCurrenciesActions = () => {
    return async(dispatch, getState) => {
        if (getState().currencies.type === GET_CURRENCIES_LOAD) {
            return;
        }
        dispatch({type: GET_CURRENCIES_LOAD, data: []})
        try{
            const res = await getCurrencies();
            console.log('getCurrenciesActions res -=====> ', res);
            if(_checkValidStatus(res)){
                let defaultCurrency = localStorage.getItem('currency');
                try {
                    defaultCurrency = JSON.parse(defaultCurrency);
                    if (!defaultCurrency) {
                        throw new Error('Default currency is empty');
                    }
                }catch {
                    defaultCurrency = constants.defaultCurrency ?? res.data.currencies.default_currencies?.[0];
                    localStorage.setItem('currency', JSON.stringify(defaultCurrency));
                }

                dispatch({type: GET_CURRENCIES_SUCCESS, data: { ...res.data, defaultCurrency }, message: 'Sucecss'})
            }
        }catch(err){
            dispatch({type: GET_CURRENCIES_ERR, data: err.data, message: err.message})
        }
    }
}

/**
 *
 * @param {*} title | heading
 * @param {*} description | description
 * @param {*} closeAfterMilliseconds | it will be closed after these passed milliseconds
 * @param {*} primaryAction | function that will be called when clicked on the primary action button
 * @param {*} primaryActionBtnTxt | primary action button's text or label to show on button
 * @param {*} secondaryAction | function that will be called when clicked on the seconday action button
 * @param {*} secondaryActionBtnTxt | secondary action button's text or label to show on button
 */
export const successNotificationActions = (title, description, closeAfterMilliseconds=false, primaryAction=null, primaryActionBtnTxt=null, secondaryAction=null, secondaryActionBtnTxt=null) => {
    return (dispatch) => {
        dispatch({type: SUCCESS_NOTIFICATION, successMsgTitle: title, successMsgDescription: description, primaryAction, primaryActionBtnTxt,secondaryAction,secondaryActionBtnTxt})

        // hide this notification after closeAfterMilliseconds milliseconds
        /* if(closeAfterMilliseconds)
        setTimeout(function(){
            dispatch({type: SUCCESS_NOTIFICATION, successMsgTitle: null, successMsgDescription: null})
        },closeAfterMilliseconds) */
    }
}

/**
 *
 * @param {*} title | heading
 * @param {*} description | description
 * @param {*} closeAfterMilliseconds | it will be closed after these passed milliseconds
 * @param {*} primaryAction | function that will be called when clicked on the primary action button
 * @param {*} primaryActionBtnTxt | primary action button's text or label to show on button
 * @param {*} secondaryAction | function that will be called when clicked on the seconday action button
 * @param {*} secondaryActionBtnTxt | secondary action button's text or label to show on button
 */
export const errorNotificationActions = (title, description, closeAfterMilliseconds=false, primaryAction=null, primaryActionBtnTxt=null, secondaryAction=null, secondaryActionBtnTxt=null) => {
    return (dispatch) => {
        dispatch({type: ERROR_NOTIFICATION, errorMsgTitle: title, errorMsgDescription: description, primaryAction, primaryActionBtnTxt,secondaryAction,secondaryActionBtnTxt})

        // hide this notification after closeAfterMilliseconds milliseconds
       /*  if(closeAfterMilliseconds)
        setTimeout(function(){
            dispatch({type: SUCCESS_NOTIFICATION, successMsgTitle: null, successMsgDescription: null})
        },closeAfterMilliseconds) */
    }
}

/**
 *
 * @param {*} title | heading
 * @param {*} description | description
 * @param {*} closeAfterMilliseconds | it will be closed after these passed milliseconds
 * @param {*} primaryAction | function that will be called when clicked on the primary action button
 * @param {*} primaryActionBtnTxt | primary action button's text or label to show on button
 * @param {*} secondaryAction | function that will be called when clicked on the seconday action button
 * @param {*} secondaryActionBtnTxt | secondary action button's text or label to show on button
 */
export const infoNotificationActions = (title, description, closeAfterMilliseconds=false, primaryAction=null, primaryActionBtnTxt=null, secondaryAction=null, secondaryActionBtnTxt=null) => {
    return (dispatch) => {
        dispatch({type: INFO_NOTIFICATION, infoMsgTitle: title, infoMsgDescription: description, primaryAction, primaryActionBtnTxt,secondaryAction,secondaryActionBtnTxt})

        // hide this notification after closeAfterMilliseconds milliseconds
        if(closeAfterMilliseconds)
        setTimeout(function(){
            dispatch({type: SUCCESS_NOTIFICATION, successMsgTitle: null, successMsgDescription: null})
        },closeAfterMilliseconds)
    }
}

export const setClasses = (url) => {
    return (dispatch) => {
        dispatch({type: URL_PATH, url: setUrl})
    }
}

export const selectCurrency = currency => ({
    type: SET_CURRENCY,
    payload: { currency }
});

let cancelToken = undefined;
export const geoLocationAction = data => {

    return async dispatch => {
        dispatch({type: GEO_CODE_LOAD});

        try{
              //Check if there are any previous pending requests
            if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.");
            }

            if (!data.data) {
                return dispatch({ type: null });
            }

            //Save the cancel token for the current request
            cancelToken = axios.CancelToken.source();
            const response = await getGeoLocation(data, cancelToken.token);
            cancelToken = undefined;

            if(response && Array.isArray(response) && response.length > 0) {
                return dispatch({type: GEO_CODE_SUCCESS, data: normalizeGeoCode(response)})
            }else {
                return dispatch({type: GEO_CODE_ERROR, data: response})
            }
        }catch(err) {

            return dispatch({type: GEO_CODE_ERROR, data: err})
        }
    }
}

export const resetGeoLocation = () => ({
    type: GEO_CODE_RESET
});

export const getCurrentLocation = () => {

    return async (dispatch) => {
        dispatch({type: GET_CURRENT_LOAD});
        try{
            let userLocation = localStorage.getItem('userAddress');
            if (userLocation) {
                userLocation = JSON.parse(userLocation);
                if (userLocation?.status === 'coords' && !!userLocation?.coords) {
                    const {latitude, longitude} = userLocation.coords;
                    userLocation = `?lat=${latitude}&lon=${longitude}`;
                } else {
                    userLocation = null;
                }
            }
            const response = await getCurrentLoc(userLocation);
            if(response && _checkValidStatus(response)) {
                return dispatch({type: GET_CURRENT_SUCCESS, data: response?.data?.result})
            }
            throw new Error('¡Vete a la mierda!');
        }catch(err) {
            return dispatch({type: GET_CURRENT_ERR, data: err})
        }
    }
}

export const getNearLocation = (data) => {

    return async (dispatch) => {
        dispatch({ type: GEO_NEAR_LOAD });
        try {
            const response = await getCountryNearbyUserCoordinates(data);
            // TODO: FE-865 revert
            dispatch({ type: GEO_NEAR_SUCCESS, data: transformCountryNearbyResponse(response.data) });
            // dispatch({ type: GEO_NEAR_SUCCESS, data: response.length > 0 ? normalizeGeoCode(response) : [] /*transformCountryNearbyResponse(response.data)*/ })
        } catch (err) {
            dispatch({ type: GEO_NEAR_ERROR, data: err })
        }
    }
}