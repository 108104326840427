import { useEffect, useState, useRef } from "react";
import useApi from "../../useApi";
import { currentPositionPromise } from "@/utils/currentPosition";
import {
    getCityNearbyAttractionsByUserCoordinates as getAttractionsNearbyAPI,
} from '@/redux/services/global';

export default function useAttractionsNearby() {

    const isComponentMounted = useRef(true);
    const [attractionsState, setAttractionsState] = useState([]);

   
    /**
     *
     * @param { city, state, country } locationData
     * @returns Promise
     */
    const fetchAtractionNearby = locationData => {
        const locationDataPromise =
            locationData
                ? Promise.resolve(locationData)
                : currentPositionPromise
                    .then(locationResponse => {
                        const { result } = locationResponse.data ?? {};
                        if (!result) {
                            throw new Error('Undefined location data');
                        }

                        return result;
                    });

        return locationDataPromise
                .then(getAttractionsNearbyAPI)
                .then(({ data }) => {
                    if (Array.isArray(data) && isComponentMounted.current) {
                        setAttractionsState(data);
                        return;
                    }
                    setAttractionsState([]);
                })
                .catch(console.error);
    };

    return {
        attractionsState,
        fetchAtractionNearby
    };
}
