import numeral from 'numeral';
import { countUtf8Bytes, entityToChar, formatNumber, roundToFixed, truncateUtf8Bytes } from '@/utils/globalFunc';
import { DestinationTypeEnum, mainHotelType } from '@/static/contants';

export default class General {
    static onReadyInfoWindow = infoWindowEl => {
        const scrollWrapperContainer = infoWindowEl.querySelector('.gm-style-iw-d');
        if (scrollWrapperContainer) {
            scrollWrapperContainer.style.overflow = 'visible';
        }
    }

    static getCurrencyWithPrice = (markers, markerDataGetter) => {
        let currency = '';
        const startPrice = markers.reduce((acc, marker) => {
            const { price } = markerDataGetter(marker);
            if (!currency && price?.[0]) {
                currency = price[0];
            }
            const priceValue = numeral(price).value();
            if (!acc || !priceValue) {
                return priceValue
            }
            return Math.min(priceValue, acc);
        }, 0);

        return { currency, startPrice };
    };

    static getListingsBounds(listings) {
        if (Array.isArray(listings) && listings.length > 0) {
            return listings.reduce((bounds, { lat, lon }) => {
                const latParsed = Number.parseFloat(lat);
                const lonParsed = Number.parseFloat(lon);
                if (Number.isNaN(latParsed) || Number.isNaN(lonParsed)) {
                    return bounds;
                }
                return bounds.extend(new google.maps.LatLng(latParsed, lonParsed))
            }, new google.maps.LatLngBounds());
        }

        // here we can return a current user location lat/lon
        return null;
    }

    static isTouchDevice() {
        return Boolean('ontouchstart' in window || navigator.msMaxTouchPoints);
    }

    static truncateStr(str, maxLength) {
        const s = str ? entityToChar(str) : '';
        return countUtf8Bytes(s) > maxLength ? `${truncateUtf8Bytes(s, maxLength)}...` : s;
    }

    static getPropertyDistance(
        { value, unit } = {},
        { minThreshold = 0.1, maxThreshold = 60 } = {}
    ) {
        // const propertyDistanceValue = Math.round((value ?? '').replace(/,/g, '')); // -> if we need rounding
        const propertyDistanceUnit = (unit ?? '').toLowerCase();
        const precision = (new Map())
            .set('km', 1)
            .set('mile', 1)
            .set('miles', 1)
            .set('m', 0)
            .set('meters', 0);
        let distanceValue = Number.parseFloat(value);
        if ((['km', 'mile', 'miles'].includes(propertyDistanceUnit)) &&
            (distanceValue > maxThreshold)) {
            return '';
        }

        if (distanceValue < minThreshold) {
            distanceValue = minThreshold;
        }
        distanceValue = Number.isNaN(distanceValue)
            ? 0
            : roundToFixed({
                value: distanceValue,
                precision: precision.get(propertyDistanceUnit) ?? 1,
                // precision: 1
            });
        if (distanceValue && propertyDistanceUnit) {
            return `${distanceValue} ${propertyDistanceUnit}`;
        }
        return '';
    }

    static getPropertyPrice({ currency_symbol: currencySymbol, amount } = {}, decimalPlaces = null) {
        let priceAmount;

        if (typeof decimalPlaces === 'number') {
            priceAmount = Number.parseFloat(amount)?.toFixed(decimalPlaces);
        } else {
            priceAmount =  Math.round(amount);
        }

        if (!currencySymbol || !priceAmount) {
            return '';
        }

        priceAmount = formatNumber(priceAmount);
        return `${currencySymbol}${priceAmount}`;
    }

    static getPropertType(main_propertyType = null) {
        if(mainHotelType.includes(main_propertyType[0])){
            return DestinationTypeEnum.HOTEL
        } else {
            return DestinationTypeEnum.VR
        }
    }

    static getPropertyPriceDrop({ amount, rate, cache_price, new_price, currency_symbol: currencySymbol } = {} ,numberOfNights = 1) {
        if (!cache_price || !new_price || !currencySymbol || !rate) {
            return null;
        }
        const cachePrice = Math.round(cache_price * rate);
        // const newPrice = Math.round(new_price * rate);
        const newPrice = amount;
        const dropValue = cachePrice - newPrice;

        if (newPrice < cachePrice) {
            return {
                priceDrop: `${currencySymbol}${formatNumber(dropValue)}`,
                oldPrice: `${currencySymbol}${formatNumber(cachePrice)}`,
                cachePriced: formatNumber(dropValue),
                dropValue
            };
        }
        return null;
    }

    static isHostMode() {
        return (typeof location !== 'undefined') && Boolean(new URLSearchParams(location.search).get('host_id')?.trim());
    }

    static getGeolocationString(arrayOfStrings, joinToken) {
        return arrayOfStrings.map(str => str?.trim())
            .filter(Boolean)
            .join(joinToken);
    }

    static validateCoords(coordsEntity, outputDataAs = {}) {
        const coordsEntries = Object.entries(coordsEntity);
        const validatedCoords = coordsEntries
            .reduce((acc, [key, value]) => {
                if (Number.isNaN(Number.parseFloat(value))) {
                    return acc;
                }

                return Array.isArray(outputDataAs)
                    ? [
                        ...acc,
                        [key, Number(value)]
                    ]
                    : {
                        ...acc,
                        [key]: Number(value)
                    };
            }, outputDataAs);

        const validateCoordsLength = validatedCoords.length ?? Object.entries(validatedCoords).length;

        if (validateCoordsLength !== coordsEntries.length) {
            return false;
        }

        return validatedCoords;
    }
}
