import { CHILD_AGE_UPDATE } from '../actions/childAgeAction';

const initialState = {
  data:null,
};

const childAgeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHILD_AGE_UPDATE:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
};

export default childAgeReducer;