import { DEFAULT_ROUTE } from "../actions/dashboardRoutesActions";

export const dashboardRoutesReducer = (state = {
	dashboardSwitcher : DEFAULT_ROUTE
}, action ) => {
	if (['DASHBOARD_ROUTES'].includes(action.type)) {
		return {
			...state,
			dashboardRoutes: action.data
		};
	}
	
	if(['DASHBOARD_SWITCHER'].includes(action.type)) {
		return {
			...state,
			dashboardSwitcher: action.data
		};
	}

	if(['HOST_SELECTED_DATE'].includes(action.type)) {
		return {
			...state,
			hostSelectedDate: action.data
		};
	}
	return state;
}
