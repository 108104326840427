export default class WishLists {
    constructor({
        product_title,
        product_id,
        property_type,
        lat,
        lng,
        product_image = [],
        product_images_count,
        wishlists,
        price,
        avg_primary_rating,
        featured_amenities,
        url,
        distance,
        star_rating,
    }) {
        this.amenities = featured_amenities;
        this.lat = lat;
        this.lon = lng;
        this.propertyName = product_title;
        this.propertyType = property_type,
        this.images = [product_image];
        this.wishlists = wishlists;
        this.id = product_id;
        this.product_images_count = product_images_count;
        this.price = `${price?.currency_symbol ?? '$'}${price?.amount ? Math.round(price.amount) : 'N/A'}`;
        this.avg_primary_rating = avg_primary_rating;
        this.url = url;
        this.distance = distance instanceof Object ? `${distance.value} ${distance.unit.toLowerCase()}` : '';
        this.rating = star_rating;
    }
}