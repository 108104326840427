import { useState } from "react";

export default function useMobileMenu() {
    const [isOpen, setOpen] = useState(false);

    const open = () => setOpen(true);

    const close = () => setOpen(false);

    const toggle = () => setOpen(state => !state);

    return {
        isOpen,
        setOpen,
        open,
        close,
        toggle
    };
};
