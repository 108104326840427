import ActivityInvariantException from "./ActivityInvariantException";

export default class InvalidDateRangeSelectionCriteriaException extends ActivityInvariantException {
    name = 'InvalidDateRangeSelectionCriteriaException';
    context = {};

    constructor(message, context = {}) {
        super(message);
        this.context = context;
    }
}
