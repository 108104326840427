import ActivityInvariantException from "./exceptions/ActivityInvariantException";

export default class SearchCriteriaEntity {
    validate() {
        return false;
    }

    getValidationError() {
        if (!this.validate()) {
            throw new ActivityInvariantException('Invalid search criteria', this);
        }

        return null;
    }
}
