import { useState, useEffect, useRef, memo, useCallback, forwardRef } from 'react';
import styles from './OnboardingTooltip.module.scss';
import CloseSVG from './CloseSVG';
import { useDispatch, useSelector } from 'react-redux';
import { removeMsgOnboard } from '@/redux/actions/onboardingActions';
import { isElemInViewport } from "@/src/utils/globalFunc";
import { StyledPopper, StyledPopperArrow } from './StyledPopper';


const checkVisibility = elem => (isElemInViewport(elem) && Boolean(elem.offsetParent));


const OnboardingTooltipMui = ({ }, popperRef) => {
    const dispatch = useDispatch();
    const messages = useSelector(state => state.onboarding.data);
    const { isDesktop } = useSelector(state => state.device);
    const isSearchResultFocused = useSelector(state => state.searchResults.isSearchResultFocused);
    const [arrowRef, setArrowRef] = useState(null);
    const [displayedMsg, setDisplayedMsg] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const closeTimeoutID = useRef(null);
    const waitingTimeoutID = useRef(null);

    const wait = useCallback((ms = 2000) => {
        setWaiting(true);
        waitingTimeoutID.current = setTimeout(() => {
            setWaiting(false);
        }, ms);
    }, [setWaiting]);

    const handleCloseClick = useCallback(() => {
        // clearTimeout(closeTimeoutID.current);
        wait();
        dispatch(removeMsgOnboard(displayedMsg.name));
        setDisplayedMsg(null);
    }, [wait, setDisplayedMsg, displayedMsg]);

    useEffect(() => {
        if (!messages || waiting) return;

        if (!displayedMsg) {
            const msg = messages.find(item => item.visible);
            if (!msg) {
                return;
            }
            const anchor = document.querySelector(`*[data-onboarding=${msg.name}]`);
            const container = document.querySelector(msg.container);
            if (anchor) {
                setDisplayedMsg({
                    name: msg.name,
                    content: msg.content,
                    placement: msg.placement,
                    anchor,
                    container,
                });
                // closeTimeoutID.current = setTimeout(() => {
                //     wait();
                //     dispatch(removeMsgOnboard(msg.name));
                //     setDisplayedMsg(null);
                // }, 6000);
            }
        } else {
            const isDisplayedMsgVisible = messages
                .find(item => item.name === displayedMsg.name)?.visible;

            if (!isDisplayedMsgVisible) {
                // logic for displayed message when it is no longer visible
                // clearTimeout(closeTimeoutID.current);
                wait();
                // dispatch(removeMsgOnboard(displayedMsg.name));
                setDisplayedMsg(null);
            }
        }
    }, [messages, waiting, displayedMsg]);

    useEffect(() => {
        return () => {
            // clearTimeout(closeTimeoutID.current);
            clearTimeout(waitingTimeoutID.current);
        };
    }, []);

    if (!displayedMsg || !checkVisibility(displayedMsg.anchor)) {
        return null;
    }

    return (
        <StyledPopper
            popperRef={popperRef}
            id={'onboarding-popper'}
            open={!!displayedMsg}
            // arrow={true}
            color={'var(--color-dark-purple)'}
            anchorEl={displayedMsg.anchor}
            container={displayedMsg.container}
            placement={displayedMsg.placement}
            // strategy={'fixed'} <-- dis shit not working
            sx={{
                maxWidth: '218px',
                borderRadius: '9px',
                padding: '12px 20px',
                backgroundColor: 'var(--color-dark-purple)',
                animation: `appear 0.3s ease-in-out forwards`,
                zIndex: isSearchResultFocused && isDesktop ?  1 : isDesktop ? 10 : 1,
            }}
            modifiers={[
                {
                    name: 'arrow',
                    enabled: true,
                    options: {
                        element: arrowRef,
                    },
                },
                {
                    name: 'offset',
                    options: {
                        offset: [0, 14],
                    },
                },
                {
                    name: 'flip',
                    enabled: false,
                },
            ]}
        >
            <StyledPopperArrow ref={setArrowRef} className="MuiPopper-arrow" />
            <span className={styles.close} onClick={handleCloseClick}>
                <CloseSVG />
            </span>
            <div className={styles.content}>
                {displayedMsg.content}
            </div>
        </StyledPopper>
    );
};

export default memo(forwardRef(OnboardingTooltipMui));
