import { useRef, useEffect } from "react";
import { shallowEqual, useSelector, useStore } from "react-redux";

export const AuthenticationPromptType = {
    Login: 'login',
    Signup: 'signup',
    ForgotPassword: 'forgot'
};

export default function useAuth({
    getAuthCssSelector = (promptType = AuthenticationPromptType.Login) =>
        `.js-${promptType}-prompt-trigger`
} = {}) {
    const store = useStore();

    const auth = useSelector(state => state.authReducer, shallowEqual);
    const isAuth = Boolean(auth.data);
    const watchlistResolverRef = useRef(null);

    const triggerAuthPrompt = promptType =>
        document.querySelector(getAuthCssSelector(promptType))?.click();

    const handleAuthAwareAction = async delegateData => {
        await new Promise(resolve => {
            if (isAuth) {
                resolve();
                return;
            }

            watchlistResolverRef.current = resolve;
            triggerAuthPrompt();
        });

        return delegateData;
    };

    const getAuthUserData = () => (
        store.getState().authReducer.data
    );

    const getAuthUserId = () => getAuthUserData()?._id;

    useEffect(() => {
        if (isAuth && watchlistResolverRef.current) {
            watchlistResolverRef.current();
        }

        watchlistResolverRef.current = null;
    }, [isAuth]);

    return {
        auth,
        getAuthUserId,
        getAuthUserData,
        handleAuthAwareAction,
        triggerAuthPrompt
    };
};
