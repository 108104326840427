import { config } from "@/utils/config";
import request from "../../../../redux/services/requestToken";

const defaultOptions = {
    dev3: true
};


export const getUserProfile = (_, cancelToken) =>
    request({
        ...defaultOptions,
        // url: `${config.api_ver}/profile/${userId}`,
        // here 'baseURL' is for testing only
        // baseURL: 'https://staging.cuddlynest.com/api',
        url: `${config.api_ver}/profile`,
        method: 'GET',
        cancelToken
    });


export const getUnreadCounts = (_, cancelToken) =>
    request({
        ...defaultOptions,
        url: `${config.api_ver}/unread_counts`,
        method: 'GET',
        cancelToken
    });

export const updateUserProfilePicture = (data, cancelToken) =>
    request({
        ...defaultOptions,
        url: `${config.api_ver}/profile/upload_photo`,
        method: 'POST',
        data,
        cancelToken
    });

export const updateUserProfile = (data, cancelToken) =>
    request({
        ...defaultOptions,
        url: `${config.api_ver}/profile/update`,
        method: 'POST',
        data,
        cancelToken
    });

export const addUserPhoneNumber = (data, cancelToken) =>
    request({
        ...defaultOptions,
        url: `${config.api_ver}/phone_number/add`,
        method: 'POST',
        data,
        cancelToken
    });

export const updateUserPhoneNumber = (data, cancelToken) =>
    request({
        ...defaultOptions,
        url: `${config.api_ver}/phone_number/update`,
        method: 'POST',
        data,
        cancelToken
    });


export const sendUserVerificationCodeToEmail = (data, cancelToken) => {
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/sendverificationemail`,
        method: 'POST',
        data,
        cancelToken
    });
}

export const sendUserVerificationCodeToPhone = (data, cancelToken) => {
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/resendverifycode`,
        method: 'POST',
        data: {
            type: 'phone_verifcation',
            ...data
        },
        cancelToken
    });
}

export const submitUserVerificationCodeForPhone = (data, cancelToken) =>{
    return request({
        ...defaultOptions,
        url: `${config.api_ver}/verifycode`,
        method: 'POST',
        data,
        cancelToken
    });
}