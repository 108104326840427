import {
    getUserProfile as getUserProfileRequest,
    updateUserProfilePicture as updateUserProfilePictureRequest,
    updateUserProfile as updateUserProfileRequest,
    updateUserPhoneNumber as updateUserPhoneNumberRequest,
    addUserPhoneNumber as addUserPhoneNumberRequest,
    sendUserVerificationCodeToPhone as sendUserVerificationCodeToPhoneRequest,
    sendUserVerificationCodeToEmail as sendUserVerificationCodeToEmailRequest,
    submitUserVerificationCodeForPhone as submitUserVerificationCodeForPhoneRequest,
    getUnreadCounts
} from '@/containers/Dashboard/redux/services/dashboardProfileServices';

import {
    DASHBOARD_UPDATE_PROFILE,
    DASHBOARD_UPDATE_PROFILE_PICTURE,
    DASHBOARD_USER_PROFILE,
    DASHBOARD_PROFILE_VERIFICATION,
    DASHBOARD_PROFILE_VERIFICATION_SUBMIT,
    DASHBOARD_USER_UNREADCOUNTS,
    DASHBOARD_USER_UNREADCOUNTS_TOZERO
} from "../types/dashboardTypes";

import responseContainer from './utils/responseController';
const {failed, successor} = responseContainer;


export const getUnreadCountActions = async (dispatch, requestParams) => {
    const META = {
        pending: true,
        failed: false,
        message: "Pending...",
        payload: {
            data: null,
            error: null,
        },
    };
    
    dispatch({ type: DASHBOARD_USER_UNREADCOUNTS, data: META });
    return getUnreadCounts(requestParams).then(async (response) => {
        if(response.statusCode === 200) {
            return dispatch({ type: DASHBOARD_USER_UNREADCOUNTS, data: successor(response) });
        }
        return dispatch({ type: DASHBOARD_USER_UNREADCOUNTS, data: failed(response) });
    })
    .catch(e => {
        return dispatch({ type: DASHBOARD_USER_UNREADCOUNTS, data: failed(e) });
    })
}

export const getUnreadCountToZeroActions = async (dispatch, requestParams) => {
    const META = {
        pending: true,
        failed: false,
        message: "Pending...",
        payload: {
            data: null,
            error: null,
        },
    };
    
    dispatch({ type: DASHBOARD_USER_UNREADCOUNTS_TOZERO, data: META });
    return getUnreadCounts(requestParams).then(async (response) => {
        if(response.statusCode === 200) {
            return dispatch({ type: DASHBOARD_USER_UNREADCOUNTS_TOZERO, data: successor(response) });
        }
        return dispatch({ type: DASHBOARD_USER_UNREADCOUNTS_TOZERO, data: failed(response) });
    })
    .catch(e => {
        return dispatch({ type: DASHBOARD_USER_UNREADCOUNTS_TOZERO, data: failed(e) });
    })
}




export const getUserProfile = async (dispatch, requestParams) => {
    const META = {
        pending: true,
        failed: false,
        message: "Pending...",
        payload: {
            data: null,
            error: null,
        },
    };
    
    dispatch({ type: DASHBOARD_USER_PROFILE, data: META });
    return getUserProfileRequest(requestParams).then(async (response) => {
        if(response.statusCode === 200) {
            return dispatch({ type: DASHBOARD_USER_PROFILE, data: successor(response) });
        }
        return dispatch({ type: DASHBOARD_USER_PROFILE, data: failed(response) });
    })
    .catch(e => {
        return dispatch({ type: DASHBOARD_USER_PROFILE, data: failed(e) });
    })
}



export const updateUserProfile = async (dispatch, requestParams) => {
    const META = {
        pending: true,
        failed: false,
        message: "Pending...",
        payload: {
            data: null,
            error: null,
        },
    };
    
    dispatch({ type: DASHBOARD_UPDATE_PROFILE, data: META });
    return updateUserProfileRequest(requestParams).then(response => {
        if(response.statusCode === 200) {
            return dispatch({ type: DASHBOARD_UPDATE_PROFILE, data: successor(response) });
        }
        return dispatch({ type: DASHBOARD_UPDATE_PROFILE, data: failed(response) });
    })
    .catch(e => {
        return dispatch({ type: DASHBOARD_UPDATE_PROFILE, data: failed(e) });
    })
}



export const updateUserProfileImage = async (dispatch, requestParams) => {
    const META = {
        pending: true,
        failed: false,
        message: "Pending...",
        payload: {
            data: null,
            error: null,
        },
    };
    
    dispatch({ type: DASHBOARD_UPDATE_PROFILE_PICTURE, data: META });
    return updateUserProfilePictureRequest(requestParams).then(response => {
        if(response.statusCode === 200) {
            return dispatch({ type: DASHBOARD_UPDATE_PROFILE_PICTURE, data: successor(response) });
        }
        return dispatch({ type: DASHBOARD_UPDATE_PROFILE_PICTURE, data: failed(response) });
    })
    .catch(e => {
        return dispatch({ type: DASHBOARD_UPDATE_PROFILE_PICTURE, data: failed(e) });
    })
}


export const sendUserVerificationCodeToPhoneAction = async (dispatch, requestParams) => 
{
    const META = {
        pending: true,
        failed: false,
        message: "Pending...",
        payload: {
            data: null,
            error: null,
        },
    };
    
    dispatch({ type: DASHBOARD_PROFILE_VERIFICATION, data: META });
    return sendUserVerificationCodeToPhoneRequest(requestParams).then(response => {
        if(response.statusCode === 200) {
            return dispatch({ type: DASHBOARD_PROFILE_VERIFICATION, data: successor(response) });
        }
        return dispatch({ type: DASHBOARD_PROFILE_VERIFICATION, data: failed(response) });
    })
    .catch(e => {
        return dispatch({ type: DASHBOARD_PROFILE_VERIFICATION, data: failed(e) });
    })
}
export const submitUserVerificationCodeOfPhoneAction = async (dispatch, requestParams) => 
{
    const META = {
        pending: true,
        failed: false,
        message: "Pending...",
        payload: {
            data: null,
            error: null,
        },
    };
    
    dispatch({ type: DASHBOARD_PROFILE_VERIFICATION_SUBMIT, data: META });
    return submitUserVerificationCodeForPhoneRequest(requestParams).then(response => {
        if(response.statusCode === 200) {
            return dispatch({ type: DASHBOARD_PROFILE_VERIFICATION_SUBMIT, data: successor(response) });
        }
        return dispatch({ type: DASHBOARD_PROFILE_VERIFICATION_SUBMIT, data: failed(response) });
    })
    .catch(e => {
        return dispatch({ type: DASHBOARD_PROFILE_VERIFICATION_SUBMIT, data: failed(e) });
    })
}


export const sendUserVerificationCodeToEmailAction = async (dispatch, requestParams) => 
{
    const META = {
        pending: true,
        failed: false,
        message: "Pending...",
        payload: {
            data: null,
            error: null,
        },
    };
    
    dispatch({ type: DASHBOARD_PROFILE_VERIFICATION, data: META });
    return sendUserVerificationCodeToEmailRequest(requestParams).then(response => {
        if(response.statusCode === 200) {
            return dispatch({ type: DASHBOARD_PROFILE_VERIFICATION, data: successor(response) });
        }
        return dispatch({ type: DASHBOARD_PROFILE_VERIFICATION, data: failed(response) });
    })
    .catch(e => {
        return dispatch({ type: DASHBOARD_PROFILE_VERIFICATION, data: failed(e) });
    })
}




