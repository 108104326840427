import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    isMobile,
    isTablet,
    isDesktop,
    getScreenWidth
} from "@/utils/commonScripts/commonMethods"
import { DEVICE_UPDATE } from '@/redux/types';
import debounce from 'lodash.debounce';

const MountResizeListener = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const resizeHandler = debounce(() => {
            const update = {
                isMobile: isMobile(),
                isTablet: isTablet(),
                isDesktop: isDesktop(),
                screenWidth: getScreenWidth(),
            };
            dispatch({ type: DEVICE_UPDATE, data: update });
        }, 400, { trailing: true });

        resizeHandler();
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, []);

    return (
        <>{children}</>
    );
};

export { MountResizeListener };
