import { createContext, memo } from "react";
import useMobileMenu from "./useMobileMenu";

export const MobileMenuContext = createContext(null);

const MobileMenuProvider = ({ children }) => (
    <MobileMenuContext.Provider
        value={useMobileMenu()}
    >
        {children}
    </MobileMenuContext.Provider>
);

export default memo(MobileMenuProvider);
