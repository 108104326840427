import request from "./request"
import requestToken from "./requestToken"
import { config } from "@/src/utils/config"

const api = 'api/v1'
export const login = (data) => {
    return request({
        url: `/${config.api_ver}/login`,
        method: 'POST',
        data,
        dev3: true,
    })
}

export const logout = () => {
    return requestToken({
        url: `/${config.api_ver}/logout`,
        method: 'POST',
        dev3: true,
    })
}

export const signup = (data) => {
    return request({
        url: `/${config.api_ver}/signup`,
        method: 'POST',
        data,
        dev3: true,
    })
}

export const refreshToken = () => {
    return requestToken({
        url: `/${config.api_ver}/refreshToken`,
        method: 'POST',
        dev3: true,
        tokenType: 'refreshToken'
    })
}

export const forgetPassword = (data) => {
    return requestToken({
        url: `/${config.api_ver}/forgetPassword`,
        method: 'POST',
        data
    })
}

export const fbSignUpIn = (data) => {
    return request({
        url: `/${config.api_ver}/signup/fb`,
        method: 'POST',
        data,
        dev3: true,
    })
}

export const googleSignUpIn = (data) => {
    return request({
        url: `/${config.api_ver}/signup/google`,
        method: 'POST',
        data,
        dev3: true,
    })
}

export const appleSignUpIn = (data) => {
    return request({
        url: `/${config.api_ver}/signup/apple`,
        method: 'POST',
        data,
        dev3: true,
    })
}

export const verifyCode = data => {
    return request({
        url: `/${config.api_ver}/verifycode`,
        method: 'POST',
        data,
        dev3: true,
    });
};

export const resendVerifyCode = data => {
    return request({
        url: `/${config.api_ver}/resendverifycode`,
        method: 'POST',
        data,
        dev3: true,
    });
};

export const forgotPassword = data => {
    return request({
        url: `/${config.api_ver}/forgetPassword`,
        method: 'POST',
        data,
        dev3: true,
    });
};

export const resetPasswordCheck = data => {
    return request({
        url: `/${config.api_ver}/reset-password-check`,
        method: 'POST',
        data,
        dev3: true,
    });
}

export const resetPassword = data => {
    return request({
        url: `/${config.api_ver}/reset-password`,
        method: 'POST',
        data,
        dev3: true,
    });
}
