import * as types from "../types";

const initialState = {
    type: null,
    data: null,
    message: null,
	statusCode: null,
    user_id: null,
    payload: null
}

export const authReducer = (state = initialState, { type, data = state.data, message = state.message, statusCode, user_id = state.user_id, payload = state.payload }) => {
	if ([
		// direct setAuthData
		types.SET_AUTH_DATA,
		// signup actions
		types.SIGNUP_LOAD,
		types.SIGNUP_FAIL,
		types.SIGNUP_SUCCESS,
		// login actions
		types.LOGIN_LOAD,
		types.LOGIN_FAIL,
		types.LOGIN_SUCCESS,
		// logout actions
		types.LOGOUT_LOAD,
		types.LOGOUT_FAIL,
		types.LOGOUT_SUCCESS,
		// OAuth Google Actions
		types.GOOGLE_SIGNUP_SIGNIN_LOAD,
		types.GOOGLE_SIGNUP_SIGNIN_FAIL,
		types.GOOGLE_SIGNUP_SIGNIN_SUCCESS,
		// OAuth Facebook Actions
		types.FB_SIGNUP_SIGNIN_LOAD,
		types.FB_SIGNUP_SIGNIN_FAIL,
		types.FB_SIGNUP_SIGNIN_SUCCESS,
		// OAuth Apple actions
		types.APPLE_SIGNUP_SIGNIN_LOAD,
		types.APPLE_SIGNUP_SIGNIN_FAIL,
		types.APPLE_SIGNUP_SIGNIN_SUCCESS,
		// Refresh actions
		types.REFRESH_TOKEN_LOAD,
		types.REFRESH_TOKEN_FAIL,
		types.REFRESH_TOKEN_SUCCESS,
		// Verify phone code
		types.VERIFY_CODE_FAIL,
		types.VERIFY_CODE_LOAD,
		types.VERIFY_CODE_SUCCESS,
		// Resend verify phone code
		types.RESEND_VERIFY_CODE_FAIL,
		types.RESEND_VERIFY_CODE_LOAD,
		types.RESEND_VERIFY_CODE_SUCCESS,
		// Forgot password
		types.FORGOT_PASSWORD_FAIL,
		types.FORGOT_PASSWORD_LOAD,
		types.FORGOT_PASSWORD_SUCCESS,
	].includes(type)) {
		return {
			...state,
			data,
			type: type === types.SET_AUTH_DATA ? null : type,
			message,
			statusCode,
			user_id,
			payload
		};
	}else if(type === types.RESET_DATA){
		return initialState;
	}
	return state;
}
