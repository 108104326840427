import { memo } from "react";

const PaymentMethodsProgressive = ({ className }) => (
    <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none">
        <mask id="payment_methods_progressive" fill="white">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 6C5.84315 6 4.5 7.34315 4.5 9V10H20.5V9C20.5 7.34315 19.1569 6 17.5 6H7.5ZM20.5 12H4.5V15C4.5 16.6569 5.84315 18 7.5 18H17.5C19.1569 18 20.5 16.6569 20.5 15V12Z" />
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 6C5.84315 6 4.5 7.34315 4.5 9V10H20.5V9C20.5 7.34315 19.1569 6 17.5 6H7.5ZM20.5 12H4.5V15C4.5 16.6569 5.84315 18 7.5 18H17.5C19.1569 18 20.5 16.6569 20.5 15V12Z" fill="#64748B" />
        <path d="M4.5 10H2.5V12H4.5V10ZM20.5 10V12H22.5V10H20.5ZM20.5 12H22.5V10H20.5V12ZM4.5 12V10H2.5V12H4.5ZM6.5 9C6.5 8.44772 6.94772 8 7.5 8V4C4.73858 4 2.5 6.23858 2.5 9H6.5ZM6.5 10V9H2.5V10H6.5ZM4.5 12H20.5V8H4.5V12ZM18.5 9V10H22.5V9H18.5ZM17.5 8C18.0523 8 18.5 8.44772 18.5 9H22.5C22.5 6.23858 20.2614 4 17.5 4V8ZM7.5 8H17.5V4H7.5V8ZM20.5 10H4.5V14H20.5V10ZM6.5 15V12H2.5V15H6.5ZM7.5 16C6.94772 16 6.5 15.5523 6.5 15H2.5C2.5 17.7614 4.73858 20 7.5 20V16ZM17.5 16H7.5V20H17.5V16ZM18.5 15C18.5 15.5523 18.0523 16 17.5 16V20C20.2614 20 22.5 17.7614 22.5 15H18.5ZM18.5 12V15H22.5V12H18.5Z" fill="#64748B" mask="url(#payment_methods_progressive)" />
    </svg>
);

export default memo(PaymentMethodsProgressive);
