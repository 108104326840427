import { getListings } from '@/redux/services/promoDeals';

export const INIT_PROMO_DEALS_LIST = "INIT_PROMO_DEALS_LIST";

//Initialize lists
export const getLists = (data) => {
    return { type: INIT_PROMO_DEALS_LIST, data: data };
}
export const initLists = endpointUrl => {
    return async dispatch => {
        const listsAPI = await getListings(endpointUrl);
        dispatch(getLists(listsAPI));
    }
};