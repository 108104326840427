export default {
    INITIAL: 'INITIAL',
    LOCATION: 'LOCATION',
    DATES: 'DATES',
    GUESTS: 'GUESTS',
    INTERESTS: 'INTERESTS'
};

export const MOBILE_THRESHOLD = 763;

export const VIEWPORT_TYPE = {
    MOBILE: 'MOBILE',
    DESKTOP: 'DESKTOP'
};

export const VIEWPORT_MAP = new Map([
    [VIEWPORT_TYPE.MOBILE, {
        name: VIEWPORT_TYPE.MOBILE,
        rule: () => window.innerWidth <= MOBILE_THRESHOLD
    }],
    [VIEWPORT_TYPE.DESKTOP, {
        name: VIEWPORT_TYPE.DESKTOP,
        rule: () => window.innerWidth > MOBILE_THRESHOLD
    }]
]);

export const Entities = {
    Destination: 'Destination',
    DateRange: 'DateRange',
    GuestRoom: 'GuestRoom'
};

export const ActivityErrorTypes = {
    ActivitesNotAvailable: 'ActivitesNotAvailable',
    ActivitiesOutsideSelectedRange: 'ActivitiesOutsideSelectedRange'
};
