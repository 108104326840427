import { useRouter } from "next/router";
import { useEffect } from "react";

export default function useRouteEventSubscriptions(...args) {
    const router = useRouter();

    useEffect(() => {
        const handleRouterEvents = method => {
            args.forEach(({ eventName, eventHandler }) => {
                router.events[method](eventName, eventHandler);
            });
        };

        handleRouterEvents('on');
        return () => {
            handleRouterEvents('off');
        };
    }, [args, router.events]);
};
