
const failed = (e) => {
  return {
    pending: false,
    failed: true,
    message: e.message || "Failed...",
    payload: {
      data: e.data || null,
      error: e.message || e.error || "Something Went Wrong",
    },
  };
};

const successor = (response) => {
  return {
    pending: false,
    failed: false,
    message: response.message || "Success...",
    payload: {
      data: response.data || response, 
      error: response.error || null,
    },
  };
};


const responseContainer = {
  failed,
  successor,
};
export default responseContainer;
