import { constants } from "@/src/utils/constants";
import * as types from "../types";

const initialState = {
    type: null,
    data: null,
	message: null,
	status: false,
	cards: [],
	selectedCard: constants.checkout_new_card,
	guestData: {},
	is3dsIFrameOpen: false
}

export const getCheckoutInitialData = (state = initialState, {data, type, message}) => {
    switch (type) {
		case types.CHECKOUT_INITIAL_DATA_SUCCESS : {
			return {
				...state,
				data,
                type,
                message
			};
        }
        case types.CHECKOUT_INITIAL_DATA_LOAD : {
			return {
				...state,
				data,
                type,
                message
			};
        }
        case types.CHECKOUT_INITIAL_DATA_ERR : {
			return {
				...state,
				data,
				type,
                message
			};
		}
        default:
			return state;
	}
}

export const checkout3dsIFrameStatus = (state = initialState, {is3dsIFrameOpen, type}) => {
	switch(type) {
		case types._3DS_POPUP_STATUS : {
			return {
				...state,
				is3dsIFrameOpen
			}
		}
		default:
			return state;
	}
}
export const getCheckoutSideWidget = (state = initialState, {data, type, message}) => {
    switch (type) {
		case types.GET_CHECKOUT_SIDE_WIDGET_SUCCESS : {
			return {
				...state,
				data,
                type,
                message
			};
        }
        case types.GET_CHECKOUT_SIDE_WIDGET_LOAD : {
			return {
				...state,
				data,
                type,
                message
			};
        }
        case types.GET_CHECKOUT_SIDE_WIDGET_ERR : {
			return {
				...state,
				data,
				type,
                message
			};
		}
        default:
			return state;
	}
}

export const getCheckoutCards = (state = initialState, {data, type, message}) => {
    switch (type) {
		case types.GET_CHECKOUT_CARDS_SUCCESS :{
			return {
				...state,
				data,
                type,
                message
			};
        }
		case types.GET_CHECKOUT_CARDS_LOAD : {
			return {
				...state,
				data,
                type,
                message
			};
        }
		case types.GET_CHECKOUT_CARDS_ERR : {
			return {
				...state,
				data,
                type,
                message
			};
        }
        default:
			return state;
	}
}

export const createCheckoutCreditCard = (state = initialState, {data, type, message}) => {
    switch (type) {
		case types.CREATE_CHECKOUT_CARD_LOAD :{

			return {
				...state,
				data,
                type,
                message
			};
        }
		case types.CREATE_CHECKOUT_CARD_ERR :{

			return {
				...state,
				data,
                type,
                message
			};
        }
		case types.CREATE_CHECKOUT_CARD_SUCCESS: {

			return {
				...state,
				data,
                type,
                message
			};
        }
        default:
			return state;
	}
}

export const getCheckoutGuestDetail = (state = initialState, {data, type, message}) => {
    switch (type) {
		case types.GET_CHECKOUT_GUEST_DETAIL_SUCCESS :{
			return {
				...state,
				data,
                type,
                message
			};
        }
		case types.GET_CHECKOUT_GUEST_DETAIL_LOAD :{
			return {
				...state,
				data,
                type,
                message
			};
        }
		case types.GET_CHECKOUT_GUEST_DETAIL_ERR : {
			return {
				...state,
				data,
                type,
                message
			};
        }
        default:
			return state;
	}
}

export const setCheckoutData = (state = initialState, {cards, selectedCard, guestData, type}) => {
    switch (type) {
		case types.CHECKOUT_SET_CARDS :{
			return {
				...state,
				cards: cards?? state.cards,
				selectedCard: selectedCard?? state.selectedCard
			};
        }
		case types.CHECKOUT_SET_SELECTED_CARD :{
			return {
				...state,
				cards: cards?? state.cards,
				selectedCard: selectedCard?? state.selectedCard
			};
        }
		case types.CHECKOUT_GUEST_DATA : {
			return {
				...state,
				guestData
			};
        }
        default:
			return state;
	}
}

export const checkoutConfirmBooking = (state = initialState, {data, type, message}) => {
    switch (type) {
		case types.CHECKOUT_CONFIRM_BOOKING_SUCCESS :{
			return {
				...state,
				data,
                type,
                message
			};
        }
		case types.CHECKOUT_CONFIRM_BOOKING_LOAD :{
			return {
				...state,
				data,
                type,
                message
			};
        }
		case types.CHECKOUT_CONFIRM_BOOKING_ERR : {
			return {
				...state,
				data,
                type,
                message
			};
        }
        default:
			return state;
	}
}