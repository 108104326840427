import React, { memo, useCallback, useState } from "react";
import moment from "moment";
import Cookies from "js-cookie";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from "@material-ui/lab/Alert";

const Notificator = ({
  displayTillUTC,
  rootOptions,
  innerOptions,
  message,
  closable,
  cookieKey,
  cookieValue,
  cookieOptions,
  children
}) => {
  const [isOpen, setOpen] = useState(() => {
    if (typeof rootOptions.open === 'boolean') {
      return rootOptions.open;
    }
    if (!cookieKey) {
      return true;
    }
    return moment()
      .utc()
      .isBefore(moment(displayTillUTC)) && !Cookies.get(cookieKey);
  });

  const handleClose = useCallback((...arg) => {
    if (cookieKey) {
      Cookies.set(cookieKey, cookieValue, cookieOptions);
    }
    setOpen(false);
    rootOptions.onClose?.(...arg);
  }, [cookieKey, rootOptions.onClose]);

  return (
    <Snackbar
      onClose={handleClose}
      open={isOpen}
      {...rootOptions}
    >
      {children || (
        <MuiAlert
          {...innerOptions}
          action={
            closable ? (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null
          }
        >
          {message}
        </MuiAlert>
      )}
    </Snackbar>
  );
};

Notificator.defaultProps = {
  message: '',
  rootOptions: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    autoHideDuration: 10000
  },
  innerOptions: {
    severity: 'warning',
    variant: 'filled'
  },
  cookieOptions: {
    expires: 1,
    secure: true
  },
  cookieValue: true,
  closable: true,
  cookieKey: 'cn-maintenance'
};

export default memo(Notificator);
