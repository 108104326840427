import { ValidationException } from "@/exceptions/ValidationException";

export default class ActivityInvariantException extends ValidationException {
    name = 'ActivityInvariantException';
    context = {};

    constructor(message, context = {}) {
        super(message);
        this.context = context;
    }
}
