import {isObject, isValidJson} from "@/utils/globalFunc";
import { constants } from '@/src/utils/constants';


export const phoneNumberRe = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const _checkValidStatus = data => {
    return [200, 201].includes(data?.statusCode);
}

export const _validateEmail = email => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

export const _validatePassword = password => {
  return (!!password && password.length > 3)
}

export const _setFocus = id => {
  const elem = document.getElementById(id);
  if(!!elem){
      elem.focus();
  }
}
export const _clearInput = id => {
  const elem = document.getElementById(id);
  if(!!elem){
      elem.value = '';
      elem.blur();
  }
}

export const checkCurrencyLocalStorageValidity = () => {

  if(!isValidJson(localStorage.getItem('currency'))) return false;

  let currency = JSON.parse(localStorage.getItem('currency'))

  if(isObject(currency) && currency.short && currency.long && currency.symbol && currency.id )
  {
    return currency;
  }
  return false;

}

// Guests Input Validation
const guestsValidationSchema = {
  [constants.guestTypes.ADULT]: {
      MIN: constants.guestInput[constants.guestTypes.ADULT]['MIN'],
      MAX: constants.guestInput[constants.guestTypes.ADULT]['MAX']
  },
  [constants.guestTypes.CHILD]: {
      MIN: constants.guestInput[constants.guestTypes.CHILD]['MIN'],
      MAX: constants.guestInput[constants.guestTypes.CHILD]['MAX']
  },
  [constants.guestTypes.INFANT]: {
      MIN: constants.guestInput[constants.guestTypes.INFANT]['MIN'],
      MAX: constants.guestInput[constants.guestTypes.INFANT]['MAX']
  },
  [constants.guestTypes.ROOM]: {
      MIN: constants.guestInput[constants.guestTypes.ROOM]['MIN'],
      MAX: constants.guestInput[constants.guestTypes.ROOM]['MAX']
  }
};
export const guestsValidator = ([guestCountType, count]) => {
  const validationRules = guestsValidationSchema[guestCountType];
  return Math.max(Math.min(count, validationRules.MAX), validationRules.MIN);
};
