import MapBase from "@/components/map/MapBase";
import { useRouter } from "next/router";

export default function useGoogleMaps({ dynamicLibImportRoutesRegex }) {
    const { route } = useRouter();

    if (dynamicLibImportRoutesRegex.test(route)) {
        return null;
    }

    // Map requred routes
    const isRouteRequireGoogleMapsScript = () => new RegExp('^/(wishlist|sr|list_space|hotel/|hotels/|listings/|l/|reservation/|vacation-rentals/)')
        .test(route);

    return (
        <MapBase
            async={!isRouteRequireGoogleMapsScript(route)}
        />
    );;
}
