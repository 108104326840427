import request from "./request"
import { config } from "@/src/utils/config"

export const getTripData = () => {
    return request({
        url: `/${config.api_ver}/tripType`,
        method: 'GET'
    })
}

export const getBestPriceCountries = (currency) => {
    return request({
        url: `/${config.api_ver}/bestPriceCountries?currency=${currency}`,
        method: 'GET'
    })
}

export const getFeaturedCities = currency => {
    return request({
        url: `/${config.api_ver}/featuredCities${currency ? `?currency=${currency}` : ''}`,
        method: 'GET'
    })
}
export const getPetFriendly = () => {
    return request({
        url: `/${config.api_ver}/petFriendly`,
        method: 'GET'
    })
}

export const getPropertytypes = () => {
    return request({
        url: `/${config.api_ver}/propertyTypes`,
        method: 'GET'
    })
}

export const getRegions = () => {
    return request({
        url: `/${config.api_ver}/regions`,
        method: 'GET'
    })
}

export const getReviews = () => {
    return request({
        url: `/${config.api_ver}/reviews`,
        method: 'GET'
    })
}

export const getSeoLinks = () => {
    return request({
        url: `/${config.api_ver}/seoLinks`,
        method: 'GET'
    })
}

export const getUserCountry = () => {
    return request({
        url: `/${config.api_ver}/userCountryDestination`,
        method: 'GET'
    })
}

export const getMetaInfo = () => {
    return request({
        url: `/${config.api_ver}/metaInfo`,
        method: 'GET'
    })
}

export const subscribeApi = data => {
    return request({
        url: `${config.api_ver}/subscribe`,
        method: "POST",
        data
    })
}

export const careprogramApi = data => {
    return request({
        url: `${config.api_ver}/care/redemption`,
        method: "POST",
        data
    })
}

export const getCurrencies = async () => {
        return request({
            url: `${config.api_ver}/currencies`,
            method: 'GET'
        })
}

export const getGeoLocation = (data, cancelToken) => {
    return request({
        url: '/location',
        method: 'POST',
        geo: true,
        data,
        cancelToken,
        type: 'text/plain'
    })
}

export const getCurrentLoc = (userLocation) => {
    return request({
        url: `${config.api_ver}/user/location` + `${userLocation ? userLocation : ''}`,
        method: 'GET'
    })
    .catch(() => ({
        data: {
            result: {}
        }
    }));
}

/**
 * Deprecated use getCountryNearbyUserCoordinates
 */
export const getNearLoc = (data) => {
    return request({
        // TODO: FE-865 revert
        url: `/getCountryNearby/${data.country}`,
        // url: `/get_country_near_by`,
        method: 'POST',
        // geo: true,
        baseURL: config.api_web_services_endpoint_url,
        data
    })
}

export const getActivityCategories = (data) =>
    request({
        url: `/get_activity_categories_v2`,
        method: 'POST',
        baseURL: config.api_web_services_endpoint_url,
        data
    });

/**
 * Deprecated use getCityNearbyAttractionsbyUserCoordinates
 */
export const getAttractionsNearby = data =>
    request({
        // TODO: FE-865 revert
        url: `/getCityNearbyAttractions`,
        // url: `/city_nearby_attractions`,
        method: 'POST',
        // geo: true,
        baseURL: config.api_web_services_endpoint_url,
        data
    });

const getByCoordinates = ({
    url,
    method = 'POST',
    baseURL = config.api_web_services_endpoint_url
}) => data => {
    const { city, state, country, latitude: lat, longitude: lon } = data;

    return request({
        url,
        method,
        baseURL,
        data: {
            city: city ?? '',
            state: state ?? '',
            country: country ?? '',
            lat,
            lon
        }
    });
};

export const getCityNearbyAttractionsByUserCoordinates = getByCoordinates({
    url: `/getCityNearbyAttractionsbyUserCoordinates`
});

export const getCountryNearbyUserCoordinates = getByCoordinates({
    url: `/getCountryNearbyUserCoordinates`
});

export const getStaycationByCountry = getByCoordinates({
    url: `/getStaycationByCountryAndCoordinates`,
});

//--------- NEW HOMEPAGE ----------

export const getDealsAllTheWay = () => {
    return request({
        url: `/${config.api_ver}/homepage/deals`,
        method: 'GET'
    })
};

export const getEndOfMessySearches = (data) => {
    return request({
        url: '/messy_search',
        method: 'POST',
        geo: true,
        data
    })
};

export const checkAvailabilityOfMessySearch = data =>
    request({
        baseURL: config.tSocketApiEndpoint,
        url: '/messy_search_check',
        method: 'POST',
        xApiKey: false,
        data
});

//--------- DEALS ----------

export const getBlackFriday = () => {
    return request({
        url: `/${config.api_ver}/blackfriday/listings`,
        method: 'GET'
    })
};

export const getThanksgiving = () => {
    return request({
        url: `/${config.api_ver}/thanksgiving/listings`,
        method: 'GET'
    })
};