import { createContext, memo } from 'react';
import { isServer } from './useEnvironment';

export const EnvironmentContext = createContext(null);

const EnvironmentProvider = ({ children }) => (
    <EnvironmentContext.Provider value={isServer() ? undefined : window.cnEnvironment}>
        {children}
    </EnvironmentContext.Provider>
);

export default memo(EnvironmentProvider);
