import isEqual from 'lodash.isequal';
import { DEVICE_UPDATE } from '../types';

const initialState = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    screenWidth: null,
};

const deviceDetectReducer = (state = initialState, { type, data }) => {
    switch (type) {
        case DEVICE_UPDATE:
            if (!isEqual(state, data)) {
                return data;
            }
            return state;
        default:
            return state;
    }
};

export { deviceDetectReducer };
