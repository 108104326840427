import { useCallback } from "react";
import {
    SEARCH_RESULTS_DATEPICKER_CHANGE,
    SEARCH_RESULTS_FILTERS_BUDGET
} from "@/redux/types";
import useDates from "./useDates";
import { ValidationException } from "@/exceptions/ValidationException";

export class ValidationError extends ValidationException {
    constructor(message) {
        super(message);
        this.name = 'ValidationError';
    }
}

export default function useValidators(asHook = true) {
    const fn = ({ type, value, initialValue }) => {
        switch (type) {
            case SEARCH_RESULTS_FILTERS_BUDGET: {
                return budgetValidator(value, initialValue);
            }
            case SEARCH_RESULTS_DATEPICKER_CHANGE: {
                return datesValidator(value);
            }
            default:
                return value;
        }
    };
    return asHook ? useCallback(fn, []) : fn;
};

function budgetValidator(value, initialValue) {
    let minPrice = value[0];
    if (value[0] < initialValue[0]) {
        minPrice = initialValue[0];
    }
    if (value[0] > initialValue[1]) {
        minPrice = initialValue[1];
    }

    let maxPrice = value[1];
    if (value[1] > initialValue[1]) {
        maxPrice = initialValue[1];
    }
    if (value[1] < initialValue[0]) {
        maxPrice = initialValue[0];
    }

    if (minPrice > maxPrice) {
        [maxPrice, minPrice] = [minPrice, maxPrice];
    }
    return [minPrice, maxPrice];
}

function datesValidator({ startDate, endDate } = {}) {
    const getDates = useDates(false);
    const datesPayload = getDates({ checkin: startDate, checkout: endDate, isVR: true });
    if (Object.values(datesPayload).every(Boolean)) {
        return datesPayload;
    }
    throw new ValidationError('Date invalid');
}

export function searchResultValidator(value = '') {
    let criteria = '';
    const isInvalid = ['Russia', 'Belarus'].some(location => {
        criteria = location;
        return value.includes(location);
    });

    if (isInvalid) {
        throw new ValidationError(`Suck my dick ${criteria}`);
    }

    return value;
}
