import { getCurrency, geoLocationReducer, getCurrentLocReducer, getNearLocReducer } from './globalReducer';
import { setNotifications } from './globalReducer';
import { authReducer } from './authReducer';
import promoDealsReducer from './promoDealsReducer';
import reservationReducer from './reservationReducer';
import searchResultsReducer from './searchResultsReducer';
import wishListReducer from './wishListReducer';
import onboardingReducer from './onboardingReducer';
import dashboardReducer, { STORE_TREE_PATH as dashboardTreePath } from './dashboardReducer';
// import productReducer from './productReducer';
// import newProductReducer from '../../../store/reducers/newProductReducer';
// import themePageReducer from '../../../store/reducers/themePageReducer';
// import {
//     getPopularCities,
//     getTemperature,
//     getPopularVacationRentals,
//     getDescription,
//     getFaq,
//     setGeoMapExpand
// } from './geoPagesReducer';
import { checkoutConfirmBooking, createCheckoutCreditCard, getCheckoutCards, getCheckoutGuestDetail, getCheckoutInitialData, getCheckoutSideWidget, setCheckoutData, checkout3dsIFrameStatus } from './checkoutPageReducer';
import { deviceDetectReducer } from './deviceDetectReducer';
import childAgeReducer from './childAgeReducer';
import VoucherStore from './VoucherStore';
import roomDetailReducer from './roomDetailReducer';
// import commandRegistryReducer from './commandRegistryReducer';

// TODO: since I have added lazy load reducers support remove all this reducers from here
const staticReducers = {
    notifications: setNotifications,
    currencies: getCurrency,
    // countryListing: getListingsCountry,
    // whereListing: getListingsWhere,
    // accessibleListing: getListingsAccessible,
    // urlPath: setClasses,
    authReducer: authReducer,
    // product: productReducer,
    // new_product: newProductReducer,
    // themePage: themePageReducer,
    promoDeals: promoDealsReducer,
    // product: productReducer,
    reservationReducer: reservationReducer,
    searchResults: searchResultsReducer,
    wishList: wishListReducer,
    // popular_cities: getPopularCities,
    // temperature: getTemperature,
    // popular_listings: getPopularVacationRentals,
    // geo_description: getDescription,
    // geo_faqs: getFaq,
    // geo_map_expand: setGeoMapExpand,
    geo_location: geoLocationReducer,
    checkout_side_widget: getCheckoutSideWidget,
    checkout_cards: getCheckoutCards,
    checkout_create_card: createCheckoutCreditCard,
    checkout_guest_detail: getCheckoutGuestDetail,
    checkout_confirm_booking: checkoutConfirmBooking,
    checkout_data: setCheckoutData,
    checkout_initial_data: getCheckoutInitialData,
    checkout_3ds_ifr_stat: checkout3dsIFrameStatus,
    currentLoc: getCurrentLocReducer,
    nearByLoc: getNearLocReducer,
    onboarding: onboardingReducer,
    device: deviceDetectReducer,
    childAge: childAgeReducer,
    voucherStore: VoucherStore,
    roomDetail: roomDetailReducer,
    [dashboardTreePath]: dashboardReducer
    // commandRegistry: commandRegistryReducer
};

export default staticReducers;