import { memo } from "react";

const TermsAndConditionsProgressive = ({ className }) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#terms_and_conditions_progressive)">
            <path d="M13.3125 5H6.60417C5.7175 5 5 5.7175 5 6.60417V15.6458C5 16.5325 5.7175 17.25 6.60417 17.25H10.9092L11.0375 16.5325C11.0958 16.2058 11.2475 15.9142 11.4808 15.675L14.9167 12.245V6.60417C14.9167 5.7175 14.1992 5 13.3125 5V5ZM7.33333 7.33333H9.66667C9.9875 7.33333 10.25 7.59583 10.25 7.91667C10.25 8.2375 9.9875 8.5 9.66667 8.5H7.33333C7.0125 8.5 6.75 8.2375 6.75 7.91667C6.75 7.59583 7.0125 7.33333 7.33333 7.33333ZM10.25 13.1667H7.33333C7.0125 13.1667 6.75 12.9042 6.75 12.5833C6.75 12.2625 7.0125 12 7.33333 12H10.25C10.5708 12 10.8333 12.2625 10.8333 12.5833C10.8333 12.9042 10.5708 13.1667 10.25 13.1667ZM12.5833 10.8333H7.33333C7.0125 10.8333 6.75 10.5708 6.75 10.25C6.75 9.92917 7.0125 9.66667 7.33333 9.66667H12.5833C12.9042 9.66667 13.1667 9.92917 13.1667 10.25C13.1667 10.5708 12.9042 10.8333 12.5833 10.8333Z" fill="#64748B" />
            <path d="M12.3081 18.9993C12.1932 18.9993 12.0812 18.9538 11.999 18.871C11.898 18.7701 11.8525 18.6266 11.8776 18.4854L12.1868 16.7325C12.202 16.6444 12.2451 16.5627 12.3081 16.4992L16.6394 12.1685C17.1714 11.6353 17.694 11.7794 17.9798 12.0653L18.7014 12.7868C19.0998 13.1847 19.0998 13.8322 18.7014 14.2306L14.3702 18.5618C14.3072 18.6254 14.2255 18.668 14.1369 18.6831L12.384 18.9923C12.3589 18.997 12.3332 18.9993 12.3081 18.9993ZM14.061 18.2526H14.0669H14.061Z" fill="#64748B" />
        </g>
        <defs>
            <clipPath id="terms_and_conditions_progressive">
                <rect width="14" height="14" fill="white" transform="translate(5 5)" />
            </clipPath>
        </defs>
    </svg>
);

export default memo(TermsAndConditionsProgressive);
