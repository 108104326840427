import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const CHILD_AGE_UPDATE = "CHILD_AGE_UPDATE"

export const getChildAge = (data) => {
    return { type: CHILD_AGE_UPDATE, data: data };
}

export const childAgeUpdate = (childArray) => {
    return async dispatch => {
        dispatch(getChildAge(childArray));

    }
};
