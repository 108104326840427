import { constants } from "@/src/utils/constants";
import { GET_CURRENCIES_SUCCESS, GET_CURRENCIES_ERR, GET_CURRENCIES_LOAD, SET_CURRENCY, GEO_CODE_LOAD, GEO_CODE_SUCCESS, GEO_CODE_ERROR, GET_CURRENT_LOAD, GET_CURRENT_SUCCESS, GET_CURRENT_ERR, GEO_NEAR_LOAD, GEO_NEAR_SUCCESS, GEO_NEAR_ERROR, GEO_CODE_RESET } from "../types";
import { SUCCESS_NOTIFICATION, ERROR_NOTIFICATION, INFO_NOTIFICATION, URL_PATH } from "../types";

const initialState = {
    type: null,
    data: [],
	message: null,
	successMsgTitle : null,
	successMsgDescription: null,
	errorMsgTitle : null,
	errorMsgDescription: null,
	infoMsgTitle : null,
	infoMsgDescription: null,
	secondaryAction: null,
	primaryAction: null,
	secondaryActionBtnTxt: null,
	primaryActionBtnTxt: null,
	urlPath: null,
	currency: constants.defaultCurrency
}
export const getCurrency = (state = { ...initialState, data: [constants.defaultCurrency] }, {data, type, payload, message, urlPath}) => {
    switch (type) {
		case GET_CURRENCIES_SUCCESS : {
			return {
				...state,
				data: data.currencies,
				currency: data.defaultCurrency,
                type,
				message,
				urlPath
			};
        }
        case GET_CURRENCIES_LOAD : {
			return {
				...state,
				data,
                type,
                message
			};
        }
        case GET_CURRENCIES_ERR : {
			return {
				...state,
				data,
				type,
                message
			};
		}
		case SET_CURRENCY: {
			localStorage.setItem('currency',JSON.stringify(payload.currency))

			return {
				...state,
				currency: payload.currency
			};
		}
        default:
			return state;
	}
}

export const setNotifications = (state = initialState, {
								successMsgTitle,successMsgDescription,
								errorMsgTitle, errorMsgDescription,
								infoMsgTitle, infoMsgDescription,
								secondaryAction, primaryAction,
								secondaryActionBtnTxt, primaryActionBtnTxt,
								type
							}) => {
    switch (type) {
		case SUCCESS_NOTIFICATION : {
			return {
				...state,
				successMsgTitle,
				successMsgDescription,
				errorMsgTitle:null,errorMsgDescription:null,
				infoMsgTitle:null, infoMsgDescription: null,
				secondaryAction: secondaryAction || null,
				primaryAction: primaryAction || null,
				secondaryActionBtnTxt: secondaryActionBtnTxt || null,
				primaryActionBtnTxt: primaryActionBtnTxt || null
			};
		}
		case ERROR_NOTIFICATION : {

			return {
				...state,
				errorMsgTitle,
				errorMsgDescription,
				successMsgTitle : null, successMsgDescription : null,
				infoMsgTitle:null, infoMsgDescription: null,
				secondaryAction: secondaryAction || null,
				primaryAction: primaryAction || null,
				secondaryActionBtnTxt: secondaryActionBtnTxt || null,
				primaryActionBtnTxt: primaryActionBtnTxt || null
			};
		}
		case INFO_NOTIFICATION : {
			return {
				...state,
				infoMsgTitle,
				infoMsgDescription,
				errorMsgTitle : null,errorMsgDescription : null,
				successMsgTitle : null, successMsgDescription : null,
				secondaryAction: secondaryAction || null,
				primaryAction: primaryAction || null,
				secondaryActionBtnTxt: secondaryActionBtnTxt || null,
				primaryActionBtnTxt: primaryActionBtnTxt || null
			};
        }
        default:
			return state;
	}
}

export const setClasses = (state = initialState, {data, type, message, urlPath}) => {
    switch (type) {
		case URL_PATH : {
			return {
				...state,
				urlPath: 'home'
			};
		}

        default:
			return state;
	}
}

export const getCurrentLocReducer = (state = initialState, { data, type }) => {
	const computedUserLocationEntity = data?.country ? data : state.data;

	switch (type) {
		// case null:
		case GET_CURRENT_LOAD: {
			return {
				...state,
				data: [],
				type
			}
		}
		case GET_CURRENT_SUCCESS: {
			return {
				...state,
				data: computedUserLocationEntity,
				type
			}
		}
		case GET_CURRENT_ERR: {
			return {
				...state,
				data,
				type
			}
		}
		default:
			return state;
	}
}

export const geoLocationReducer = (state = initialState, {data, type}) => {
	switch (type) {
		case GEO_CODE_RESET: {
			return {
				...state,
				data: [],
				type: null
			};
		}
		case GEO_CODE_LOAD: {
			return {
				...state,
				data: [],
				type
			}
		}
		case GEO_CODE_SUCCESS: {
			return {
				...state,
				data,
				type
			}
		}
		case GEO_CODE_ERROR: {
			return {
				...state,
				data,
				type
			}
		}
		default:
			return state;
	}
}

export const getNearLocReducer = (state = initialState, { data, type }) => {
	switch (type) {
		case GEO_NEAR_LOAD: {
			return {
				...state,
				data: [],
				type
			}
		}
		case GEO_NEAR_SUCCESS: {
			return {
				...state,
				data,
				type
			}
		}
		case GEO_NEAR_ERROR: {
			return {
				...state,
				data,
				type
			}
		}
		default:
			return state;
	}
}